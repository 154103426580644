import React from "react";

class HTMLViewer extends React.Component {
      
    constructor(props) {

        super(props);    
        this.divRef = React.createRef();
        this.myHTML = props.value;
    }
      
    componentDidMount() {
        this.divRef.current.innerHTML = this.myHTML;
    }
 
      
    render() {
  /* <div style={this.props.style} ref={this.divRef}></div> */
        return (
            <div style={this.props.style} ref={this.divRef}></div>
        );
    }
}

export default HTMLViewer