import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createCalendarLocation, deleteCalendarLocation, fetchCalendarLocations, modyfyCalendarLocation } from '../../../http/adminApi';
import Paragraph from 'antd/lib/typography/Paragraph';

const AdminRefCalendarLocations = observer(() => {
    const [locations, setLocations] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        let data = await fetchCalendarLocations()
        setLocations(data)
    }

    const addLocation = async () => {
        const id = await createCalendarLocation()
        setLocations([...locations, { name: '', id: id }])
    }

    const removeLocation = async (id) => {
        const data = await deleteCalendarLocation(id)
        setLocations(locations.filter(i => i.id !== id))
    }

    const changeLocation = async (name, id) => {
        const data = await modyfyCalendarLocation(name, id)
        setLocations(locations.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Места проведения событий</th>
                        <th><Button onClick={() => addLocation()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {locations.map(location =>
                        <tr key={location.id}>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Место проведения',
                                        onChange: (value) => changeLocation(value, location.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {location.name}
                                </Paragraph>
                                {/*    <FormControl
                                    placeholder='Введите название типа контроля'
                                    value={workType.name}
                                    onChange={(e) => changeWorkType(e.target.value, workType.id)}
                                /> */}
                            </td>
                            <td>
                                <Button onClick={() => removeLocation(location.id)}
                                    className='float-end'>
                                    <FaMinus className="d-flex justify-content-between" />
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminRefCalendarLocations;