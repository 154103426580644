import React, { useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Form } from 'react-bootstrap';
import { fetchModules, fetchThemes, getCurrentTheme } from '../../../http/teacherApi';
import { Context } from '../../..';
import { fetchDescriptionsByThemeID } from '../../../http/studentApi';
import HTMLViewer from '../../utilites/HTMLViewer';

const TeacherStudent = observer(() => {
    const { teacher } = useContext(Context)

    useEffect(() => {
        loadData()
    }, [teacher.setSelectedSubMenu, teacher.selectedSideMenu])

    /*     useEffect(() => {
            loadDescriptions()
        }, [teacher.selectedModule, teacher.selectedTheme]) */

    const loadData = async () => {
        const dataM = await fetchModules(teacher.selectedClasSubject.classID, teacher.selectedClasSubject.subjectID)
        teacher.setTeacherModules(dataM)
        const dataMT = await getCurrentTheme(teacher.selectedClasSubject.subjectID, teacher.selectedStudent.id, teacher.selectedClasSubject.classID)
        //Обработка для запоминания модуля
        let dataT = []
        if (!teacher.selectedModule?.id) {
            teacher.setSelectedModule(dataMT.module)
            dataT = await fetchThemes(dataMT.module.id)
        } else {
            dataT = await fetchThemes(teacher.selectedModule.id)
        }
        //Обработка для запоминания темы
        let dataD = []
        if (!teacher.selectedTheme?.id) {
            teacher.setSelectedTheme(dataMT.theme)
            dataD = await fetchDescriptionsByThemeID(dataMT.theme.id, teacher.selectedStudent.id)
        } else {
            dataD = await fetchDescriptionsByThemeID(teacher.selectedTheme.id, teacher.selectedStudent.id)
        }


      //  teacher.setSelectedModule(dataMT.module)
      //  teacher.setSelectedTheme(dataMT.theme)
      //  const dataT = await fetchThemes(dataMT.module.id)
        teacher.setTeacherThemes(dataT)

      //  const dataD = await fetchDescriptionsByThemeID(dataMT.theme.id, teacher.selectedStudent.id)
        teacher.setTeacherDescriptions(dataD)
    }

    /*     const loadDescriptions = async () => {
            const data = await fetchDescriptionsByThemeID(teacher.selectedTheme.id)
            teacher.setTeacherDescriptions(data)
        } */

    const openSetScore = (desc) => {
        teacher.setSelectedDescription(desc)
        teacher.setSelectedSubMenu({ id: 43 })
    }

    const selectModule = async (moduleID) => {
        teacher.setSelectedModule(teacher.teacherModules.find(modul => modul.id == moduleID))
        const themes = await fetchThemes(moduleID)//.then(data => teacher.setTeacherThemes(data))
        teacher.setTeacherThemes(themes)
        teacher.setSelectedTheme(themes[0])
        const dataD = await fetchDescriptionsByThemeID(themes[0].id, teacher.selectedStudent.id)
        teacher.setTeacherDescriptions(dataD)
    }

    const selectTheme = async (themeID) => {
        teacher.setSelectedTheme(teacher.teacherThemes.find(theme => theme.id == themeID))
        await fetchDescriptionsByThemeID(themeID, teacher.selectedStudent.id).then(data => teacher.setTeacherDescriptions(data))
    }

    return (
        <Container>
            <div style={{'marginBottom':'15px'}}>Ученик {teacher.selectedStudent.name}</div>
            <Form className="mx-2">
                <Form.Select onChange={(e) => selectModule(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={teacher.selectedModule.id} aria-label="Default select example">
                    {teacher.teacherModules.map(module =>
                        <option key={module.id} value={module.id}>{module.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => selectTheme(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={teacher.selectedTheme.id} aria-label="Default select example">
                    {teacher.teacherThemes.map(theme =>
                        <option key={theme.id} value={theme.id}>{theme.name}</option>
                    )}
                </Form.Select>
            </Form>
            {teacher.teacherDescriptions.length == 0 ? <div><br/><br/>"Для этого раздела не заданы темы и/или задания урока"</div>
                :
                <Table striped bordered hover style={{'marginTop':'15px'}}>
                    <thead>
                        <tr>
                            <th>Маршрут</th>
                            <th>Описание</th>
                            <th>Оценка</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teacher.teacherDescriptions.map(desc =>
                            <tr key={desc.id}>
                                <td key={"r" + desc.silabusRoute.id + desc.id} style={{ backgroundColor: desc.silabusRoute.color }}>
                                    {desc.index === 1 && desc.silabusRoute.name}
                                </td>
                                <td key={"t" + desc.id} style={{ backgroundColor: desc.silabusRoute.color }}>
                                    <HTMLViewer
                                        value={desc.text}
                                    ></HTMLViewer>
                                </td>
                                <td key={"ss" + desc.id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => openSetScore(desc)}
                                >{desc.score}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>}
        </Container>
    );
});

export default TeacherStudent;