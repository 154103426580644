import { TreeProps, Tree, GetProps, TreeDataNode } from "antd";
import { ReactNode, useEffect, useState, Key, useMemo } from "react";
const { DirectoryTree } = Tree;

export interface DataNode {
    title: string | ReactNode;
    key: string | number;
    isLeaf?: boolean;
    children?: DataNode[];
    selectable?: boolean;
}

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;


export default function DirectoryTreeDnD(params: any) {
    //console.log("DirectoryTreeDnD", params);

    const [treeData, setTreeData] = useState<DataNode[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<Key[] | undefined>([]);
    const [selectedKeys, setSelectedKeys] = useState<Key[] | undefined>(/* params?.selectedKeys */[]);


    const data = useMemo(() => ({
        get tree() {
            //console.log("memo", params?.treeData);
            
            if (params?.treeData) {
                return params.treeData
            }
            return []
        }
    }), [params])


    useEffect(() => {
        //console.log("effect");
        
        setTreeData(data.tree/* params?.treeData */)
    }, [params?.treeData]);

    useEffect(() => {
        setExpandedKeys(params?.expandedKeys)
        //setSelectedKeys(params?.selectedKeys)
    }, [params?.expandedKeys/* , params?.selectedKeys */]);

    useEffect(() => {
        setSelectedKeys(params?.selectedKeys)
    }, [params?.selectedKeys]);

    const onDragEnter: TreeProps['onDragEnter'] = (info) => {
        //console.log(info);
        // expandedKeys, set it when controlled is needed
        //setExpandedKeys(info.expandedKeys)
    };

    const onDrop: TreeProps['onDrop'] = (info) => {
        //console.log("position", info.dropPosition, info.dragNode.key);
        //console.log(info);
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

        //console.log("-->", String(dropKey).split('-').length);
        if (String(dropKey).split('-').length > 2) return; // нельзя в третий уровень кинуть
        //if (!(String(dropKey).split('-').length === String(dragKey).split('-').length-1)) return;

        params.onChange?.({ key: dragKey, index: info.dropPosition });
        //params.onSelect?.(dropKey);

        const loop = (
            data: TreeDataNode[],
            key: React.Key,
            callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void,
        ) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children!, key, callback);
                }
            }
        };
        const data = [...treeData];

        // Find dragObject
        let dragObj: TreeDataNode;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
                item.children.unshift(dragObj);
            });
        } else {
            let ar: TreeDataNode[] = [];
            let i: number;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                // Drop on the top of the drop node
                ar.splice(i!, 0, dragObj!);
            } else {
                // Drop on the bottom of the drop node
                ar.splice(i! + 1, 0, dragObj!);
            }
        }
        setTreeData(data);
    };

    const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
        setExpandedKeys(keys);
        //console.log('Trigger Expand', keys, info);
    };

    const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
        //console.log('DnD Trigger Select', keys, info);
        //navigate(`${String(keys[0]).replace(/-/gi, '/')}`);
        setSelectedKeys(keys);
        params.onSelect?.(keys, info)
    };


    return (
        <DirectoryTree
            {...params}
            onExpand={onExpand}
            onSelect={onSelect}
            treeData={treeData}
            expandedKeys={expandedKeys}
            selectedKeys={selectedKeys}
            draggable
            onDragEnter={onDragEnter}
            onDrop={onDrop}

        />
    )
}