import React, { useState, useEffect } from 'react';
import { Button, message, Upload } from 'antd';
//import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { deleteFile } from '../../http/adminApi';
import { $authHost } from "../../http/index";
// import { test } from '../../http/userApi';
import localForage from "localforage";

function FilesUpload(props) {
    const [fileList, setFileList] = useState(props.fileList);

    useEffect(() => {
        setFileList(props.fileList)
    }, [props])

    const acceptFileTypes = ['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/doc', 'application/docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']


    const customRequest = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();

        const config = {
            headers: { "content-type": "multipart/form-data" },
        };

        fmData.append("file", file);

        try {
            const res = await $authHost.post(
                options.action,
                fmData,
                //                config
            );

            onSuccess(res.data);
            console.log("server res: ", res);
///            return res.data;
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
        }

    };


    const handleChange = (info) => {
        let newFileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        newFileList = newFileList.slice(-10);

        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
                file.uid = file.response.uid;
            }
            return file;
        });
        setFileList(newFileList);
    };

    const propsUpload = {
        name: 'file',
        multiple: true,
        //defaultFileList: fileList,
        //  customRequest: customRequest,
        // action: `${process.env.REACT_APP_API_URL}/api/file/?upload=desc&id=5`,
        accept: 'image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        headers: {
            authorization: `Bearer ${localForage.getItem('token')}`,
        },
        beforeUpload: file => {
            // console.log("FFF",file,acceptFileTypes,acceptFileTypes.includes(file.type));
            const isValid = acceptFileTypes.includes(file.type);
            if (!isValid) {
                message.error(`${file.name} недопустимый файл с типом ${file.type}`);
            }
            return isValid || Upload.LIST_IGNORE;
        },
        onChange: handleChange,
        onRemove(file) {
            //   console.log("Del",file);
            deleteFile(file).then((e) => {
                if (e === 'ok') {
                    message.success(`${file.name} файл успешно удален!`);
                } else {
                    message.error(`${file.name} удаление файла сорвалось.`);
                }
            })
        },
        showUploadList: {
            showDownloadIcon: true,
            // downloadIcon: 'Загрузка',
            showRemoveIcon: true,
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return <div style={{ 'marginLeft': '15px', 'marginBottom': '15px' }}>
        <Upload {...propsUpload}
            fileList={fileList}
            action={props.action}
            customRequest={customRequest}
            disabled={props.disabled}
        // action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${props.area}&dataID=${props.dataID}`}
        >
            {props.disabled ? '' : <Button icon={<UploadOutlined />}>Загрузка файла...</Button>}
            {/*   <Button icon={<UploadOutlined />}>Загрузка файла...</Button> */}
        </Upload></div>;
}

export default FilesUpload;