import { makeAutoObservable } from "mobx";

export default class AdminStore {
    constructor() {
        this._sideMenu = [
            { id: 18, name: "Пользователи", link: 'users' },
            /*             { id: 1, name: "Учителя" },
                        { id: 17, name: "Тьюторы" },
                        { id: 6, name: "Студенты" },
                        { id: 7, name: "Родители" },
                        { id: 10, name: "Администраторы" }, */
            { id: 2, name: "Классы", link: 'classesT' },
            { id: 3, name: "Расписание", link: 'lessons' },
            /* { id: 13, name: "Посещаемость" }, */
            /*  { id: 11, name: "Модули(модал)" }, */
            { id: 14, name: "Модули", link: 'modulesNM' },
            { id: 21, name: "Силабус", link: 'silabus' },
            { id: 4, name: "Ведомость", link: 'classes' },
            { id: 9, name: "Календарь событий", link: 'events' },
            { id: 20, name: "Визиты родителей", link: 'visits' },
            { id: 5, name: "Сообщения", link: 'messages' },
            /*  { id: 12, name: "Сетки расписания" }, */
            { id: 8, name: "Справочники", link: 'refs' },
            /* { id: 16, name: "Звезды", link: 'subject_star' }, */
            { id: 16, name: "Звезды 2", link: 'stars' },
            /*  { id: 15, name: "Субьектная звезда" }, */
            /* { id: 19, name: "Системный журнал", link: 'syslog' }, */
        ]
        //        this._selectedSideMenu = { id: 18, name: "Пользователи" }

        this._subMenu = [
            { id: 121, name: "Сетки расписания" },
            { id: 141, name: "Темы" },
            { id: 142, name: "Маршруты" },
            { id: 143, name: "Описания" },
            { id: 41, name: "Классы и предметы" },
            { id: 42, name: "Ученики в классе" },
            { id: 43, name: "Проверка ДЗ" },
        ]
        this._selectedSubMenu = {}


        //     this._users = [
        //         {id: 1, login: "petrov@mail.ru", password: "aaa", name: "Петров", role: "teacher", properties: [{key: "Адрес", value: "Кирова 12", id: 1}, {key: "Телефон брата", value: "764434523", id: 2}]},
        //         {id: 6, login: "sidorov@mail.ru", password: "bbb", name: "Сидоров", role: "teacher", properties: [{key: "Телефон бабушки", value: "755599883", id: 3}, {key: "Аллергия", value: "Арахис!", id: 4}]},
        //         {id: 7, login: "iivanovv@yandex.ru", password: "ccc", name: "Иванов", role: "teacher", properties: [{key: "Адрес", value: "Ленина 74", id: 5}, {key: "Может опаздывать", value: "разрешено на 15 минут", id: 6}]},   
        //   {id: 1, login: "petrov@mail.ru", password: "aaa", properties: {"name": "Петров", "role": "teacher"}},
        //   {id: 6, login: "sidorov@mail.ru", password: "bbb", properties: {"name": "Сидоров", "role": "teacher"}},
        //   {id: 7, login: "iivanovv@yandex.ru", password: "ccc", properties: {"name": "Иванов", "role": "teacher"}},   
        //        {id: 2, login: "mpopova@mail.ru", password: "aaa", properties: [{key: "Имя пользователя", value: "teacher", id: 7}, {key: "role", value: "student", id: 8}]},
        //   {id: 3, login: "sidorovkola@mail.ru", password: "bbb"},
        //   {id: 4, login: "kivanova@yandex.ru", password: "ccc"},  
        //   {id: 9, login: "mpopova@mail.ru", password: "aaa"},
        //   {id: 10, login: "sidorovkola@mail.ru", password: "bbb"},
        //   {id: 11, login: "kivanova@yandex.ru", password: "ccc"},                                              
        //     ] 

        this._users = []
        this._selectedUser = {}

        this._teachers = []
        this._selectedLeader = {}
        this._selectedTeacher = {}

        this._students = []
        this._selectedStudent = {}

        this._parents = []
        this._selectedParent = {}

        /*         this._classes = [
                    {id: 1, name: "7D", leader: "Наставник 1", teacher: "Педагог 1", branchID: 8},
                    {id: 2, name: "7A", leader: "Наставник 2", teacher: "Педагог 2", branchID: 8},
                    {id: 3, name: "9F", leader: "Наставник 3", teacher: "Педагог 3", branchID: 10},                         
                ] */
        this._classes = []
        this._selectedClass = {}
        this._teacherStudents = []

        this._subjects = []
        this._selectedSubject = {}

        this._lessonTypes = []
        this._selectedLessonType = {}

        this._workTypes = []
        this._selectedWorkType = {}

        this._branches = []
        this._selectedBranch = {}

        this._propertiesTypes = [
            { id: 1, key: "Мама", type: "parentid" },
            { id: 2, key: "Папа", type: "parentid" },
            { id: 5, key: "Класс", type: "classid" },
            { id: 3, key: "Доверенное лицо", type: "string" },
            { id: 4, key: "Телефон", type: "string" },
            { id: 6, key: "Предмет", type: "subjectid" },
            { id: 7, key: "Класс/Предмет", type: "clasid_subjectid" },
        ]
        this._selectedPropertiesType = {}

        this._lessonsInterval = []
        this._lessonsSchedule = []
        /*         this._lessonsSchedule = [
                    {id: 1, intID: 1, day: 1, subjectID: 1, subjectName: "Математика"},                     
                ] */
        /*        this._page = 1
                this._totalCount = 0
                this._limit = 3
        */
        this._scheduleGrids = []
        this._selectedScheduleGrid = {}
        this._scheduleGridItems = []

        this._starSubjects = []

        this._calendarEvents = []

        this._selectedInterval = []

        makeAutoObservable(this)
    }

    setSelectedInterval(interval) {
        this._selectedInterval = interval
    }
    setSideMenu(sidemenu) {
        this._sideMenu = sidemenu
    }
    setSelectedSideMenu(menuitem) {
        this._selectedSideMenu = menuitem
    }

    setSubMenu(submenu) {
        this._subMenu = submenu
    }
    setSelectedSubMenu(menuitem) {
        this._selectedSubMenu = menuitem
    }

    setUsers(users) {
        this._users = users
    }
    setSelectedUser(user) {
        this._selectedUser = user
    }

    setTeachers(teachers) {
        this._teachers = teachers
    }
    setSelectedLeader(teacher) {
        this._selectedLeader = teacher
    }
    setSelectedTeacher(teacher) {
        this._selectedTeacher = teacher
    }

    setStudents(students) {
        this._students = students
    }
    setSelectedStudent(student) {
        this._selectedStudent = student
    }

    setParents(parents) {
        this._parents = parents
    }
    setSelectedParent(parent) {
        this._selectedParent = parent
    }

    setClasses(classes) {
        this._classes = classes
    }
    setSelectedClass(clas) {
        this._selectedClass = clas
    }
    setTeacherStudents(data) {
        this._teacherStudents = data
    }

    setSubjects(subjects) {
        this._subjects = subjects
    }
    setSelectedSubject(subject) {
        this._selectedSubject = subject
    }

    setLessonTypes(lessonTypes) {
        this._lessonTypes = lessonTypes
    }
    setSelectedLessonType(lessonType) {
        this._selectedLessonType = lessonType
    }

    setWorkTypes(workTypes) {
        this._workTypes = workTypes
    }
    setSelectedWorkType(workType) {
        this._selectedWorkType = workType
    }

    setBranches(branches) {
        this._branches = branches
    }
    setSelectedBranch(branch) {
        this._selectedBranch = branch
    }

    setPropertiesTypes(propertiesTypes) {
        this._propertiesTypes = propertiesTypes
    }
    setSelectedPropertiesTypes(propertiesType) {
        this._selectedPropertiesType = propertiesType
    }

    setLessonsInterval(lessonsInterval) {
        this._lessonsInterval = lessonsInterval
    }
    setLessonsSchedule(lessonsSchedule) {
        this._lessonsSchedule = lessonsSchedule
    }

    setScheduleGrids(scheduleGrids) {
        this._scheduleGrids = scheduleGrids
    }
    setSelectedScheduleGrid(scheduleGrid) {
        this._selectedScheduleGrid = scheduleGrid
    }
    setScheduleGridItems(scheduleGridItems) {
        this._scheduleGridItems = scheduleGridItems
    }

    setStarSubjects(starSubjects) {
        this._starSubjects = starSubjects
    }

    setCalendarEvents(events) {
        this._calendarEvents = events
    }

    get sideMenu() {
        return this._sideMenu
    }
    get selectedSideMenu() {
        return this._selectedSideMenu
    }

    get subMenu() {
        return this._subMenu
    }
    get selectedSubMenu() {
        return this._selectedSubMenu
    }

    get users() {
        return this._users
    }
    get selectedUser() {
        return this._selectedUser
    }

    get teachers() {
        return this._teachers
    }
    get selectedLeader() {
        return this._selectedLeader
    }
    get selectedTeacher() {
        return this._selectedTeacher
    }

    get students() {
        return this._students
    }
    get selectedStudent() {
        return this._selectedStudent
    }

    get parents() {
        return this._parents
    }
    get selectedParent() {
        return this._selectedParent
    }

    get classes() {
        return this._classes
    }
    get selectedClass() {
        return this._selectedClass
    }
    get teacherStudents() {
        return this._teacherStudents
    }

    get subjects() {
        return this._subjects
    }
    get selectedSubject() {
        return this._selectedSubject
    }

    get lessonTypes() {
        return this._lessonTypes
    }
    get selectedLessonType() {
        return this._selectedLessonType
    }

    get workTypes() {
        return this._workTypes
    }
    get selectedWorkType() {
        return this._selectedWorkType
    }

    get branches() {
        return this._branches
    }
    get selectedBranch() {
        return this._selectedBranch
    }

    get propertiesTypes() {
        return this._propertiesTypes
    }
    get selectedPropertiesType() {
        return this._selectedPropertiesType
    }

    get lessonsInterval() {
        return this._lessonsInterval
    }

    get lessonsSchedule() {
        return this._lessonsSchedule
    }

    get scheduleGrids() {
        return this._scheduleGrids
    }
    get selectedScheduleGrid() {
        return this._selectedScheduleGrid
    }
    get scheduleGridItems() {
        return this._scheduleGridItems
    }

    get starSubjects() {
        return this._starSubjects
    }

    get calendarEvents() {
        return this._calendarEvents
    }
    get selectedInterval() {
        return this._selectedInterval
    }
}