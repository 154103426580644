import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Card, DatePicker, Popconfirm, Typography, message } from 'antd';
import { TimePicker } from 'antd';
//import moment from 'moment';
//import 'moment/locale/ru';
import { createPlan, deletePlan, fetchPlans, modyfyPlan } from '../../http/studentApi';
import dayjs from 'dayjs';

const { Paragraph } = Typography;

const StudentPlansTable = observer(() => {
    const { student } = useContext(Context)

    const [plans, setPlans] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const timeFormat = 'HH:mm';
    const format = "YYYY-MM-DD HH:mm"

    const loadData = async () => {
        const data = await fetchPlans()
        if (Array.isArray(data)) {
            setPlans(data)
            student.setCalendarPlans(data)
        } else message.error("Получены не корректные данные от сервера")
    }

    const newPlan = async () => {
//        let begin = moment(moment.now()).format(format)
//        let end = moment(moment.now() + 3600000).format(format)
        let begin = dayjs()/* .format(format) */
        let end = dayjs().add(3600000, 'second')/* .format(format) */
        const data = await createPlan(begin.format(format), end.format(format))
        setPlans([{ name: '', begin: begin, end: end, id: data }, ...plans])
        student.setCalendarPlans([{ name: '', begin: begin, end: end, id: data }, ...student.calendarPlans])
        return data
    }

    const changeName = async (name, id) => {
        let data = await modyfyPlan(null, name, id)
        setPlans(plans.map(i => i.id === id ? { ...i, ['name']: name } : i))
        student.setCalendarPlans(student.calendarPlans.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    const changeDate = async ({value, /* date, */ id}) => {
        console.log(id, value);
        const date = [dayjs(value[0]).isValid() ? dayjs(value[0].format()) : dayjs().format(), dayjs(value[1]).isValid() ? dayjs(value[1].format()) : dayjs().format()]
        let data = await modyfyPlan(date[0].format(format), null, id, date[1].format(format))
        console.log('Formatted Selected Time: ', date);
        setPlans(plans.map(i => i.id === id ? { ...i, ['begin']: date[0], ['end']: date[1] } : i))
        student.setCalendarPlans(student.calendarPlans.map(i => i.id === id ? { ...i, ['begin']: date[0], ['end']: date[1] } : i))
    }

    const removePlan = async (planID) => {
        const data = await deletePlan(planID)
        if (data > 0) {
            message.success("Удаление успешно!")
            setPlans(plans.filter(i => i.id !== planID))
            student.setCalendarPlans(student.calendarPlans.filter(i => i.id !== planID))
        } else {
            message.error("Удаление не удалось!")
        }
    }

/*     const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    };
    const onOk = (value) => {
        console.log('onOk: ', value);
    }; */


    //  const disabledTime = { now: moment, type: ['start', 'end'], disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24] }
    const disabledTime = (value) => {
        return {
            type: ['start', 'end'],
            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24]
        }
    }

    return (
        <Card title='Планы' extra={<Button onClick={() => newPlan()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button>}>
            <Table striped bordered hover>
{/*                 <thead>
                    <tr>
                        <th style={{ verticalAlign: 'bottom' }}>Планы</th>
                        <th style={{ verticalAlign: 'bottom' }}></th>
                        <th style={{ verticalAlign: 'bottom' }}><Button onClick={() => newPlan()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
 */}                <tbody>
                    {plans.map(plan =>
                        <tr key={plan.id}>
                            <td>
                                <DatePicker.RangePicker
                                    showTime={{ format: timeFormat, }}
                                    //onChange={onChange} 
                                    //onOk={onOk}
                                    minuteStep={5}
                                    //format={format}
                                    defaultValue={[dayjs(plan.begin)/* .format(format) */, dayjs(plan.end)/* .format(format) */]}
                                    //value={[moment(gItem.begin, format), moment(gItem.end, format)]}
                                    onChange={(value, dateString) => changeDate({value, date:dateString, id: plan.id})}
                                    onOk={(value, dateString) => changeDate({value, date:dateString, id: plan.id})}
                                    //disabledTime={disabledTime}
                                />
                            </td>
                            <td>
                                <Paragraph 
                                    editable={{
                                        tooltip: 'Название плана',
                                        onChange: (value) => changeName(value, plan.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {plan.name}
                                </Paragraph>
                            </td>
                            <td>
                                <Popconfirm
                                    title={'Вы уверены что хотите удалить план?'}
                                    onConfirm={() => removePlan(plan.id)}
                                    onCancel={() => message.warning('Удаление отменено')}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button className='float-end'>
                                        <FaMinus className="d-flex justify-content-between" />
                                    </Button>
                                </Popconfirm>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    );
});

export default StudentPlansTable;