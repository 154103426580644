import React, { useContext, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container, Form, Button } from 'react-bootstrap';
import { fetchAttendance, fetchAttendanceData, modyfyAttendance } from '../../../http/teacherApi';
import { DatePicker, Checkbox, TimePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';

const AttendanceEditNotShedulled = observer(({ classID, setClassID, subjectID, setSubjectID, lessonComment, setLessonComment, begin, setBegin, end, setEnd }) => {
    const { teacher } = useContext(Context)

    const timeFormat = 'HH:mm';
    const disabledTime = (value) => {
        return { disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24] }
    }
    return (
        <Container>
            <Form className="mx-2">
                <Form.Select onChange={(e) => setClassID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={classID}>
                    {teacher.classes.map(clas =>
                        <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => setSubjectID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={subjectID}>
                    {
                        teacher.subjects.map(subject =>
                            <option key={'subject' + subject.id} value={subject.id}>{subject.name}</option>
                        )
                    }
                </Form.Select>
                <Form.Control className="mt-2 mb-2"
                    placeholder='Введите комментарий к уроку, например кто проводил, почему перенос'
                    value={lessonComment}
                    onChange={e => setLessonComment(e.target.value)}
                />
                <TimePicker
                    minuteStep={5}
                    format={timeFormat}
                    allowClear={false}
                    defaultValue={moment(begin, timeFormat)}
                    //value={moment(begin, timeFormat)}
                    onChange={(value) => setBegin(value.format(timeFormat))}
                    disabledTime={disabledTime}
                />
                <TimePicker
                    minuteStep={5}
                    format={timeFormat}
                    allowClear={false}
                    defaultValue={moment(end, timeFormat)}
                    //value={moment(end, timeFormat)}
                    onChange={(value) => setEnd(value.format(timeFormat))}
                    disabledTime={disabledTime}
                />
            </Form>
        </Container>
    )
});

export default AttendanceEditNotShedulled;