import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
//import { Context } from '../../..';
import { Button, Container, Dropdown, Form } from 'react-bootstrap';
import { createResponsibleStage, deleteResponsibleStage, fetchResponsible, modyfyResponsibleDirector, modyfyResponsibleStage, userList } from '../../../http/adminApi';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Paragraph from 'antd/lib/typography/Paragraph';

const AdminRefResponsible = observer(() => {
    //const { admin } = useContext(Context)
    const [responsible, setResponsible] = useState({})

    const [users, setUsers] = useState([])

    useEffect(() => {
        userList('ADMIN').then(data => setUsers(data))
        loadData()
    }, [])

    /*     useEffect(() => {
            loadData()
        }, [admin.users]) */

    let data1 = { director: 231, stage: [{ id: 1, name: "0-3 ступень", userID: 77 }, { id: 2, name: "4-5 ступень", userID: 240 }] }

    const loadData = async () => {
        let data = await fetchResponsible()
        setResponsible(data)
    }

    const changeDirector = async (userID) => {
        let data = await modyfyResponsibleDirector(userID)
        setResponsible({ 'director': userID, 'stage': responsible.stage })
        //await loadData()
    }

    const addStage = async () => {
        const id = await createResponsibleStage()
        setResponsible({ 'director': responsible.director, 'stage': [...responsible.stage, { name: '', id: id }] })
    }

    const removeStage = async (id) => {
        const data = await deleteResponsibleStage(id)
        setResponsible({ 'director': responsible.director, 'stage': responsible.stage.filter(i => i.id != id) })
        //setLocations(locations.filter(i => i.id !== id))
    }

    const changeStageName = async (name, id) => {
        let data = await modyfyResponsibleStage(null, id, name)
        setResponsible({ 'director': responsible.director, 'stage': responsible.stage.map(i => i.id === id ? {...i, ['name']: name} : i) })
        //await loadData()
    }

    const changeStage = async (userID, stageID) => {
        let data = await modyfyResponsibleStage(userID, stageID, null)
        setResponsible({ 'director': responsible.director, 'stage': responsible.stage.map(i => i.id === stageID ? {...i, ['userID']: userID} : i) })
        //await loadData()
    }

    const resIDByStage = (stageID) => {
        let stage = responsible?.stage?.find(i => i.id == stageID)
        return stage?.userID
    }

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Наберите для фильтрации..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    return (
        <Container>
            <div style={{ marginBottom: "15px", marginLeft: "15px" }}>
                <b>Директор школы:</b>
                <Dropdown /* search */>
                    <Dropdown.Toggle>{users.filter(j => j.id == responsible?.director)[0]?.name || "Выберите директора"}</Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                        {users.map(user =>
                            <Dropdown.Item
                                onClick={() => changeDirector(user.id)}
                                key={user.id}
                            >
                                {user.name}
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Название ступени</th>
                        <th>Руководитель ступени</th>
                        <th><Button onClick={() => addStage()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {responsible?.stage && responsible.stage.map(stage =>
                        <tr key={stage.id}>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Название ступени',
                                        onChange: (value) => changeStageName(value, stage.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {stage.name}
                                </Paragraph>
                            </td>
                            <td>
                                <Dropdown /* search */>
                                    <Dropdown.Toggle>{users.filter(j => j.id == resIDByStage(stage.id))[0]?.name || "Выберите руководителя ступени"}</Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                        {users.map(user =>
                                            <Dropdown.Item
                                                onClick={() => changeStage(user.id, stage.id)}
                                                key={user.id}
                                            >
                                                {user.name}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                            <td>
                                <Button onClick={() => removeStage(stage.id)}
                                    className='float-end'>
                                    <FaMinus className="d-flex justify-content-between" />
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminRefResponsible;