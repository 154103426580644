import React from 'react';
import { Col, Row } from 'antd';
import { Container } from 'react-bootstrap';
import HTMLViewer from '../HTMLViewer2';

const StarTips = ({ tipsData, name, choice, data }) => {

  return (
    <Container style={{ marginTop: '25px' }}>
      <Row>
        <Col span={24}>
          <h5>{name}</h5>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {tipsData[name] && choice != 0 &&
          /* tipsData[name][choice - 1] */
             <HTMLViewer
              value={data}
            ></HTMLViewer> 
          }
        </Col>
      </Row>
    </Container>
  );
};

export default StarTips;