import { makeAutoObservable } from "mobx";
//import moment from "moment";
import dayjs from 'dayjs'

export default class TeacherStore {
    constructor() {
        this._sideMenu = [
            { id: 1, name: "Расписание", link: "shedule" },
            /*   {id: 2, name: "Модули(модал)"}, */
            { id: 6, name: "Модули", link: "modules" },
//            { id: 6, name: "Модули", link: "modules" },
            //{ id: 4, name: "Классы", link: "classes" },
            { id: 7, name: "Журнал", link: "journal" },
            { id: 5, name: "Посещаемость", link: "attendance" },
            //{ id: 8, name: "Статистика" },
            { id: 9, name: "Календарь событий", link: "events" },
            /* { id: 10, name: "Звезды" }, */
            //{ id: 3, name: "Настройки" },
        ]
        this._selectedSideMenu = { id: 1, name: "Расписание" }

        this._subMenu = [
            { id: 41, name: "Классы и предметы" },
            { id: 42, name: "Ученики в классе" },
            { id: 43, name: "Проверка ДЗ" },
            { id: 401, name: "Классы и предметы_М" },
            { id: 402, name: "Ученики в классе_М" },
            { id: 403, name: "Проверка ДЗ_М" },       
            { id: 61, name: "Разделы" },
            { id: 62, name: "Темы урока" },
            { id: 63, name: "Задания урока" },
            { id: 71, name: "Массовые оценки" },
            { id: 81, name: "Статистика посешяемости" }
        ]
        this._selectedSubMenu = {}

        this._classesSubjects = []
        this._selectedClasSubject = {} // Испрользуется при проставлении оценок, хранить обьект
        this._selectedClasSubjectSyllabus = {} // Используется в модулях-темах, хранит ID

        this._teacherStudents = []
        this._selectedStudent = {}

        this._teacherModules = []
        this._selectedModule = {}
        this._teacherThemes = []
        this._selectedTheme = {}
        this._teacherRoutes = []
        this._selectedRoute = {}
        this._teacherDescriptions = []
        this._selectedDescription = {}
        this._teacherScores = []

        this._classes = []
        this._selectedClass = {}

        this._subjects = []
        this._selectedSubject = {}

        this._teacherSchedule = []


        this._statBegin = dayjs().month(8).date(1)//dayjs().subtract(604800, 'second')
        this._statEnd = dayjs()

//        this._statBegin = moment(moment.now() - 604800000)
//        this._statEnd = moment(moment.now())

        makeAutoObservable(this)
    }

    setSideMenu(sidemenu) {
        this._sideMenu = sidemenu
    }
    setSelectedSideMenu(menuitem) {
        this._selectedSideMenu = menuitem
    }

    setSubMenu(submenu) {
        this._subMenu = submenu
    }
    setSelectedSubMenu(menuitem) {
        this._selectedSubMenu = menuitem
    }

    setClassesSubjects(data) {
        this._classesSubjects = data
    }
    setSelectedClasSubject(classub) {
        this._selectedClasSubject = classub
    }
    setSelectedClasSubjectSyllabus(classub) {
        this._selectedClasSubjectSyllabus = classub
    }

    setTeacherStudents(data) {
        this._teacherStudents = data
    }
    setSelectedStudent(student) {
        this._selectedStudent = student
    }

    setTeacherModules(modules) {
        this._teacherModules = modules
    }
    setSelectedModule(module) {
        this._selectedModule = module
    }
    setTeacherThemes(themes) {
        this._teacherThemes = themes
    }
    setSelectedTheme(theme) {
        this._selectedTheme = theme
    }
    setTeacherRoutes(routes) {
        this._teacherRoutes = routes
    }
    setSelectedRoute(route) {
        this._selectedRoute = route
    }
    setTeacherDescriptions(descriptions) {
        this._teacherDescriptions = descriptions
    }
    setSelectedDescription(description) {
        this._selectedDescription = description
    }

    setTeacherScores(scores) {
        this._teacherScores = scores
    }

    setClasses(classes) {
        this._classes = classes
    }
    setSelectedClass(clas) {
        this._selectedClass = clas
    }

    setSubjects(subjects) {
        this._subjects = subjects
    }
    setSelectedSubject(subject) {
        this._selectedSubject = subject
    }

    setTeacherSchedule(teacherSchedule) {
        this._teacherSchedule = teacherSchedule
    }

    setStatBegin(mom) {
        this._statBegin = mom
    } 
    setStatEnd(mom) {
        this._statEnd = mom
    } 

    get sideMenu() {
        return this._sideMenu
    }
    get selectedSideMenu() {
        return this._selectedSideMenu
    }

    get subMenu() {
        return this._subMenu
    }
    get selectedSubMenu() {
        return this._selectedSubMenu
    }

    get classesSubjects() {
        return this._classesSubjects
    }
    get selectedClasSubject() {
        return this._selectedClasSubject
    }
    get selectedClasSubjectSyllabus() {
        return this._selectedClasSubjectSyllabus
    }

    get teacherStudents() {
        return this._teacherStudents
    }
    get selectedStudent() {
        return this._selectedStudent
    }

    get teacherModules() {
        return this._teacherModules
    }
    get selectedModule() {
        return this._selectedModule
    }
    get teacherThemes() {
        return this._teacherThemes
    }
    get selectedTheme() {
        return this._selectedTheme
    }
    get teacherRoutes() {
        return this._teacherRoutes
    }
    get selectedRoute() {
        return this._selectedRoute
    }
    get teacherDescriptions() {
        return this._teacherDescriptions
    }
    get selectedDescription() {
        return this._selectedDescription
    }

    get teacherScores() {
        return this._teacherScores
    }

    get classes() {
        return this._classes
    }
    get selectedClass() {
        return this._selectedClass
    }

    get subjects() {
        return this._subjects
    }
    get selectedSubject() {
        return this._selectedSubject
    }

    get teacherSchedule() {
        return this._teacherSchedule
    }

    get statBegin() {
        return this._statBegin
    }
    get statEnd() {
        return this._statEnd
    }

}