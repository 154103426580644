import { Form, defer, redirect, useLoaderData, useNavigation } from "react-router-dom";

import Select from 'react-select';
import { createVisitInterval, fetchClasses, userList } from "../../../http/adminApi";
import { Card, Col, Row, message } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { fetchVisitIntervals } from "../../../http/parentApi";
import { PropertiesType, UserType } from "../../../models/Users";
import SelectParent from "./SelectParent";
import { Button } from "react-bootstrap";


export async function action({ request, params }: any) {
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  console.log("admin visit action new", updates);
  if (updates.studentID && updates.intervalID && updates.begin && updates.end && updates.parentID) {
    const data = await createVisitInterval(updates);
    if (data > 0) {
      message.success("Записи прошла успешно!")
    } else {
      message.error("Записать не удалось!")
      //loadData()
    }
  }
  return redirect('..');
}

export async function loader({ request, params }: any) {
  console.debug("observation loader", params);
  const classes = await fetchClasses() as any;
  const students = await userList('student');
  //  const students = await studentsByClassList(params.classID);
  //  const items = getVisits();

  return defer({ classes, students });
}

interface IntervalType {
  id: number;
  name: string;
  begin: string;
  end: string;
  value: string | number;
  label: string;
}

export default observer(function NewObservation() {
  //  const [intervals, setIntervals] = useState<IntervalType[]>([]);
  const { classes } = useLoaderData() as any;
  const [disabled, setDisabled] = useState(true);
  const [selectedClassID, setSelectedClassID] = useState(0);
  const [selectedStudentID, setSelectedStudentID] = useState(0);
  const [selectedParentID, setSelectedParentID] = useState(0);
  const [intervalReserved, setIntervalReserved] = useState<{ label: string, value: number }>();
  const [visitIntervals, setVisitIntervals] = useState<IntervalType[]>()
  const [intervals, setIntervals] = useState<IntervalType[]>();
  const [selectedIntervalID, setSelectedIntervalID] = useState<{ label: string, value: number }>()

  const [students, setStudents] = useState([]);

  const navigation = useNavigation();
  const busy = navigation.state === 'submitting';

  const [begin, setBegin] = useState<undefined | string>(undefined);
  const [end, setEnd] = useState<undefined | string>(undefined);

  useEffect(() => {

    userList('student').then((data: any) => {
      const students = data.filter((i: UserType) => i.properties.find((prop: PropertiesType) => prop.key === 'Класс')?.value == selectedClassID).map((clas: any) => { return { label: clas.name, value: clas.id } })
      setStudents(students);

      //      console.log('d', students, data);
    })
  }, [selectedClassID])

  useEffect(() => {
    if (selectedStudentID) {
      fetchVisitIntervals(selectedStudentID).then((data: IntervalType[]) => {
        setIntervals(data);
        const intervals = data.map((interval: IntervalType) => { return { ...interval, label: interval.name, value: interval.id } });
        setVisitIntervals(intervals);
      })
    }
  }, [selectedStudentID])

  useEffect(() => {
    if (selectedClassID && selectedStudentID && selectedIntervalID && selectedParentID) setDisabled(false);
  }, [selectedClassID, selectedStudentID, selectedIntervalID, selectedParentID]);

  /* 
    const onChange = (event: any) => {
      const values = form.getFieldsValue();
      console.log('onChange', event, values);
      if (values['class'] && values['student'] && values['interval'] && values['parent']) {
        //setIsDisabled(false);
      } else {
        // setIsDisabled(true);
      }
    }
   */
  return (
    <Card title='Добавить запись на наблюдение'>
      <Form /* {...formItemLayout} */ action='' method="post">
        <fieldset disabled={busy}>
          <Row align={'middle'} /* gutter={[16, 16]} */>
            <Col span={4}><label>Класс</label></Col>
            <Col span={20}>
              <Select
                id="classID"
                options={classes.map((clas: any) => { return { label: clas.name, value: clas.id } })}
                //value={{ label: classes[0].name, value: classes[0].id }}
                placeholder="Выберете класс"
                name="classID"
                onChange={(value: any) => {
                  setSelectedClassID(value.value);
                  setSelectedStudentID(0);
                  setSelectedParentID(0);
                }}
              />
            </Col>
          </Row>
          {Boolean(selectedClassID) && <>
            <Row align={'middle'} /* gutter={[16, 24]} */>
              <Col span={4}><label>Студент</label></Col>
              <Col span={20}>
                <Select
                  //isDisabled={true}
                  placeholder="Выберете студента"
                  name="studentID"
                  options={students}
                  onChange={(value: any) => { setSelectedStudentID(value.value) }}
                  value={students?.filter((item: any) => item.value === selectedStudentID)}
                />
              </Col>
            </Row>

            {Boolean(selectedStudentID) && <>
              {visitIntervals?.length === 0 ? <p>Нет свободных дней для записи</p> :
                intervalReserved ? <p>Уже запись на {intervalReserved.label}</p> :
                  <>
                    <Row align={'middle'} /* gutter={[16, 24]} */>
                      <Col span={4}><label>Интервал</label></Col>
                      <Col span={20}>
                        <Select
                          //type="text"
                          name="intervalID"
                          placeholder="Выберете интервал"
                          //defaultValue={selectedIntervalID}
                          options={visitIntervals}
                          value={visitIntervals?.filter((item: any) => item.value === selectedIntervalID)}
                          onChange={(target: any) => {
                            setSelectedIntervalID(target.value);
                            if (visitIntervals) {
                              const interval = visitIntervals.find((interval: IntervalType) => interval.value === target.value);
                              if (interval) {
                                setBegin(interval?.begin)
                                setEnd(interval?.end)
                              }
                            }
                          }}
                        />
                      </Col>
                    </Row>

                    <Row align={'middle'} /* gutter={[16, 24]} */>
                      <Col span={4}><label>Родитель</label></Col>
                      <Col span={20}>
                        <SelectParent studentID={selectedStudentID}
                          onChange={(value: any) => { setSelectedParentID(value.value) }}
                        />
                      </Col>
                    </Row>
                  </>
              }
            </>}
          </>
          }
          <Row>
            <Col>&nbsp;</Col>
          </Row>
          <Row>
            <Col span={20} /* offset={8} */>
              <input type="hidden" name="begin" value={begin} />
              <input type="hidden" name="end" value={end} />

              <Button type="submit">Отменить</Button>
              <Button type="submit" disabled={disabled}>Сохранить</Button>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </Card >
  );
})