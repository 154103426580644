import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { fetchResponses, modyfyResponse, createResponse } from "../../../http/studentApi";
import { Context } from "../../..";
import { FaPlus } from "react-icons/fa";
import Editor from "../../utilites/quill/EditorBlur";
import HTMLViewer from "../../utilites/HTMLViewer";
import { InputNumber, message } from "antd";
//import 'antd/dist/antd.css';
import FilesUpload from "../../utilites/FilesUpload";
import StudentAnswerEditable from "./StudentAnswerEditable";
import TeacherCommentNotEditable from "../../teacher/classes/TeacherCommentNotEditable";
import { modyfyTeacherScores } from "../../../http/teacherApi";
import ResponseAnswers from "../../utilites/classResponse/ResponseAnswer";
import ResponseScores from "../../utilites/classResponse/ResponseScore";
import ResponseAnswersIterator from "../../utilites/classResponse/ResponseAnswersIterator";

const StudentResponses = observer(({student}) => {
   // const { user } = useContext(Context)
   // const { student } = useContext(Context)
    const [responses, setResponses] = useState([])

    useEffect(() => {
        loadData()
    }, [student.selectedDescription])

    const loadData = async () => {
        /*         const data = await oneDescription(descID)
                console.log("FFF", data, descID); */
        //  setDescription(student.selectedDescription)
        //const dataResp = await fetchResponses(student.selectedDescription.id, user.user.id)
        const dataResp = await fetchResponses(student.selectedDescription.id, student.studentData.id)
        setResponses(dataResp)
    }

    const changeAnswer = (comment, answerID, responseID) => {
        //changeResponse(answer, null, id)
        // setResponses(responses.map(i => i.id === id ? { ...i, ['answer']: answer } : i))
        setResponses(responses.map(i => i.id === responseID ?
            {
                ...i, ['answers']: i.answers.map(j => j.id === answerID ?
                    { ...j, ['comment']: comment }
                    : j)
            }
            : i)
        )
    }

    const changeScore = (score, answerID, responseID) => {
        //changeResponse(null, score, id)
        // setResponses(responses.map(i => i.id === id ? { ...i, ['studentScore']: score } : i))
        setResponses(responses.map(i => i.id === responseID ?
            {
                ...i, ['answers']: i.answers.map(j => j.id === answerID ?
                    { ...j, ['score']: score }
                    : j)
            }
            : i)
        )
    }

    const saveAnswer = async (answerID, responseID) => {
        // console.log("SAV", answerID, responseID);
        let response = responses.find(i => i.id === responseID)
        let answer = response.answers.find(j => j.id === answerID)
        //console.log("SCORE", score);
        const data = await modyfyTeacherScores(answer.id, answer.score, answer.comment)
        if (data) {
            message.success(`Сохранение успешно!`);
        } else {
            message.error(`Сохранение не удалось...`);
        }
    }

    return (
        <Container>
            <HTMLViewer
                value={student.selectedDescription.text}
            ></HTMLViewer>
            <Container>
                <Row>
                    <Col sm={10}><b>Ответ</b></Col>
                    <Col sm={2}><b>Оценка</b></Col>
                </Row>
            </Container>
            <hr></hr>
            {responses.map(resp =>
                <ResponseAnswersIterator
                    responseID={resp.id}
                    changeAnswer={changeAnswer}
                    answers={resp.answers}
                    changeScore={changeScore}
                    saveAnswer={saveAnswer}
                    placeholder='Самооценка'
                ></ResponseAnswersIterator>
            )}
        </Container>
    );
});

export default StudentResponses;