import React, { useContext, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container } from 'react-bootstrap';
import RadarChart from '../../utilites/star/RadarChart';
import { fetchStar1Data, fetchStar2Data, fetchStar3Data, fetchStarSData, modyfyStarEduData, modyfyStarEduDataL2, modyfyStarEduDataL3, modyfyStarSubData } from '../../../http/studentApi';
import EditStar from '../../utilites/star/EditStar';
import { message } from 'antd';
import { fetchTipsData } from '../../../http/adminApi';
import SubjectsStore from '../../../store/SubjectsStore';
import { fetchStar3DataBSID } from '../../../http/parentApi';
/* import star1 from './Star1.png'
import star2 from './Star2.png' */
//Звезды - прогресс студента уровень 3
const StudentStarL3 = observer(({ mode = 'student' }) => {
    const { student } = useContext(Context)
    const { parent } = useContext(Context)

    const [star3, setStar3] = useState([])

    useEffect(() => {
        loadData()
    }, [, parent.studentData])

    const loadData = async () => {
        let id
        if (mode == 'student') {
            id = student.selectedModule?.id
        } else {
            id = parent.selectedModule?.id
        }
        let data3
        if (mode == 'student') {
            data3 = await fetchStar3Data(id)
        } else {
            data3 = await fetchStar3DataBSID(id, parent.studentData.id)
        }
        if (data3) {
            setStar3(data3)
        }
        //student.setEducationRoute(data1)
/*         const tips = await fetchTipsData('student')
        setTipsTextDataSub(tips) */

        //для проверки нового стора (SubjectsStore)
        //subjects = [...student.studentSubjects]
    }

    const chartSize = 450;
    const numberOfScale = 5;

    const saveEducationRouteDataL3 = async () => {
        try {
            const formData = new FormData()
            formData.append('starData', JSON.stringify(star3))
            formData.append('subjectID', student.selectedModule?.id)
            modyfyStarEduDataL3(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }

    const getHeader = () => {
        let ret = ''
        if (mode == 'student') {
            ret = student.studentSubjects?.find(i => i.id == student.selectedSubject)?.name + ' / ' + student.studentModules?.find(i => i.id == student.selectedModule)?.name
        }
        if (mode == 'parent') {
            ret = parent.studentSubjects?.find(i => i.id == parent.selectedSubject)?.name + ' / ' + parent.studentModules?.find(i => i.id == parent.selectedModule)?.name
        }
        return ret
    }

    return (
        <Container>
            <EditStar
                header={getHeader()}
                starS={star3}
                setStarS={setStar3}
                starLayer={1}
                saveData={saveEducationRouteDataL3}
                greenDesc='- планируемый уровень'
                yellowDesc='- текуший уровень'
                editable={mode == 'parent' ? false : true}
            />
        </Container>
    );
});

export default StudentStarL3;