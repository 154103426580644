import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Form } from 'react-bootstrap';
import { fetchWorkTypes } from '../../../http/adminApi';
import { useLoaderData } from 'react-router-dom';

const TeacherSelectWorkType = observer(({classubID, selectedWorkTypeID, selectWorkType}) => {
    const [workTypes, setWorkTypes] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const data = await fetchWorkTypes(classubID)
        setWorkTypes(data)
        selectWorkType(data[0].id)
    }

    return (
        <Form className="mx-2">
            <Form.Select onChange={(e) => selectWorkType(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={selectedWorkTypeID} aria-label="Default select example">
                {workTypes.map(workType =>
                    <option key={workType.id} value={workType.id}>{workType.name}</option>
                )}
            </Form.Select>
        </Form>
    );
});

export default TeacherSelectWorkType;