import React, { useContext } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import '../navbar.css'

const StudentBar = observer(() => {
  const { student } = useContext(Context)

  function click(menuItem) {
    student.setSelectedSubMenu({})
    student.setSelectedSideMenu(menuItem)
  }

  return (
    <ListGroup style={{maxWidth: '250px'}}>
      {student.sideMenu.map(menuItem =>
        <ListGroup.Item bsPrefix="list-group-item listgroupitemadm"
          style={{ cursor: 'pointer' }}
          active={menuItem.id === student.selectedSideMenu.id}
          onClick={() => click(menuItem)}
          key={menuItem.id}
        >
          {menuItem.name}
        </ListGroup.Item>
      )}
    </ListGroup>
  );
});

export default StudentBar;