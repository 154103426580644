import React, { useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container } from 'react-bootstrap';
import { fetchTeacherStudents } from '../../../http/teacherApi';
import { Context } from '../../..';

const TeacherStudents = observer(({ nextID }) => {
    const { teacher } = useContext(Context)

    useEffect(() => {
        loadData()
    }, [teacher.setSelectedSubMenu, teacher.selectedSideMenu])

    const loadData = async () => {
        const data = await fetchTeacherStudents(teacher.selectedClasSubject.classID)
        teacher.setTeacherStudents(data)
    }

    const openStudent = (student) => {
        teacher.setTeacherDescriptions([])
        teacher.setSelectedStudent(student)
        teacher.setSelectedSubMenu({ id: nextID })
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            Ученики {teacher.selectedClasSubject.className + ' / ' + teacher.selectedClasSubject.subjectName}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {teacher.teacherStudents.map(student =>
                        <tr key={student.id}>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => openStudent(student)}
                            >
                                {nextID === 42 ?
                                    student.name + ' (' + student.scoresCount + ')'
                                    :
                                    student.name
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default TeacherStudents;