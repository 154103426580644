import React, { useState, useEffect } from 'react';

const HTMLViewer = ({ value, style }) => {

//  const [divRef, setDivRef] = useState(React.createRef())

//  useEffect(() => {
//     let ref = React.createRef() */
 //   divRef.current.innerHTML = value;
//    ref = divRef
 //   setDivRef(ref) */
 // }, [value])
//{/* <div style={style} ref={divRef}></div> */}
  return (
    <div style={style} dangerouslySetInnerHTML={{__html: value}}></div>
  );
};

export default HTMLViewer;