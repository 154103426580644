import React, { useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Form } from 'react-bootstrap';
import { fetchModules, fetchThemes, getCurrentTheme } from '../../../http/teacherApi';
import { Context } from '../../..';
import { fetchDescriptionsByThemeID } from '../../../http/studentApi';
import HTMLViewer from '../../utilites/HTMLViewer';

const AdminStudent = observer(() => {
    const { admin } = useContext(Context)
    const { teacher } = useContext(Context)

    useEffect(() => {
        loadData()
    }, [admin.setSelectedSubMenu, admin.selectedSideMenu])

    /*     useEffect(() => {
            loadDescriptions()
        }, [teacher.selectedModule, teacher.selectedTheme]) */

    const loadData = async () => {
        const dataM = await fetchModules(admin.selectedClass.id, admin.selectedSubject.id)
        teacher.setTeacherModules(dataM)
        const dataMT = await getCurrentTheme(admin.selectedSubject.id, teacher.selectedStudent.id, admin.selectedClass.id)
        teacher.setSelectedModule(dataMT.module)
        teacher.setSelectedTheme(dataMT.theme)
        const dataT = await fetchThemes(dataMT.module.id)
        teacher.setTeacherThemes(dataT)
        const dataD = await fetchDescriptionsByThemeID(dataMT.theme.id, teacher.selectedStudent.id)
        teacher.setTeacherDescriptions(dataD)
    }

    /*     const loadDescriptions = async () => {
            const data = await fetchDescriptionsByThemeID(teacher.selectedTheme.id)
            teacher.setTeacherDescriptions(data)
        } */

    const openSetScore = (desc) => {
        teacher.setSelectedDescription(desc)
        admin.setSelectedSubMenu({ id: 43 })
    }

    const selectModule = async (moduleID) => {
        teacher.setSelectedModule(teacher.teacherModules.find(modul => modul.id == moduleID))
        const themes = await fetchThemes(moduleID)//.then(data => teacher.setTeacherThemes(data))
        teacher.setTeacherThemes(themes)
        teacher.setSelectedTheme(themes[0])
        const dataD = await fetchDescriptionsByThemeID(themes[0].id, teacher.selectedStudent.id)
        teacher.setTeacherDescriptions(dataD)
    }

    const selectTheme = async (themeID) => {
        teacher.setSelectedTheme(teacher.teacherThemes.find(theme => theme.id == themeID))
        await fetchDescriptionsByThemeID(themeID, teacher.selectedStudent.id).then(data => teacher.setTeacherDescriptions(data))
    }

    return (
        <Container>
            <div>Ученик {teacher.selectedStudent.name}</div>
            <Form className="mx-2">
                <Form.Select onChange={(e) => selectModule(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={teacher.selectedModule.id} aria-label="Default select example">
                    {teacher.teacherModules.map(module =>
                        <option key={module.id} value={module.id}>{module.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => selectTheme(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={teacher.selectedTheme.id} aria-label="Default select example">
                    {teacher.teacherThemes.map(theme =>
                        <option key={theme.id} value={theme.id}>{theme.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Маршрут</th>
                        <th>Описание</th>
                        <th>Оценка</th>
                    </tr>
                </thead>
                <tbody>
                    {teacher.teacherDescriptions.map(desc =>
                        <tr key={desc.id}>
                            <td key={"r" + desc.silabusRoute.id + desc.id} style={{ backgroundColor: desc.silabusRoute.color }}>
                                {desc.index === 1 && desc.silabusRoute.name}
                            </td>
                            <td key={"t" + desc.id} style={{ backgroundColor: desc.silabusRoute.color }}>
                                <HTMLViewer
                                    value={desc.text}
                                ></HTMLViewer>
                            </td>
                            {/*                             <td key={"dz" + desc.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(desc)}
                            >
                                ДЗ  
                            </td>
                            <td key={"st" + desc.id}>100</td>  */}
                            <td key={"ss" + desc.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => openSetScore(desc)}
                            >{desc.teacherScore}</td> {/* оценка учителя */}
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminStudent;