import React from 'react';
import { observer } from "mobx-react-lite";
import { Container } from 'react-bootstrap';
import HTMLViewer from '../../utilites/HTMLViewer';
import FilesUpload from '../../utilites/FilesUpload';

const TeacherCommentNotEditable = observer((score) => {

    return (
        <Container>
            <HTMLViewer
                value={score.answer}
            ></HTMLViewer>
            {Boolean(score.fileListStudent.length != 0) &&
                <FilesUpload
                    fileList={score.fileListStudent}
                    disabled={true}
                >
                </FilesUpload>
            }
            {Boolean(score.answer && score.comment) && <hr />}
            <HTMLViewer
                value={score.comment}
            ></HTMLViewer>
            {Boolean(score.fileListTeacher.length != 0) &&
                <FilesUpload
                    fileList={score.fileListTeacher}
                    disabled={true}
                >
                </FilesUpload>
            }
        </Container>
    );
});

export default TeacherCommentNotEditable;