import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { createVisitInterval, deleteVisitInterval } from '../../http/parentApi';
import { Checkbox, message } from 'antd';

//Запись на наблюдение
const ParentMeetingVisit = ({ student, visitIntervals, carers, reload }) => {

    const [selectedIntervalID, setSelectedIntervalID] = useState({})
    const [noIntervals, setNoIntervals] = useState(false)
    const [alreadyVisit, setAlreadyVisit] = useState(false)

    const [selectedCarers, setSelectedCarers] = useState([])

    useEffect(() => {
        //setAlreadyVisit(false)
        //setNoIntervals(false)
        if (student?.id) {
            if (visitIntervals.length == 0) {
                // нет достыпных интервалов
                setAlreadyVisit(false)
                setNoIntervals(true)
            } else
                if (visitIntervals.length == 1 && visitIntervals[0]?.reserved) {
                    // уже записаны
                    setAlreadyVisit(true)
                    setNoIntervals(false)
                    setSelectedIntervalID(visitIntervals[0]?.id)
                } else {
                    // не записаны и есть интервалы
                    setAlreadyVisit(false)
                    setNoIntervals(false)
                    setSelectedIntervalID(visitIntervals[0]?.id)
                }
        }
    }, [student, visitIntervals, carers])

    const addVisit = async () => {
        //console.log("DDDD", visitIntervals, selectedIntervalID, visitIntervals.find(i => i.id == selectedIntervalID));
        let begin = visitIntervals.find(i => i.id == selectedIntervalID).begin
        let end = visitIntervals.find(i => i.id == selectedIntervalID).end
        const data = await createVisitInterval(selectedIntervalID, student?.id, selectedCarers, begin, end)
        if (data > 0) {
            message.success("Запись на наблюдение прошла успешно!")
            reload()
        } else {
            message.error("Записатся не удалось, пожайлуста выберете другую дату посещения")
            reload()
        }
    }

    const removeVisit = async () => {
        const data = await deleteVisitInterval(selectedIntervalID)
        if (data > 0) {
            message.success("Отмена записи прошла успешно!")
            //setAlreadyVisit(false)
            reload()
        } else {
            message.error("Отменить запись не удалось, пожайлуста свяжитесь с администратором")
            reload()
        }
    }

    const onChangeCarers = (checkedValues) => {
        //console.log('checked = ', checkedValues);
        setSelectedCarers(checkedValues)
    };

    return (
        <Container style={{ backgroundColor: "white", textAlign: "center", padding: "15px" }}>
            {alreadyVisit ?
                <>
                    <p style={{ fontSize: "16px" }}>Вы записаны на наблюдение {visitIntervals[0]?.name}</p>
                    <Button disabled={visitIntervals[0]?.disabled} onClick={() => removeVisit()} className='float-middle'>Отменить запись</Button>
                </>
                :
                <>
                    <p style={{ fontSize: "16px" }}>Запись на наблюдение студента {student?.name} в течении учебного дня в школе.</p>
                    {noIntervals ? "Нет свободных дней для записи" :
                        <Form className="mx-2">
                            <Form.Select onChange={(e) => setSelectedIntervalID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={selectedIntervalID} aria-label="Default select example">
                                {visitIntervals.map(interval =>
                                    <option key={'interval' + interval.id} value={interval.id}>{interval.name}</option>
                                )}
                            </Form.Select>
                        </Form>
                    }
{/*                     {carers.length > 0 &&
                        <>
                            <p style={{ marginTop: "15px" }}>Создать событие о записи на наблюдение у пользователей:</p>
                            <Checkbox.Group
                                style={{
                                    width: '100%',
                                    marginBottom: '15px',
                                }}
                                //value={roles}
                                onChange={onChangeCarers} >
                                {carers.map(carer =>
                                    <Checkbox value={carer.userID}>{carer.name}</Checkbox>
                                )}
                            </Checkbox.Group>
                        </>
                    } */}
                    <p style={{ marginTop: "15px" }}>&nbsp;
                        <Button disabled={noIntervals} onClick={() => addVisit()} className='float-middle'>Записатся</Button>
                    </p>
                </>
            }
        </Container >
    );
};

export default ParentMeetingVisit;