import React, { useContext } from 'react';
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx"
import { Context } from '../..';
import '../navbar.css'
import { Breadcrumb } from 'antd';
//import 'antd/dist/antd.css';

const TeacherSubBar = observer(() => {
    const { teacher } = useContext(Context)


    function click(subMenuItem) {
        runInAction(() => {
            teacher.setSelectedSubMenu({ id: subMenuItem })
        })
    }

    function clickRoot(menuItem) {
        runInAction(() => {
            teacher.setSelectedSubMenu({})
            teacher.setSelectedSideMenu({ id: menuItem })
        })
    }

    const style = {
        cursor: 'pointer',
        backgroundColor: '#ffd110',
        paddingBottom: '5px',
        paddingLeft: '5px',
        paddingTop: '5px',
        paddingRight: '5px'
    };

    const getClassID = () => { 
        if (Object.keys(teacher.selectedClasSubjectSyllabus).length != 0) {
            return teacher.selectedClasSubjectSyllabus?.split('|')[0]
        } else {
            return teacher.classesSubjects[0]?.id.split('|')[0]
        }
    }

    const getSubjectID = () => {
        if (Object.keys(teacher.selectedClasSubjectSyllabus).length != 0) {
            return teacher.selectedClasSubjectSyllabus?.split('|')[1]
        } else {
            return teacher.classesSubjects[0]?.id.split('|')[1]
        }
    }

    return (
        <Breadcrumb style={{ 'marginBottom': '15px' }}>
            {Boolean(teacher.selectedSubMenu?.id == '41' | teacher.selectedSubMenu?.id == '42' | teacher.selectedSubMenu?.id == '43') &&
                <Breadcrumb.Item onClick={() => clickRoot(4)} style={style}>
                    {teacher.selectedClasSubject.className + ' / ' + teacher.selectedClasSubject.subjectName}
                </Breadcrumb.Item>
            }
            {Boolean(teacher.selectedSubMenu?.id == '42' | teacher.selectedSubMenu?.id == '43') &&
                <Breadcrumb.Item onClick={() => click(41)} style={style}>
                    Ученики {teacher.selectedClasSubject.className}
                </Breadcrumb.Item>
            }
            {teacher.selectedSubMenu?.id == '43' &&
                <Breadcrumb.Item onClick={() => click(42)} style={style}>
                    Ученик {teacher.selectedStudent.name}
                </Breadcrumb.Item>
            }
            {Boolean(teacher.selectedSubMenu?.id == '61' | teacher.selectedSubMenu?.id == '62' | teacher.selectedSubMenu?.id == '63') &&
                <Breadcrumb.Item onClick={() => clickRoot(6)} style={style}>
                    {teacher.classes.find((e) => e.id == getClassID())?.name + ' / ' + teacher.subjects.find((e) => e.id == getSubjectID())?.name}
                </Breadcrumb.Item>
            }
            {Boolean(teacher.selectedSubMenu?.id == '62' | teacher.selectedSubMenu?.id == '63') &&
                <Breadcrumb.Item onClick={() => click(61)} style={style}>
                    Разделы для модуля #{teacher.selectedModule.index} ({teacher.selectedModule.name})
                </Breadcrumb.Item>
            }
            {teacher.selectedSubMenu?.id == '63' &&
                <Breadcrumb.Item onClick={() => click(62)} style={style}>
                    Темы уроков для раздела #{teacher.selectedTheme.index} ({teacher.selectedTheme.name})
                </Breadcrumb.Item>
            }
        </Breadcrumb>

        /*        {id: 61, name: "Темы"},  
                {id: 62, name: "Маршруты"}, 
                {id: 63, name: "Описания"}  */

    );
});

export default TeacherSubBar;