import React, { useContext } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import '../navbar.css'

const ParentBar = observer(() => {
  const { parent } = useContext(Context)

  function click(menuItem) {
    parent.setSelectedSubMenu({})
    parent.setSelectedSideMenu(menuItem)
  }

  return (
    <ListGroup>
      {parent.sideMenu.map(menuItem =>
        <ListGroup.Item bsPrefix="list-group-item listgroupitemadm"
          style={{ cursor: 'pointer' }}
          active={menuItem.id === parent.selectedSideMenu.id}
          onClick={() => click(menuItem)}
          key={menuItem.id}
        >
          {menuItem.name}
        </ListGroup.Item>
      )}
    </ListGroup>
  );
});

export default ParentBar;