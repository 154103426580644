import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Input } from 'antd';
import { Container } from 'react-bootstrap';
import HTMLViewer from '../HTMLViewer';
import * as ReactDOM from 'react-dom';
import { EditOutlined } from '@ant-design/icons';


const { TextArea } = Input;

const Editor = (props) => {
  const [value, setValue] = useState(props.data);
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    setValue(props.data)
  }, [props.data])

  useEffect(() => {
    setEdit(props.edit)
  }, [props.edit])

  const checkBlur = () => {
    props.funcToSave(value)
//    setEdit(false) // - механизьм сохранения по кнопке... ?
  }

  const checkClick = () => {
    setEdit(true)
    /*     quill.focus() */
  }

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  /*   const quill = React.createElement("ReactQuill",{
      modules: modules,
      theme: "snow",
      value: value,
      onChange: setValue,
      onBlur: checkBlur,
    });
  
    const rootElement = document.getElementById("cont"); */


  return (<div style={{ 'marginBottom': '15px', 'backgroundColor':'white' }}>
    {edit ?
      // ReactDOM.render(quill, rootElement)
      <ReactQuill modules={modules} theme="snow" value={value} onChange={setValue} onBlur={checkBlur} />
      :
      <div onClick={checkClick} style={{ cursor: 'pointer' }}>
        <EditOutlined />
        <HTMLViewer value={value}></HTMLViewer>
      </div>
    }
  </div>)
}

export default Editor;