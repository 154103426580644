import React from 'react';
import { Container } from 'react-bootstrap';
import { Dropdown, Space, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const AttendanceDropdownComment = ({ items, attendance, changeData }) => {

    const handleMenuClick = ({ key }) => {
        changeData(key, attendance.id)
        attendance.comment = key
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown menu={menuProps}>
            <Button>
                <Space>
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
};

export default AttendanceDropdownComment;