import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { createEvent, deleteEvent, modyfyEvent, fetchEvents, fetchCalendarEventTypes, fetchEvents2, modyfyEventO } from '../../http/adminApi';
import { Card, DatePicker, Popconfirm, Typography, message } from 'antd';
import { TimePicker } from 'antd';
import Select, { StylesConfig } from 'react-select'
import chroma from 'chroma-js';
import MyCalendar from '../Calendar';


//import moment from 'moment';
//import 'moment/locale/ru';
import dayjs from 'dayjs'
import { redirect, useLoaderData, useSubmit } from 'react-router-dom';
dayjs.locale('ru')

const { Paragraph } = Typography;


const timeFormat = 'HH:mm';
const format = "YYYY-MM-DD HH:mm"



interface loaderAPIType {
    context: {},
    request: {},
    params: {}
}

export const eventRouter = [
    { path: 'new', element: <></>, action: newEvent },
    { path: ':id/destroy', element: <></>, loader: destroyEvent },

]

export const loaderEvents = async ({ params }: any) => {
    //console.log('=events loader===================================', params);
    const events_raw = await fetchEvents2()
    const eventTypes = await fetchCalendarEventTypes();
    const colors = eventTypes.map((item: any) => { return { value: item.id, label: item.name, color: item.color } });
    const events = events_raw.map((event: any) => { return { ...event, calendareventtype: eventTypes.find((eventtype: any) => eventtype.id == event.type) } })

    return { events, eventTypes, colors }
}

export async function actionEvents({ params, request }: any) {
    const formData = await request.formData();
    const { event } = Object.fromEntries(formData);
    if (event) {
        const event2 = JSON.parse(event)
        //console.log("eventsTable2", event, event2);
        modyfyEventO(event2)
    }


    return { event }
}

export async function newEvent({ params, request }: any) {
    let date = dayjs().format(format)
    let end = dayjs().add(3600000, 'seconds').format(format)
    const data = await createEvent(date, end)
    return redirect('..');
    //setEvents([{ name: '', date, end, id: data }, ...events])

    /*     const formData = await request.formData();
        const { event } = Object.fromEntries(formData);
        if (event) { 
            const event2 = JSON.parse(event)
            console.log("eventsTable2", event, event2);
            modyfyEventO(event2)
        }
    
    
        return { event } */
}

export async function destroyEvent({ params, request }: any) {
    const { id } = params
    if (id) {
        deleteEvent(id)
    }
    return redirect('..')
}

interface event {
    id: number,
    name: string,
    date: string,
    end: string,

}

export type ColourOption = {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
};


const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 3,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 15,
        width: 15,
    },
});

const colourStyles: StylesConfig<ColourOption> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(data.color);
        return {
            ...styles,
            ...dot(data.color),
            /*             backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                                ? data.color
                                : isFocused
                                    ? color.alpha(0.1).css()
                                    : undefined, */
            /*             color: isDisabled
                            ? '#ccc'
                            : isSelected
                                ? chroma.contrast(color, 'white') > 2
                                    ? 'white'
                                    : 'black'
                                : data.color, */
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,

            },
        };
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};


const AdminEventsTable2 = observer(() => {
    const submit = useSubmit();
    const { admin } = useContext(Context)

    const { events, eventTypes, colors } = useLoaderData() as any;
    //    console.log(eventTypes, colors);

    if (Array.isArray(events)) {
        //console.log(events);

        admin.setCalendarEvents(events)
    } else message.error("Получены не корректные данные от сервера")


    const newEvent = async () => {
        console.log("new event");
        let formData = new FormData();
        formData.append("event", JSON.stringify({}));
        submit(formData, { method: "POST", action: 'new' });
        return
    }

    /*     const changeDate = async (date: any, id: any) => {
            let data = await modyfyEvent(dayjs(date[0]).format(), null, id, dayjs(date[1]).format())
            console.log('Formatted Selected Time: ', date);
            //setEvents(events.map(i => i.id === id ? { ...i, ['date']: date[0], ['end']: date[1] } : i))
            admin.setCalendarEvents(admin.calendarEvents.map(i => i.id === id ? { ...i, ['date']: date[0], ['end']: date[1] } : i))
        } */

    const removeEvent = async (eventID: number) => {
        console.log("destroy event", eventID);
        submit(null, { method: "GET", action: `${eventID}/destroy` });
        return redirect('.')
    }

    //  const disabledTime = { now: moment, type: ['start', 'end'], disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24] }
    const disabledTime = (value: any) => {
        return {
            type: ['start', 'end'],
            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24]
        }
    }

    const change = (event: any) => {
        console.log("change", event);
        let formData = new FormData();
        formData.append("event", JSON.stringify({ ...event }));
        submit(formData, { method: "POST" });

    }


    return (<>
        <Card title='Календарь событий' style={{ height: '58vw' }}>
            <MyCalendar key={Math.random()}/>
        </Card>

        <Card title='События календаря' extra={<Button onClick={() => newEvent()} className="float-end"><FaPlus className="d-flex justify-content-between" /></Button>}>
            <Table striped bordered hover>
                <tbody>
                    {events.map((event: any) =>
                        <tr key={event.id}>
                            <td>
                                <DatePicker.RangePicker
                                    showTime={{ format: timeFormat, }}
                                    //onChange={onChange} 
                                    //onOk={onOk}
                                    minuteStep={5}
                                    format={format}
                                    //defaultValue={[dayjs(event.date, format), dayjs(event.end, format)]}
                                    defaultValue={[dayjs(event.date), dayjs(event.end)]}
                                    //value={[moment(gItem.begin, format), moment(gItem.end, format)]}
                                    //onChange={(value, dateString) => changeDate(dateString, event.id)}
                                    onChange={(value, dateString) => change({ date: dateString[0], end: dateString[1], id: event.id })}

                                    disabledTime={disabledTime}
                                />
                            </td>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Название события',
                                        onChange: (name) => change({ id: event.id, name }),// changeName(value, event.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {event.name}
                                </Paragraph>
                            </td>
                            <td>
                                <Select
                                    onChange={(value: any) => change({ id: event.id, type: value.value }/* { color: colors.filter((item: any) => item.value === value.value)[0]['color'] } */)}
                                    name="color" /* style={{ width: '100%' }}  */
                                    placeholder='Выберите цвет'
                                    value={colors.filter((item: any) => item.value === event.type)}
                                    options={colors}
                                    styles={colourStyles}
                                />
                                {event?.color}
                            </td>
                            <td>
                                <Popconfirm
                                    title={'Вы уверены что хотите удалить событие?'}
                                    onConfirm={() => removeEvent(event.id)}
                                    onCancel={() => message.warning('Удаление отменено')}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button className='float-end'>
                                        <FaMinus className="d-flex justify-content-between" />
                                    </Button>
                                </Popconfirm>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    </>
    );
});

export default AdminEventsTable2;