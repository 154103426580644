import React, { useEffect, useContext, useState } from 'react';
//import Table from 'react-bootstrap/Table';
import { Card, Modal, Popconfirm, Table, Tooltip, message } from 'antd';
import { observer } from "mobx-react-lite";
import { Button, Container, /* Form */ } from 'react-bootstrap';
import { Context } from '../../..';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from 'antd';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { deleteMKInterval } from '../../../http/adminApi';
import AdminIKModal, { newIK } from './AdminIKModal';
import { fetchParentIK } from '../../../http/adminApi';
import { Form } from 'antd';


const { RangePicker } = DatePicker;

const AdminParentIK = observer(({ studentID }) => {
    const { admin } = useContext(Context)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const [form] = Form.useForm();

    const dateFormat = 'DD.MM.YYYY';
    const dateFormatForDB = 'YYYY-MM-DD'

    const handleOk = async () => {
        setIsModalOpen(false);
        console.log(form.getFieldsValue());
        await newIK(await form.getFieldsValue()).then(() => {
            loadData();
        });

    }

    const handleCancel = async () => {
        setIsModalOpen(false);
        form.resetFields();
    }
    const showModal = () => {
        setIsModalOpen(true);
        form.resetFields();
        setIsDisabled(true);
    };

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            fixed: 'left',
        },
        {
            title: 'Класс',
            dataIndex: 'class',
        },
        //  {
        //      title: 'Студент',
        //      dataIndex: 'student',
        //  },
        {
            title: 'Родитель',
            dataIndex: 'parent',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
        },
        {
            title: <Button onClick={showModal} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button>,
            dataIndex: 'id',
            fixed: 'right',
            width: 60,
        },
    ]


    const ikList1 = [{ "date": "12.10.2023 с 8:00 до 14:00", "class": "5 Класс", "student": "Петрова Анастасия", "parent": "Петров Илья Андреевич", "id": 3, "phone": "322412451" },
    { "date": "16.10.2023 с 8:00 до 14:00", "class": "3 Класс", "student": "Иванов Илья", "parent": "Иванова Мария Павловна", "id": 5, "phone": "2222412451" }
    ]

    const [ikList, setIKList] = useState([])

    //    const [begin, setBegin] = useState(moment(moment.now() - 604800000))
    //    const [end, setEnd] = useState(moment(moment.now()))

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        let data = await fetchParentIK()
        setIKList(data.map(ik => {
            return {
                ...ik,
                ['key']: ik.id,
                ['id']: <Popconfirm
                    title={'Вы уверены что хотите удалить запись?'}
                    onConfirm={() => removeIK(ik.id)}
                    onCancel={() => message.warning('Удаление отменено')}
                    okText="Да"
                    cancelText="Нет"
                >
                    <Button className='float-end'>
                        <FaMinus className="d-flex justify-content-between" />
                    </Button>
                </Popconfirm>
            }
        }))


        /*         setSubjectsList(data.subjects.map(sub => {
                    for (var key in sub) {
                        if (key != 'id' && key != 'subjectName' && key != 'hist' && key != 'key') {
                            sub[key] = <AttendanceStudentStatus cell={sub[key]} />
                        }
                    }
                    return {
                        ...sub,
                        ['key']: sub.id,
                        ['hist']: <AttendanceStudentHist hist={sub.hist} />
                    }
                })) */

    }

    const removeIK = async (intervalID) => {
        const data = await deleteMKInterval(intervalID)
        if (data > 0) {
            message.success("Отмена записи прошла успешно!")
            loadData()
        } else {
            message.error("Отменить запись не удалось!")
            //loadData()
        }
    }

    return (
        <Card>
            {/*    <RangePicker
                //value={[begin, end]}
                value={[student.attBegin, student.attEnd]}
                format={[dateFormat, dateFormat]}
                allowClear={false}
                onChange={(value) => changePeriod(value)}
            // disabledDate={disabledDate}
            // onCalendarChange={(val) => setDates(val)}
            // onChange={(val) => setValue(val)}
            // onOpenChange={onOpenChange} 
            /> */}

            <Table
                style={{ marginTop: '15px' }}
                columns={columns}
                dataSource={ikList}
                //pagination={false}
                pagination={{
                    pageSize: 25,
                }}
                scroll={{
                    x: 500,
                }}
            />
            <Modal
                open={isModalOpen}
                title='Добавить запись на ИК'
                onOk={handleOk}
                onCancel={handleCancel}
                width={650}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Отменить
                    </Button>,
                    <Button key="submit" type="primary" /* loading={loading} */ onClick={handleOk} disabled={isDisabled}> 
                        Добавить
                    </Button>,
                ]}
            >
                <AdminIKModal show={isModalOpen} onHide={() => { loadData(); }} form={form} setIsDisabled={setIsDisabled}/>
            </Modal>
        </Card>
    );
});

export default AdminParentIK;