import { Card, Select } from "antd";
import EditStar from "../../utilites/star/EditStar";

const SkillStars = (params) => {
    return (
        <Card title="Индивидуальный план развития" key={"b" + params.studentID}>
{/*             <div style={{ width: '100%', }}>
                <Select
                    mode='multiple'
                    placeholder='Выберите такт'
                    options={params.skillTacts}
                    style={{ width: '100%' }}
                    onChange={params.handleChangeSkillTacts}
                />
            </div> */}

            {params.starS.length > 0 &&
                <>
                    {params.starS.map((item, index) => 
                        <EditStar
                        key = {"editstar"+index}
                        header={item.title}
                            starS={item.star}
                            //                            setStarS={setStarS}
                            tipsTextData={params.tipsTextData}
                            starLayer={0}
                            saveData={params.saveData}
                            greenDesc='- самооценка студента'
                            yellowDesc='- оценка тьютора'
                            editable={item.editable ? true : params.editable}
                        />
                    )}
                </>
            }
        </Card>
    )
}

export default SkillStars;