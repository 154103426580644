import { makeAutoObservable } from "mobx";

export default class ParentStore {
    constructor() {
        this._sideMenu = [
            { id: 4, name: "Прогресс" },
            { id: 1, name: "Расписание" },
            { id: 7, name: "Посещаемость" },
            { id: 8, name: "Успеваемость" },
            { id: 6, name: "Маршруты" },
            { id: 10, name: "Запись на встречу" },
            { id: 9, name: "Календарь событий" },
           // { id: 3, name: "Настройки" },
        ]
        this._selectedSideMenu = { id: 1, name: "Расписание" }

        this._subMenu = [
            { id: 61, name: "Маршрут-описание" },
            { id: 62, name: "Силабусы самостоятельная работа" },
            { id: 41, name: "Звезда 2" },
            { id: 42, name: "Звезда 3" },
        ]
        this._selectedSubMenu = {id:0}

        this._students = []
        this._studentData = {id:0}
        this._studentClass = 0

        this._studentSchedule = []

        this._studentSubjects = []
        this._selectedSubject = 0

        this._studentModules = []
        this._selectedModule = {}

        this._educationRoute = []

        this._selectedTheme = {}
        this._selectedDescription = {}

        makeAutoObservable(this)
    }

    setSideMenu(sidemenu) {
        this._sideMenu = sidemenu
    }
    setSelectedSideMenu(menuitem) {
        this._selectedSideMenu = menuitem
    }
    setSubMenu(submenu) {
        this._subMenu = submenu
    }
    setSelectedSubMenu(menuitem) {
        this._selectedSubMenu = menuitem
    }

    setStudents(students) {
        this._students = students
    }
    setStudentData(studentData) {
        this._studentData = studentData
    }
    setStudentClass(studentClass) {
        this._studentClass = studentClass
    }
    setStudentSchedule(studentSchedule) {
        this._studentSchedule = studentSchedule
    }
    setStudentSubjects(studentSubjects) {
        this._studentSubjects = studentSubjects
    }
    setSelectedSubject(subject) {
        this._selectedSubject = subject
    }
    setStudentModules(studentModules) {
        this._studentModules = studentModules
    }
    setSelectedModule(selectedModule) {
        this._selectedModule = selectedModule
    }

    setEducationRoute(educationRoute) {
        this._educationRoute = educationRoute
    }

    setSelectedTheme(theme) {
        this._selectedTheme = theme
    }
    setSelectedDescription(desc) {
        this._selectedDescription = desc
    }

    get sideMenu() {
        return this._sideMenu
    }
    get selectedSideMenu() {
        return this._selectedSideMenu
    }
    get subMenu() {
        return this._subMenu
    }
    get selectedSubMenu() {
        return this._selectedSubMenu
    }

    get students() {
        return this._students
    }
    get studentData() {
        return this._studentData
    }
    get studentClass() {
        return this._studentClass
    }
    get studentSchedule() {
        return this._studentSchedule
    }
    get studentSubjects() {
        return this._studentSubjects
    }
    get selectedSubject() {
        return this._selectedSubject
    }
    get studentModules() {
        return this._studentModules
    }
    get selectedModule() {
        return this._selectedModule
    }

    get educationRoute() {
        return this._educationRoute
    }

    get selectedTheme() {
        return this._selectedTheme
    }
    get selectedDescription() {
        return this._selectedDescription
    }

}