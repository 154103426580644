import { useContext } from 'react';
import { Context } from "../index";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { CLIENT_VERSION, LOGIN_ROUTE} from "../utils/consts";
import { Button, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import {  useNavigate } from 'react-router-dom';
import './navbar.css'
import { getServerVersion } from '../http/userApi';

import { observer } from 'mobx-react-lite';
import { Spin } from 'antd';

const NavBar = observer(() => {

    const { store } = useContext(Context);

    const roles = store.roles;

    const { parent } = useContext(Context)

    const navigate = useNavigate()

    const serverVersion = getServerVersion();

    const logOut = () => {
        store.setUser({id:0, role:'', roles:''})
        store.setAuth(false)
        store.logout();
        navigate('/login', { replace: true })
    }

    function changeRole(role:any) {
/*         console.log('===chrole=================================');
        console.log(role);
        console.log('===================================='); */
        store.setRole(role)
        //        user.user.role = role
        if (store.isAuth) {
            navigate('/' + store.role, { replace: true });
        } else {
            navigate(LOGIN_ROUTE, { replace: true });
        }

    }

    function changeStudent(studentID:number) {
        parent.setStudentData(parent.students.find(item => item.id == studentID))
        // console.log("SD1", parent.studentData, studentID, parent.students, parent.students.find(i => i.id == studentID));
    }

    function getRoleName(role:string) {
        let res = ''
        switch (role) {
            case 'tutor': res = "Тьютор"
                break;
            case 'teacher': res = "Учитель"
                break;
            case 'student': res = "Студент"
                break;
            case 'parent': res = "Родитель"
                break;
            case 'ADMIN': res = "Администратор"
                break;
            default: res = "Администратор"
        }
        return res
    }
    
    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                {store.isLoading ? (<Spin /* size="large" */ />) : (<Navbar.Brand href="https://sg24.info/">sg24 </Navbar.Brand>)}


                {/* <span className="navbar-text">C:{CLIENT_VERSION} / S:{serverVersion} / A:{process.env.REACT_APP_API_URL}</span> */}

                {store.isAuth ?
                    <Nav className="ml-auto" style={{ color: 'white' }}>
                        {/* {user.role === 'ADMIN' &&
                        <Form className="mx-2">        
                            <Form.Select className="bg-transparent" bsPrefix="form-select navselect" defaultValue={admin.selectedBranch?.id} aria-label="Default select example">
                                    <option onClick={() => admin.setSelectedBranch({})} key="0" value='0'>Все филиалы</option>
                                {admin.branches.map(branch =>  
                                    <option onClick={() => admin.setSelectedBranch(branch)} key={branch.id} value={branch.id}>{branch.name}</option>
                                )}
                            </Form.Select>    
                        </Form>} */}
                        {store?.role === 'parent' && parent.students.length > 1 &&
                            <Form className="mx-2" key={'students111'}>
                                <Form.Select key={'wzdserwa'} onChange={(e:any) => changeStudent(e.target.value)} className="bg-transparent" bsPrefix="form-select navselect" value={parent?.studentData?.id} aria-label="Default select example">
                                    {parent.students.map((student, index) =>
                                        <option  /* onClick={() => parent.setStudentData(student)} */  key={index} value={student?.id}>{student?.name}</option>
                                    )}
                                </Form.Select>
                            </Form>}

                        {store?.roles.length > 1 &&
                            <Form className="mx-2" key={'roles'}>
                                <Form.Select onChange={(e) => changeRole(e.target.value)} className="bg-transparent" bsPrefix="form-select navselect" value={store.role} aria-label="Default select example">
                                    {roles.map(role =>
                                        <option /* onClick={() => changeRole(role)} */ key={role} value={role}>{getRoleName(role)}</option>
                                    )}
                                </Form.Select>
                            </Form>}
{/*                         {store.role === 'ADMIN' &&
                            <Button
                                bsPrefix="btn navbtn"
                                variant={"transparent"}
                                onClick={() => navigate(ADMIN_ROUTE, { replace: true })}
                                className="mx-2 d-flex float-end"
                            >
                                Админ панель
                            </Button>}
                        {store.role === 'student' &&
                            <Button
                                bsPrefix="btn navbtn"
                                variant={"transparent"}
                                onClick={() => navigate(STUDENT_ROUTE, { replace: true })}
                                className="mx-2 d-flex float-end"
                            >
                                Личный кабинет
                            </Button>}
                        {store.role === 'parent' &&
                            <Button
                                bsPrefix="btn navbtn"
                                variant={"transparent"}
                                onClick={() => navigate(PARENT_ROUTE, { replace: true })}
                                className="mx-2 d-flex float-end"
                            >
                                Личный кабинет
                            </Button>}
                        {store.role === 'teacher' &&
                            <Button
                                bsPrefix="btn navbtn"
                                variant={"transparent"}
                                onClick={() => navigate(TEACHER_ROUTE, { replace: true })}
                                className="mx-2 d-flex float-end"
                            >
                                Личный кабинет
                            </Button>} */}
                        <Button
                            bsPrefix="btn navbtn"
                            variant={"transparent"}
                            onClick={() => logOut()}
                            className="mx-2 d-flex float-end"
                        >
                            Выйти {store?.user?.name || store?.user?.login || null}
                        </Button>
                    </Nav>
                    :
                    <Nav className="ml-auto" style={{ color: 'white' }}>
                        <Button className="mx-2" bsPrefix="btn navbtn" variant={"transparent"} onClick={() => navigate(LOGIN_ROUTE)}>Авторизация</Button>
                    </Nav>
                }
            </Container>
        </Navbar>

    );
});

export default NavBar;