import { makeAutoObservable } from "mobx";
//import moment from "moment";
import dayjs from 'dayjs'
dayjs.locale('ru')

export default class TutorStore {
    constructor() {
        this._sideMenu = [
            { id: 3, name: "Расписание", link: "schedule" },
            { id: 13, name: "Посещаемость", link: "attendancestat" },
            { id: 16, name: "Настройки звезды", link: "refstars" },
            { id: 15, name: "Субьектная звезда", link: "subjectstar" },
            { id: 9, name: "Календарь событий", link: "eventcalendar" },
        ]
        this._selectedSideMenu = { id: 3, name: "Расписание" }

        this._subMenu = []
        this._selectedSubMenu = {}


        this._students = []
        this._selectedStudent = {}

        this._classes = []
        this._selectedClass = {}
        this._teacherStudents = []

        this._subjects = []
        this._selectedSubject = {}

        this._lessonsInterval = []
        this._lessonsSchedule = []

        this._starSubjects = []

//        this._statBegin = moment(moment.now() - 604800000)
        //this._statEnd = moment(moment.now())
        this._statBegin = dayjs().month(8).date(1)//dayjs().subtract(604800, 'second')
        this._statEnd = dayjs()

        makeAutoObservable(this)
    }

    setSideMenu(sidemenu) {
        this._sideMenu = sidemenu
    }
    setSelectedSideMenu(menuitem) {
        this._selectedSideMenu = menuitem
    }

    setSubMenu(submenu) {
        this._subMenu = submenu
    }
    setSelectedSubMenu(menuitem) {
        this._selectedSubMenu = menuitem
    }

    setStudents(students) {
        this._students = students
    }
    setSelectedStudent(student) {
        this._selectedStudent = student
    }

    setClasses(classes) {
        this._classes = classes
    }
    setSelectedClass(clas) {
        this._selectedClass = clas
    }
    setTeacherStudents(data) {
        this._teacherStudents = data
    }

    setSubjects(subjects) {
        this._subjects = subjects
    }
    setSelectedSubject(subject) {
        this._selectedSubject = subject
    }

    setLessonsInterval(lessonsInterval) {
        this._lessonsInterval = lessonsInterval
    }
    setLessonsSchedule(lessonsSchedule) {
        this._lessonsSchedule = lessonsSchedule
    }

    setStarSubjects(starSubjects) {
        this._starSubjects = starSubjects
    }

    setStatBegin(mom) {
        this._statBegin = mom
    } 
    setStatEnd(mom) {
        this._statEnd = mom
    } 

    get sideMenu() {
        return this._sideMenu
    }
    get selectedSideMenu() {
        return this._selectedSideMenu
    }

    get subMenu() {
        return this._subMenu
    }
    get selectedSubMenu() {
        return this._selectedSubMenu
    }

    get students() {
        return this._students
    }
    get selectedStudent() {
        return this._selectedStudent
    }

    get classes() {
        return this._classes
    }
    get selectedClass() {
        return this._selectedClass
    }
    get teacherStudents() {
        return this._teacherStudents
    }

    get subjects() {
        return this._subjects
    }
    get selectedSubject() {
        return this._selectedSubject
    }

    get lessonsInterval() {
        return this._lessonsInterval
    }

    get lessonsSchedule() {
        return this._lessonsSchedule
    }

    get starSubjects() {
        return this._starSubjects
    }

    get statBegin() {
        return this._statBegin
    }
    get statEnd() {
        return this._statEnd
    }
}