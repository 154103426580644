import React, { useContext, useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import '../navbar.css'
import { Container } from 'react-bootstrap';
import FilesUpload from './FilesUpload';
import { getFileList } from '../../http/teacherApi';

const SchcollBook = observer((props) => {
  const [fileList, setFileList] = useState([])
  const [aId, setAId] = useState('')

  useEffect(() => {
      setAId(Number(props.classID) * 1000 + Number(props.subject))
      loadData(Number(props.classID) * 1000 + Number(props.subject))
  }, [props])

  const loadData = async (aId) => {
    await getFileList('schoolbook', aId).then(data => setFileList(data))
  }

  return (
    <Container style={{'marginTop':'15px'}}>
      {Boolean(fileList.length > 0) &&
        <Container>
          Материалы:&nbsp;
          <FilesUpload
            fileList={fileList}
            disabled={true}
            action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'schoolbook'}&id=${aId}`}
          >
          </FilesUpload>
        </Container>
      }
    </Container>
  );
});

export default SchcollBook;