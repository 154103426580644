import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";
import axios from "axios";

import localForage from "localforage";

export const userLogin = async (login, password) => {
    //console.log(login, password);
    //  const {data} = await $host.get('api/user/login?login='+login+'&password='+password)
    const { data } = await $host.post('api/user/login', { login, password })
    //console.log(data);
    localForage.setItem('token', data.accessToken)
    return jwt_decode(data.accessToken)
}

export const check = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/refresh`, { withCredentials: true })
        localForage.setItem('token', response.data.accessToken);
        return jwt_decode(response.data.accessToken)
    } catch (error) {
        console.log("userApi: check error", error.response?.data?.message);
    }
}

export const logout = async () => {
    try {
        const { data } = await $host.get('api/user/logout')
        localForage.setItem('token', data.accessToken)
        return jwt_decode(data.accessToken)
    } catch (error) {
        console.log(error.response?.data?.message);
    }
}

export const getServerVersion = async () => {
    const ret = await localForage.getItem('server-version');
    return ret;
}

export const test = async (indata) => {
    console.log(indata);
    //  const {data} = await $host.get('api/user/login?login='+login+'&password='+password)
    const { data } = await $authHost.post('api/test', { indata })
    console.log(data);
    return data
}

/* export const userList = async (userRole) => {
    try {
        const {data} = await $authHost.get('api/user/users?role=' + userRole)
        return data
    } catch (error) {
        console.log(error.response?.data?.message);
    }
} */