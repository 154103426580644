import React, { useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Container, Form } from 'react-bootstrap';
import ModulesList from '../teacher/ModulesList';
import { Card } from 'antd';
//Расписание уроков
const AdminModulesTable = observer(() => {
    const { admin } = useContext(Context)
    const [classID, setClassID] = useState(admin.classes[0]?.id)
    const [subjectID, setSubjectID] = useState(admin.subjects[0]?.id)

    return (
        <Card>
            <Form className="mx-2">
                <Form.Select onChange={(e) => setClassID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={classID} aria-label="Default select example">
                    {admin.classes.map(clas =>
                        <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => setSubjectID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={subjectID} aria-label="Default select example">
                    {admin.subjects.map(subject =>
                        <option key={'subject' + subject.id} value={subject.id}>{subject.name}</option>
                    )}
                </Form.Select>
            </Form>
            <ModulesList classID={classID} subjectID={subjectID}/>
        </Card>
    )
});

export default AdminModulesTable;