import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { createEvent, deleteEvent, modyfyEvent, fetchEvents } from '../../http/adminApi';
import { Card, DatePicker, Popconfirm, Typography, message } from 'antd';
import { TimePicker } from 'antd';
//import moment from 'moment';
//import 'moment/locale/ru';
import dayjs from 'dayjs'
dayjs.locale('ru')

const { Paragraph } = Typography;

const AdminEventsTable = observer(() => {
    const { admin } = useContext(Context)

    const [events, setEvents] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const timeFormat = 'HH:mm';
    const format = "YYYY-MM-DD HH:mm"

    const loadData = async () => {
        const data = await fetchEvents()
        if (Array.isArray(data)) {
            setEvents(data)
            admin.setCalendarEvents(data)
        } else message.error("Получены не корректные данные от сервера")
    }

    const newEvent = async () => {
        let date = dayjs().format(format)
        let end = dayjs().add(3600000, 'seconds').format(format)
        const data = await createEvent(date, end)
        setEvents([{ name: '', date, end, id: data }, ...events])
        admin.setCalendarEvents([{ name: '', date, end, id: data }, ...admin.calendarEvents])
        return data
    }

    const changeName = async (name, id) => {
        let data = await modyfyEvent(null, name, id)
        setEvents(events.map(i => i.id === id ? { ...i, ['name']: name } : i))
        admin.setCalendarEvents(admin.calendarEvents.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    const changeDate = async (date, id) => {
        let data = await modyfyEvent(dayjs(date[0]).format(), null, id, dayjs(date[1]).format())
        console.log('Formatted Selected Time: ', date);
        setEvents(events.map(i => i.id === id ? { ...i, ['date']: date[0], ['end']: date[1] } : i))
        admin.setCalendarEvents(admin.calendarEvents.map(i => i.id === id ? { ...i, ['date']: date[0], ['end']: date[1] } : i))
    }

    const removeEvent = async (eventID) => {
        const data = await deleteEvent(eventID)
        if (data > 0) {
            message.success("Удаление успешно!")
            setEvents(events.filter(i => i.id !== eventID))
            admin.setCalendarEvents(admin.calendarEvents.filter(i => i.id !== eventID))
        } else {
            message.error("Удаление не удалось!")
        }
    }

/*     const onChange = (value, dateString) => {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    };
    const onOk = (value) => {
        console.log('onOk: ', value);
    }; */


    //  const disabledTime = { now: moment, type: ['start', 'end'], disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24] }
    const disabledTime = (value) => {
        return {
            type: ['start', 'end'],
            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24]
        }
    }

    return (
        <Card title='События календаря' extra={<Button onClick={() => newEvent()} className="float-end"><FaPlus className="d-flex justify-content-between" /></Button>}>
            <Table striped bordered hover>
{/*                 <thead>
                    <tr>
                        <th style={{ verticalAlign: 'bottom' }}>События календаря</th>
                        <th style={{ verticalAlign: 'bottom' }}></th>
                        <th style={{ verticalAlign: 'bottom' }}><Button onClick={() => newEvent()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
 */}                <tbody>
                    {events.map(event =>
                        <tr key={event.id}>
                            <td>
                                <DatePicker.RangePicker
                                    showTime={{ format: timeFormat, }}
                                    //onChange={onChange} 
                                    //onOk={onOk}
                                    minuteStep={5}
                                    format={format}
                                    //defaultValue={[dayjs(event.date, format), dayjs(event.end, format)]}
                                    defaultValue={[dayjs(event.date), dayjs(event.end)]}
                                    //value={[moment(gItem.begin, format), moment(gItem.end, format)]}
                                    onChange={(value, dateString) => changeDate(dateString, event.id)}
                                    disabledTime={disabledTime}
                                />
                            </td>
                            <td>
                                <Paragraph 
                                    editable={{
                                        tooltip: 'Название события',
                                        onChange: (value) => changeName(value, event.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {event.name}
                                </Paragraph>
                            </td>
                            <td>
                                <Popconfirm
                                    title={'Вы уверены что хотите удалить событие?'}
                                    onConfirm={() => removeEvent(event.id)}
                                    onCancel={() => message.warning('Удаление отменено')}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button className='float-end'>
                                        <FaMinus className="d-flex justify-content-between" />
                                    </Button>
                                </Popconfirm>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    );
});

export default AdminEventsTable;