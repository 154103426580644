import React, { useEffect, useContext, useState, Suspense, FC } from 'react';
//import Table from 'react-bootstrap/Table';
import { Col, Descriptions, Popconfirm, Row, Table, TableProps, Tooltip, message } from 'antd';
import { observer } from "mobx-react-lite";
import { Button, Container } from 'react-bootstrap';
import { Context } from '../../..';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from 'antd';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { deleteVisitInterval } from '../../../http/adminApi';
import AdminVisitModal from './AdminVisitModal';
import { fetchParentVisits } from '../../../http/adminApi';
import { $authHost } from '../../../http';
import { Await, defer, useAsyncValue, useLoaderData, Form, Navigate, Outlet, redirect } from 'react-router-dom';
import type { DescriptionsProps } from 'antd';


export async function action() {

    console.debug("visit action");
    return null;
}

export async function destroy({params}:any) {

    console.debug("destroy action");
    const data = await deleteVisitInterval(params.id);
    if (data > 0) {
        message.success("Отмена записи прошла успешно!")
    } else {
        message.error("Отменить запись не удалось!")
        //loadData()
    }
    return null;
}

export async function loader() {
    const items = getVisits();
    console.debug("visit loader", items);

    return defer({ items });
}

interface visitItem {
    id: number;

}

interface visitData {
    data: visitItem[];
}


const getVisits = async () => {
    const { data } = await $authHost.get('api/admin/parentvisits') as visitData;

    const items = data.map(visit => {
        return {
            ...visit,
            ['key']: visit.id,
            ['id']: <Form
                method="post"
                action={String(visit.id)+"/destroy"}
/*                 onSubmit={(event) => {
                    if (
                        !confirm(
                            "Please confirm you want to delete this record."
                        )
                    ) {
                        event.preventDefault();
                    }
                }} */
            >
                <Button type="submit" className='float-end'><FaMinus className="d-flex justify-content-between" /></Button>
            </Form>
        }
    });
    return items;
}

{/* <Popconfirm
                title={'Вы уверены что хотите удалить запись?'}
                onConfirm={() => { removeVisit(visit.id); }}
                onCancel={() => message.warning('Удаление отменено')}
                okText="Да"
                cancelText="Нет"
            >
                <Button className='float-end'>
                    <FaMinus className="d-flex justify-content-between" />
                </Button>
            </Popconfirm> */}


const removeVisit = async (intervalID: any) => {
    const data = await deleteVisitInterval(intervalID);
    if (data > 0) {
        message.success("Отмена записи прошла успешно!")
    } else {
        message.error("Отменить запись не удалось!")
        //loadData()
    }
}


const { RangePicker } = DatePicker;

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
}


const AdminParentVisit = observer((/* { studentID } */) => {
    const { items } = useLoaderData() as any;
    const { admin } = useContext(Context)
    const [modalVisible, setModalVisible] = useState<boolean>(false)

    const dateFormat = 'DD.MM.YYYY';
    const dateFormatForDB = 'YYYY-MM-DD'

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Дата',
            dataIndex: 'date',
            fixed: 'left',
        },
        {
            title: 'Класс',
            dataIndex: 'class',
        },
        //  {
        //      title: 'Студент',
        //      dataIndex: 'student',
        //  },
        {
            title: 'Родитель',
            dataIndex: 'parent',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
        },
        {
            //title: <Button onClick={() => setModalVisible(true)} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button>,
            title: <Form method="get" action='new'><Button type="submit" className='float-end'><FaPlus className="d-flex justify-content-between" /></Button> </Form>,
            dataIndex: 'id',
            fixed: 'right',
            width: 60,
        },
    ]

    const visitList1 = [{ "date": "12.10.2023 с 8:00 до 14:00", "class": "5 Класс", "student": "Петрова Анастасия", "parent": "Петров Илья Андреевич", "id": 3, "phone": "322412451" },
    { "date": "16.10.2023 с 8:00 до 14:00", "class": "3 Класс", "student": "Иванов Илья", "parent": "Иванова Мария Павловна", "id": 5, "phone": "2222412451" }
    ]

    const [visitList, setVisitList] = useState([])

    //    const [begin, setBegin] = useState(moment(moment.now() - 604800000))
    //    const [end, setEnd] = useState(moment(moment.now()))

    useEffect(() => {
        //loader()
    }, []);

    /*     const loader = async () => {
            let data = await fetchParentVisits()
            console.debug("parentVisit:", data);
            setVisitList(data.map(visit => {
                return {
                    ...visit,
                    ['key']: visit.id,
                    ['id']: <Popconfirm
                        title={'Вы уверены что хотите удалить запись?'}
                        onConfirm={() => removeVisit(visit.id)}
                        onCancel={() => message.warning('Удаление отменено')}
                        okText="Да"
                        cancelText="Нет"
                    >
                        <Button className='float-end'>
                            <FaMinus className="d-flex justify-content-between" />
                        </Button>
                    </Popconfirm>
                }
            }))
    
            return null;
        } */

    return (
        <Container>
            {/*    <RangePicker
                //value={[begin, end]}
                value={[student.attBegin, student.attEnd]}
                format={[dateFormat, dateFormat]}
                allowClear={false}
                onChange={(value) => changePeriod(value)}
            // disabledDate={disabledDate}
            // onCalendarChange={(val) => setDates(val)}
            // onChange={(val) => setValue(val)}
            // onOpenChange={onOpenChange} 
            /> */}


            <Suspense fallback={<>Загрузка...</>}>
                <Await resolve={items}>
                    <MyTable columns={columns} items={items} />
                    {/*                     {
                        (items: any) => (
                            items.map((item: any) => {
                                //v.push({...item})
                                return (
                                    <Row key={item.id}>
                                        <Col span={6}>{item.date}</Col>
                                        <Col span={6}>{item.class}</Col>
                                        <Col span={6}>{item.parent}</Col>
                                        <Col span={3}>{item.id}</Col>
                                    </Row>
                                )
                            }
                            ))
                    } */}
                    {/* <Table
                        style={{ marginTop: '15px' }}
                        columns={columns}
                        dataSource={items}
                        //pagination={false}
                        pagination={{
                            pageSize: 25,
                        }}
                        scroll={{
                            x: 500,
                        }}
                    /> */}



                </Await>
            </Suspense>
            {/* <AdminVisitModal show={modalVisible} onHide={() => {  loader();  setModalVisible(false) }} /> */}
            <Outlet />
        </Container >
    );
});


const MyTable = (props: any) => {

    const items = useAsyncValue() as any;

    return (
        <Table
            style={{ marginTop: '15px' }}
            columns={props.columns}
            dataSource={items}
            //pagination={false}
            pagination={{
                pageSize: 25,
            }}
            scroll={{
                x: 500,
            }}
        />
    )
}

export default AdminParentVisit;