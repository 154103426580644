import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Form } from 'react-bootstrap';
import { fetchThemesByWorkType, fetchModules, fetchThemes, getCurrentTheme } from '../../../http/teacherApi';
import { Context } from '../../..';
import { fetchDescriptionsByThemeID } from '../../../http/studentApi';

const TeacherSelectTheme = observer(({selectTheme, selectedThemeID, selectedWorkTypeID, classID, subjectID}) => {
    const { teacher } = useContext(Context)
    const [themes, setThemes] = useState(teacher.teacherThemes)

    useEffect(() => {
        loadData()
    }, [selectedWorkTypeID])

    const loadData = async () => {
        if (selectedWorkTypeID) {
            await fetchThemesByWorkType(selectedWorkTypeID, classID, subjectID).then(data => {
                teacher.setTeacherThemes(data)
                teacher.setSelectedTheme(data[0])
                setThemes(data)
                selectTheme(data[0]?.id)
            })
        }
        //   teacher.setTeacherThemes(themes)
        //   teacher.setSelectedTheme(themes[0])
    }

    return (
        <Form className="mx-2">
            <Form.Select onChange={(e) => selectTheme(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={selectedThemeID} aria-label="Default select example">
                {themes.map(theme =>
                    <option key={theme.id} value={theme.id}>{theme.name}</option>
                )}
            </Form.Select>
        </Form>
    );
});

export default TeacherSelectTheme;