import { Card, Col, Row } from "antd"
import { Form, redirect, useLoaderData, useSubmit } from "react-router-dom"
import Select from 'react-select'
import { fetchTacts} from "../../../http/teacherApi";


export async function selectTactLoader({ params }: any) {
    const rawTacts = await fetchTacts();
    const tacts = rawTacts.map((item: any) => { return ({ value: item.id, label: item.name }) })
    //console.log("silabusLoader", params);
    return { tacts };
}

export async function selectTactAction({ params, request }: any) {
    const formData = await request.formData();
    const {query} = Object.fromEntries(formData);
    //if (query) console.log("modules2Action", JSON.parse(query));
    
    const {tact} = Object.fromEntries(formData);
    if (tact) return redirect(`${tact}`);
  
    /*   console.log("modules actions: ", params, classsubject); */
      return redirect('.')
  }
  
  

interface SelectOptions {
    value: number
    label: string
}

export const SelectTact = () => {
    const submit = useSubmit();
    const { tacts } = useLoaderData() as { tacts: SelectOptions[] };


    const onSelect1 = (e: any) => {
        //console.log(e);

        let formData = new FormData();
        formData.append("tact", e?.value);
        //formData.append("classsubjectname", e?.label);
        submit(formData, { method: "POST" });

        //teacher.setSelectedClasSubjectSyllabus(e.target.value)
    }

    return (
            <Row gutter={[8, 8]}>
                <Col span={8}>
                    <Form method="POST">
                        <Select
                            //key={'csdfsdf'}
                            onChange={onSelect1}
                            name="tact" /* style={{ width: '100%' }}  */
                            placeholder='Выберите такт'
                            //value={classesSubjects.filter((item: any) => item.value === selectedClassSubject)}
                            options={tacts}
                        />
                    </Form>
                </Col>
            </Row>

    )
}