import React, { useEffect, useContext, useState } from 'react';
//import Table from 'react-bootstrap/Table';
import { Table, Tooltip, Tag, Card } from 'antd';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { Context } from '../../..';
import moment from 'moment';
import 'moment/locale/ru';
import { DatePicker } from 'antd';
import { fetchStudentStat } from '../../../http/studentApi';
import ListGroup from 'react-bootstrap/ListGroup';
import '../../navbar.css'

const { RangePicker } = DatePicker;

const StudentStudentStat = observer((params/* { studentID, mode } */) => {

    const { store } = useContext(Context)
    const studentID = (params?.studentID) ? params?.studentID: store?.user?.id

    const dateFormat = 'DD.MM.YYYY';
    const dateFormatForDB = 'YYYY-MM-DD'


    const firstColumn = {
        title: '',
        title1: 'Предметы',
        dataIndex: 'subjectName',
        fixed: 'left',
        width: 90,
    }

    const firstColumnL2 = {
        title: 'Модули',
        dataIndex: 'subjectName',
        fixed: 'left',
        width: 90,
    }

    /*     const data = [{ "subjectName": "Математика", "ID1": 30, "ID2": 30, "ID3": 30, "ID4": "■", "ID5": "■", "ID6": "■", "ID7": "■" }
            , { "subjectName": "Русский язык", "ID1": 30, "ID2": 30, "ID3": 30, "ID4": "■", "ID5": "■", "ID6": "■", "ID7": "■", "ID8": "■", "ID9": "■", "ID10": "■" }
            , { "subjectName": "Литература", "ID1": 30, "ID2": 30, "ID3": 30, "ID4": "■", "ID5": "■", "ID6": "■", "ID7": "■", "ID8": "■", "ID9": "■", "ID10": "■" }
            , { "subjectName": "Английский язык", "ID1": 30, "ID2": 30, "ID3": 30, "ID4": "■", "ID5": "■", "ID6": "■" }]
    
        const [subjectsList, setSubjectsList] = useState([]) */

    const [statData, setStatData] = useState([])
    const [columns, setColumns] = useState([firstColumn])

    const [links, setLinks] = useState([])
    const [currentLink, setCurrentLink] = useState({})

    //    const [begin, setBegin] = useState(moment(moment.now() - 604800000))
    //    const [end, setEnd] = useState(moment(moment.now()))

/*     useEffect(() => {
        loadData(null)
    }, []) */

    useEffect(() => {
        if (currentLink) {
            loadData(currentLink)
        }
    }, [studentID])

    /*     useEffect(() => {
            
        }, [links]) */

    const clickLink = async (link) => {
        setCurrentLink(link)
        setLinks([...links, link])
        loadData(link)
    }

    const loadData = async (link) => {
        if (link) {
            setCurrentLink(link)
           // console.log("LLLL1", [...links, link], links);
            //setLinks([...links, link])
           // console.log("LLLL2", [...links, link], links);
        }
        /* if (link && !links.includes(link)) {
            setCurrentLink(link)
            console.log("LLLL1", [...links, link], links);
            setLinks([...links, link])
            console.log("LLLL2", [...links, link], links);
        } */
        if (!studentID) return null;

        const data = await fetchStudentStat(studentID, null, null, link)
        if (!data) return null;
        const data1 = {
            "columns": [{ "id": 'ID1', "name": "Модуль 1" }, { "id": 'ID2', "name": "Модуль 2" },
            { "id": 'ID3', "name": "Модуль 3" }, { "id": 'ID4', "name": "Модуль 4" },
            { "id": 'ID5', "name": "Модуль 5" }, { "id": 'ID6', "name": "Модуль 6" },
            { "id": 'ID7', "name": "Модуль 7" }, { "id": 'ID8', "name": "Модуль 8" },
            { "id": 'ID9', "name": "Модуль 9" }, { "id": 'ID10', "name": "Модуль 10" }],
            "data": [{ "subjectName": "Математика", "ID1": { "score": 30, "description": "Описание модуля М1", "link": "3255" }, "ID2": { "score": 30, "description": "Описание модуля М2" }, "ID3": { "score": 30, "description": "Описание модуля М3" }, "ID4": { "score": "■", "description": "Описание модуля М4" }, "ID5": { "score": "■", "description": "Описание модуля М5" }, "ID6": { "score": "■", "description": "Описание модуля М6" }, "ID7": { "score": "■", "description": "Описание модуля М7" } }
                , { "subjectName": "Русский язык", "ID1": { "score": 30, "description": "Описание модуля Р1" }, "ID2": { "score": 30, "description": "Описание модуля Р2" }, "ID3": { "score": 30, "description": "Описание модуля Р3" }, "ID4": { "score": "■", "description": "Описание модуля Р4" }, "ID5": { "score": "■", "description": "Описание модуля Р5" }, "ID6": { "score": "■", "description": "Описание модуля Р6" }, "ID7": { "score": "■", "description": "Описание модуля Р7" }, "ID8": { "score": "■", "description": "Описание модуля Р8" }, "ID9": { "score": "■", "description": "Описание модуля Р9" }, "ID10": { "score": "■", "description": "Описание модуля Р10" } }
                , { "subjectName": "Литература", "ID1": { "score": 30, "description": "Описание модуля Л1" }, "ID2": { "score": 30, "description": "Описание модуля Л2" }, "ID3": { "score": 30, "description": "Описание модуля Л3" }, "ID4": { "score": "■", "description": "Описание модуля Л4" }, "ID5": { "score": "■", "description": "Описание модуля Л5" }, "ID6": { "score": "■", "description": "Описание модуля Л6" }, "ID7": { "score": "■", "description": "Описание модуля Л7" }, "ID8": { "score": "■", "description": "Описание модуля Л8" }, "ID9": { "score": "■", "description": "Описание модуля Л9" }, "ID10": { "score": "■", "description": "Описание модуля Л10" } }
                , { "subjectName": "Английский язык", "ID1": { "score": 30, "description": "Описание модуля А1" }, "ID2": { "score": 30, "description": "Описание модуля А2" }, "ID3": { "score": 30, "description": "Описание модуля А3" }, "ID4": { "score": "■", "description": "Описание модуля А4" }, "ID5": { "score": "■", "description": "Описание модуля А5" }, "ID6": { "score": "■", "description": "Описание модуля А6" } }
            ]
        }

        let colData = [firstColumn]
        data.columns.map((col) => {
            colData.push({
                //title: <Tooltip title={col.description} color="grey"><div style={{writingMode:'sideways-lr'}}>{col.name}</div></Tooltip>,
                title: <div style={{ writingMode: 'sideways-lr' }}>{col.name}</div>,
                dataIndex: col.id,
                render: (score) => (
                    <span>
                        <Tooltip title={score?.description} color="grey">
                            {score?.link ?
                                <a onClick={() => {
                                    setCurrentLink(score.link)
                                    if (!links.includes(score.link)) {
                                        setLinks([...links, score.link])
                                    }
                                    loadData(score.link)
                                }}>
                                    <Tag color={'green'} key={score}>
                                        {score?.score}
                                    </Tag>
                                </a>
                                :
                                <Tag color={'green'} key={score}>
                                    {score?.score}
                                </Tag>
                            }
                        </Tooltip>
                    </span>
                ),
                width: 30,
            })
        })
        setColumns(colData)

        setStatData(data.data)
    }

    return (
        <Card>
            <ListGroup style={{ marginTop: '2px', marginBottom: '15px' }}>
                {links.length > 0 &&
                    <ListGroup.Item bsPrefix="list-group-item listgroupitemadm"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setCurrentLink({})
                            setLinks(Array())
                            loadData(null)
                        }}
                        key='home'
                    >
                        Успеваемость
                    </ListGroup.Item>
                }
                {links.map(link =>
                    <ListGroup.Item bsPrefix="list-group-item listgroupitemadm"
                        style={{ cursor: 'pointer' }}
                        active={link === currentLink}
                        onClick={() => {
                            if (link !== currentLink) {
                                setCurrentLink(link)
                                //setLinks([...links, link])
                                loadData(link)
                            }
                        }}
                        key={link}
                    >
                        {link}
                    </ListGroup.Item>
                )}
            </ListGroup>
            <Table
                style={{}}
                columns={columns}
                dataSource={statData}
                //dataSource={subjectsList}
                pagination={false}
                /* pagination={{
                    pageSize: 50,
                }} */
                scroll={{
                    x: 500,
                }}
            />
        </Card>
    );
});

export default StudentStudentStat;