import { makeAutoObservable } from "mobx";
//import moment from "moment";
import dayjs from 'dayjs'
dayjs.locale('ru')

export default class StudentStore {
    constructor() {
        this._sideMenu = [
            { id: 4, name: "Прогресс" },
            { id: 1, name: "Расписание" },
            { id: 7, name: "Посещаемость" },
            { id: 8, name: "Успеваемость" },
          /*   { id: 2, name: "Силлабусы-Маршруты(модалки)" }, */
            { id: 6, name: "Маршруты" },
            { id: 5, name: "Планирование" },
          //  { id: 3, name: "Настройки" },
            { id: 9, name: "Календарь событий" },
        ]
        this._selectedSideMenu = { id: 4, name: "Прогресс" }

        this._subMenu = [
            { id: 61, name: "Маршрут-описание" },
            { id: 62, name: "Силабусы самостоятельная работа" },
            { id: 41, name: "Звезда 2" },
            { id: 42, name: "Звезда 3" },
        ]
        this._selectedSubMenu = {id:null}

        this._studentData = {}
        this._studentClass = 0

        /*         this._studentSchedule = [
                    {id: 1, intID: 1, day: 1, subjectID: 1, subjectName: "Математика"},                     
                ] */
        this._studentSchedule = []

        this._studentSubjects = []
        this._selectedSubject = 0

        this._studentModules = []
        this._selectedModule = {}

        this._educationRoute = []

        this._selectedTheme = {}
        this._selectedDescription = {}

        this._attBegin = dayjs().month(8).date(1)//dayjs().subtract(604800, 'second')
        this._attEnd = dayjs()

        this._calendarPlans = []

        makeAutoObservable(this)
    }

    setSideMenu(sidemenu) {
        this._sideMenu = sidemenu
    }
    setSelectedSideMenu(menuitem) {
        this._selectedSideMenu = menuitem
    }
    setSubMenu(submenu) {
        this._subMenu = submenu
    }
    setSelectedSubMenu(menuitem) {
        this._selectedSubMenu = menuitem
    }

    setStudentData(studentData) {
        this._studentData = studentData
    }
    setStudentClass(studentClass) {
        this._studentClass = studentClass
    }
    setStudentSchedule(studentSchedule) {
        this._studentSchedule = studentSchedule
    }
    setStudentSubjects(studentSubjects) {
        this._studentSubjects = studentSubjects
    }
    setSelectedSubject(subject) {
        this._selectedSubject = subject
    }
    setStudentModules(studentModules) {
        this._studentModules = studentModules
    }
    setSelectedModule(selectedModule) {
        this._selectedModule = selectedModule
    }

    setEducationRoute(educationRoute) {
        this._educationRoute = educationRoute
    }

    setSelectedTheme(theme) {
        this._selectedTheme = theme
    }
    setSelectedDescription(desc) {
        this._selectedDescription = desc
    }

    setAttBegin(mom) {
        this._attBegin = mom
    }
    setAttEnd(mom) {
        this._attEnd = mom
    }

    setCalendarPlans(plans) {
        this._calendarPlans = plans
    }

    get sideMenu() {
        return this._sideMenu
    }
    get selectedSideMenu() {
        return this._selectedSideMenu
    }
    get subMenu() {
        return this._subMenu
    }
    get selectedSubMenu() {
        return this._selectedSubMenu
    }

    get studentData() {
        return this._studentData
    }
    get studentClass() {
        return this._studentClass
    }
    get studentSchedule() {
        return this._studentSchedule
    }
    get studentSubjects() {
        return this._studentSubjects
    }
    get selectedSubject() {
        return this._selectedSubject
    }
    get studentModules() {
        return this._studentModules
    }
    get selectedModule() {
        return this._selectedModule
    }

    get educationRoute() {
        return this._educationRoute
    }

    get selectedTheme() {
        return this._selectedTheme
    }
    get selectedDescription() {
        return this._selectedDescription
    }
    
    get attBegin() {
        return this._attBegin
    }
    get attEnd() {
        return this._attEnd
    }

    get calendarPlans() {
        return this._calendarPlans
    }

}