import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container, Form } from 'react-bootstrap';
import { fetchSchedule, fetchScheduleGrid, fetchScheduleGridItems, fetchScheduleGrids, modyfySchedule } from '../../../http/adminApi';
import { EMPTY_SCHEDULE } from '../../../utils/consts';
import { Card, Select } from 'antd';

//Расписание уроков
const AdminLessons = observer(({ editable = true }) => {
    const { admin } = useContext(Context)
    const [count, setCount] = useState(0) //Костыль для отрисовки, не убирать.

    const [lessonsIntervals, setLessonsIntervals] = useState([])
    const [lessonsSchedule, setLessonsSchedule] = useState([])
    const [grids, setGrids] = useState([])
    const [selectedGrid, setSelectedGrid] = useState({})
    const [selectedClassID, setSelectedClassID] = useState(0)

    useEffect(() => {
        setSelectedClassID(admin.classes[0]?.id)
        setSelectedGrid(admin.classes[0]?.scheduleGrid)
        loadData()
        loadSchedulle(admin.classes[0]?.id, admin.classes[0]?.scheduleGrid)
        /*         if (admin.selectedClass?.id) {
                    classChange(admin.selectedClass?.id)
                } else {
                    classChange(admin.classes[0]?.id)   
                } */
    }, [])

    const loadData = async () => {
        const data = await fetchScheduleGrids()
        setGrids(data)
    }

    const changeCell = async (id, subjectID, day, intID, subjectName, classID) => {
        await modyfySchedule(id, subjectID, day, intID, subjectName, classID)
        loadSchedulle(classID, selectedGrid)
        //classChange(classID)
        //  admin.setLessonsSchedule(admin.lessonsSchedule.map(i => i.id === id ? { ...i, ['subjectName']: subjectName } : i)))
        // const data = modyfyLessonsSchedule(id, subjectID, day, intID)
        // ЗАГРУЗКА
        //admin.setLessonsSchedule(admin.lessonsSchedule.map(i => i.id === id ? { ...i, ['subjectName']: subjectName } : i))
    }

    const days = [
        { day: 1 }, { day: 2 }, { day: 3 }, { day: 4 }, { day: 5 },
    ]

    function getLessonsScheduleID(day, intID) {
        //console.log("input",day,intID,"result", lessonsSchedule.filter(lessonsSchedule => (lessonsSchedule.day == day && lessonsSchedule.intID == intID))[0]?.subjectID);
        //return admin.lessonsSchedule.filter(lessonsSchedule => (lessonsSchedule.day == day && lessonsSchedule.intID == intID))[0]?.subjectID
        return lessonsSchedule.filter(lessonsSchedule => (lessonsSchedule.day == day && lessonsSchedule.intID == intID))[0]?.subjectID
    }

    function getLessonsScheduleName(day, intID) {
        let id = getLessonsScheduleID(day, intID)
        return admin.subjects.find(i => i.id == id)?.name || ''
    }

    const classChange = async (clas) => {
        if (clas) {
            let shGrid = admin.classes.find(i => i.id == clas)?.scheduleGrid
            setSelectedGrid(shGrid)
            setSelectedClassID(clas)
            setCount(count + 1)
            let dataSh
            if (shGrid?.id) {
                dataSh = await fetchScheduleGridItems(shGrid.id)
            } else {
                dataSh = await fetchScheduleGrid(clas)
            }
            setLessonsIntervals(dataSh)

            if (shGrid) {
                const data = await fetchSchedule(clas, shGrid.id)
                setLessonsSchedule(data)
            }
        }
    }

    const loadSchedulle = async (clas, shGrid) => {
        if (clas) {
            setSelectedClassID(clas)
            setCount(count + 1)
            let dataSh
            if (shGrid?.id) {
                dataSh = await fetchScheduleGridItems(shGrid.id)
            } else {
                dataSh = await fetchScheduleGrid(clas)
            }
            setLessonsIntervals(dataSh)

            const data = await fetchSchedule(clas, shGrid.id)
            setLessonsSchedule(data)
        }
    }

    const gridChange = async (grid) => {
        if (grid) {
            //console.log("SSS", grid, selectedGrid, selectedClassID);
            let shGrid = grids.find(i => i.id == grid)
            setSelectedGrid(shGrid)
            if (selectedClassID) {
                loadSchedulle(selectedClassID, shGrid)
            } else {
                loadSchedulle(admin.classes[0]?.id, shGrid)
            }
        }
    }

    return (
        <Card>
            <Form className="mx-2">
                {/* Класс */}
                {/*                 <Select onChange={(e) => classChange(e.target.value)} defaultValue={selectedClassID} options={admin.classes.map(clas =>
                        { return {value: clas.id, label: clas.name}}
                    )}></Select> */}
                <Form.Select onChange={(e) => classChange(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={selectedClassID} aria-label="" style={{ width: '40%' }}>
                    {admin.classes.map(clas =>
                        <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                    )}
                </Form.Select>
            </Form>
            {Boolean(editable && selectedGrid) &&
                <Form className="mx-2">
                    {/* Сетка */}
                    <Form.Select onChange={(e) => gridChange(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={selectedGrid?.id} aria-label="" style={{ width: '40%' }}>
                        {grids.map(grid =>
                            <option key={'grid' + grid.id} value={grid.id}>{grid.name}</option>
                        )}
                    </Form.Select>
                </Form>
            }
            {!selectedGrid && <div style={{ marginTop: '15px' }}><b style={{ color: 'red', marginTop: '15px' }}>Внимание! у выбранного класса не указана сетка расписания!</b></div>}
            <Table striped bordered hover style={{ marginTop: '15px' }}>
                <thead>
                    <tr>
                        <th>Время</th>
                        <th>Понедельник</th>
                        <th>Вторник</th>
                        <th>Среда</th>
                        <th>Четверг</th>
                        <th>Пятница</th>
                    </tr>
                </thead>
                <tbody>
                    {lessonsIntervals.map(lessonsInterval =>
                        <tr key={'tr' + lessonsInterval.id}>
                            <td>
                                {lessonsInterval.name ? lessonsInterval.name : lessonsInterval.begin + " - " + lessonsInterval.end}
                            </td>
                            {days.map(day =>
                                <td key={'td' + day.day + lessonsInterval.id}>
                                    {editable ?
                                        <Form key={count} className="mx-2">
                                            <Form.Select onChange={(e) => changeCell(getLessonsScheduleID(day.day, lessonsInterval.id), e.target.value, day.day, lessonsInterval.id, e.target.value, selectedClassID)} className="bg-transparent" bsPrefix="form-select" style={{ fontSize: 11 }} value={getLessonsScheduleID(day.day, lessonsInterval.id) || EMPTY_SCHEDULE} aria-label="Default select example">
                                                {admin.subjects.map(subject =>
                                                    <option key={'cell' + day.day + subject.id + lessonsInterval.id} value={subject.id}>{subject.name}</option>
                                                )}
                                            </Form.Select>
                                        </Form>
                                        :
                                        getLessonsScheduleName(day.day, lessonsInterval.id)
                                    }
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>
    );
});

export default AdminLessons;