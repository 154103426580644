import React, { useContext } from 'react';
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx"
import { Context } from '../..';
import '../navbar.css'
import { Breadcrumb } from 'antd';
//import 'antd/dist/antd.css';

const AdminBarSub = observer(() => {
    const { teacher } = useContext(Context)
    const { admin } = useContext(Context)


    function click(subMenuItem) {
        runInAction(() => {
            admin.setSelectedSubMenu({ id: subMenuItem })
        })
    }

    const style = {
        cursor: 'pointer',
        backgroundColor: '#ffd110',
        paddingBottom: '5px',
        paddingLeft: '5px',
        paddingTop: '5px',
        paddingRight: '5px'
    };

    return (
        <Breadcrumb>
            {Boolean(admin.selectedSubMenu?.id == '42' | admin.selectedSubMenu?.id == '43') &&
                <Breadcrumb.Item onClick={() => click(41)} style={style}>
                    Ученики {admin.selectedClass.name + ' / ' + admin.selectedSubject.name}
                </Breadcrumb.Item>
            }
            {admin.selectedSubMenu?.id == '43' &&
                <Breadcrumb.Item onClick={() => click(42)} style={style}>
                    Ученик {teacher.selectedStudent.name}
                </Breadcrumb.Item>
            }
            {Boolean(admin.selectedSubMenu?.id == '142' | admin.selectedSubMenu?.id == '143') &&
                <Breadcrumb.Item onClick={() => click(141)} style={style}>
                    Темы для модуля #{teacher.selectedModule.index} ({teacher.selectedModule.name})
                </Breadcrumb.Item>
            }
            {admin.selectedSubMenu?.id == '143' &&
                <Breadcrumb.Item onClick={() => click(142)} style={style}>
                    Маршруты для темы #{teacher.selectedTheme.index} ({teacher.selectedTheme.name})
                </Breadcrumb.Item>
            }
        </Breadcrumb>


    );
});

export default AdminBarSub;