import Modal from "react-bootstrap/Modal";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Button, Container } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { createDescription, deleteDescription, fetchDescriptions, modyfyDescription, oneRoute } from '../../../http/teacherApi';
import Editor from "../../utilites/quill/EditorBlur";
import { message, Popconfirm, Typography } from 'antd';
import FilesUpload from "../../utilites/FilesUpload";
import { Context } from '../../..';
import HTMLViewer from "../../utilites/HTMLViewer";

const { Paragraph } = Typography;

const DescriptionsTable = observer(() => {
  const { teacher } = useContext(Context)

  useEffect(() => {
    loadData()
    //  oneRoute(routeID).then(data => setRoute(data))
  }, [teacher.selectedRoute])

  const loadData = async () => {
    const data = await fetchDescriptions(teacher.selectedRoute.id)
    teacher.setTeacherDescriptions(data)
  }

  const addDescription = async () => {
    const id = await createDescription(teacher.selectedRoute.id)
    teacher.setTeacherDescriptions([...teacher.teacherDescriptions, { id: id }])
  }

  const changeIndex = (index, id) => {
    changeDescription(index, null, id)
    teacher.setTeacherDescriptions(teacher.teacherDescriptions.map(i => i.id === id ? { ...i, ['index']: index } : i))
  }

  function changeText(text, id) {
    changeDescription(null, text, id)
    teacher.setTeacherDescriptions(teacher.teacherDescriptions.map(i => i.id === id ? { ...i, ['text']: text } : i))
  }

  const changeDescription = async (index, text, id) => {
    const data = await modyfyDescription(id, index, text)
  }

  const delDescription = async (desc) => {
    //console.log("MOD", module);
    const data = await deleteDescription(desc.id)
    if (data > 0) {
      message.success("Удаление успешно!")
      loadData()
    } else {
      message.error("Удаление не удалось!")
    }
  }

  return (
    <Container>
      <div>
        Задания для темы урока #{teacher.selectedRoute.index} ({teacher.selectedRoute.name})
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Задания урока</th>
            <th><Button onClick={() => addDescription()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
          </tr>
        </thead>
        <tbody>
          {teacher.teacherDescriptions.map(description =>
            <tr key={description.id}>
              <td>
                {<Paragraph
                  editable={{
                    tooltip: 'Номер',
                    onChange: (value) => changeIndex(value, description.id),
                    triggerType: ['icon', 'text'],
                  }}
                >
                  {description.index}
                </Paragraph>}
                {/*         <FormControl
                    placeholder='Номер'
                    value={description.index}
                    onChange={(e) => changeIndex(e.target.value, description.id)}
                  /> */}
              </td>
              <td>
                {/*                   <RichTextEditor 
                    value={description.text}
                    dataToSave={description.id}
                    funcToSave={changeText}
                    placeholder="Введите ваш текст"
                  ></RichTextEditor> */}
                {/*                   <Editor></Editor> */}
                {/*                   <LinkEditor
                    placeholder="Введите ваш текст"
                  ></LinkEditor> */}
                {/*                   <MediaEditor
                    placeholder="Введите ваш текст"
                  ></MediaEditor> */}
                {/*                   <FormControl
                    placeholder='Введите описание'
                    value={description.text}
                    onChange={(e) => setDescriptions(descriptions.map(i => i.id === description.id ? { ...i, ['text']: e.target.value } : i))}
                    onBlur={(e) => changeText(e.target.value, description.id)}
                    type="text"
                  /> */}
                {description.editable != false ?
                  <div>
                    <Editor
                      data={description.text}
                      /* idToSave={description.id} */
                      funcToSave={(val) => changeText(val, description.id)}
                      placeholder='Введите описание'
                    ></Editor>
                    <FilesUpload
                      fileList={description.fileList}
                      action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'descriptions'}&id=${description.id}`}
                    >
                    </FilesUpload>
                  </div>
                  :
                  <div>
                    <HTMLViewer
                      //value={convertToHTML(convertFromRaw(JSON.parse(description.text)))}
                      value={description.text}
                    ></HTMLViewer>
                    <FilesUpload
                      disabled={true}
                      fileList={description.fileList}
                    >
                    </FilesUpload>
                  </div>
                }

                {/*   { <HTMLViewer
                    value={convertToHTML(convertFromRaw(JSON.parse(description.text)))}
                    value={description.text}
                  ></HTMLViewer>} */}
                {/* <Button onClick={setEditorVisible(true)}>Edit</Button>  */}
                {/*  <Button onClick={clickEditor(description.text, description.id)}>Edit</Button>  */}
              </td>
              {description.editable != false &&
                <td>
                  <Popconfirm
                    title={'Вы уверены что хотите удалить номер ' + description.index + '?'}
                    onConfirm={() => delDescription(description)}
                    onCancel={() => message.warning('Удаление отменено')}
                    okText="Да"
                    cancelText="Нет"
                  >
                    <Button>
                      <FaMinus className="d-flex justify-content-between" />
                    </Button>
                  </Popconfirm>
                </td>
              }
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
});

export default DescriptionsTable;