import React, { useContext, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { createScheduleGridItem, deleteScheduleGridItem, fetchScheduleGridItems, modyfyScheduleGridItem } from '../../../http/adminApi';
import { Popconfirm, Typography, message } from 'antd';
import { TimePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';

const { Paragraph } = Typography;

const AdminScheduleGridItems = observer(() => {
    const { admin } = useContext(Context)

    useEffect(() => {
        loadData()
    }, [admin.selectedScheduleGrid])

    const loadData = async () => {
        if (admin.selectedScheduleGrid.id) {
            const data = await fetchScheduleGridItems(admin.selectedScheduleGrid.id)
            // {id: data.id, interval:[moment(data.begin, format), moment(data.end, format)]}
            // admin.setScheduleGridItems(data.map(row => {['id']: row.id, ['period']: [moment(row.begin, format), moment(data.row, format)]}))
            admin.setScheduleGridItems(data)
        }
    }

    const onClickNewGridItem = async () => {
        const data = await createScheduleGridItem(admin.selectedScheduleGrid.id)
        admin.setScheduleGridItems([...admin.scheduleGridItems, data])
    }

    const changeGridItem = async (gridItemID, period) => {
        let begin = period[0].format(format);
        let end = period[1].format(format);
        // console.log("MMM", period, begin, end);
        const data = await modyfyScheduleGridItem(gridItemID, begin, end)
        admin.setScheduleGridItems(admin.scheduleGridItems.map(i => i.id === gridItemID ? { ...i, ['begin']: begin, ['end']: end } : i))
    }

    async function deleteGridItem (gridItemID) {
        const data = await deleteScheduleGridItem(gridItemID)
        if (data > 0) {
            message.success("Удаление успешно!")
            admin.setScheduleGridItems(admin.scheduleGridItems.filter(i => i.id !== gridItemID))
        } else {
            message.error("Удаление не удалось!")
        }
    }

    const format = 'HH:mm';
    //  const disabledTime = { now: moment, type: ['start', 'end'], disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24] }
    const disabledTime = (value) => {
        return {
            type: ['start', 'end'],
            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24]
        }
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th style={{ verticalAlign: 'bottom' }}>Интервалы сетки расписания "{admin.selectedScheduleGrid.name}"<Button onClick={() => onClickNewGridItem()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {admin.scheduleGridItems.map(gItem =>
                        <tr key={gItem.id}>
                            <td>
                                <TimePicker.RangePicker
                                    minuteStep={5}
                                    format={format}
                                    defaultValue={[moment(gItem.begin, format), moment(gItem.end, format)]}
                                    //value={[moment(gItem.begin, format), moment(gItem.end, format)]}
                                    onChange={(value) => changeGridItem(gItem.id, value)}
                                    onOk={(value) => changeGridItem(gItem.id, value)}
                                    disabledTime={disabledTime}
                                />
                                <Popconfirm
                                    title={'Вы уверены что хотите удалить интервал?'}
                                    onConfirm={() => deleteGridItem(gItem.id)}
                                    onCancel={() => message.warning('Удаление отменено')}
                                    okText="Да"
                                    cancelText="Нет"
                                >
                                    <Button className='float-end'>
                                        <FaMinus className="d-flex justify-content-between" />
                                    </Button>
                                </Popconfirm>




                                {/*                                 <Paragraph
                                    editable={{
                                        tooltip: 'Введите название сетки',
                                        onChange: (value) => changeGridName(grid.id, value),
                                        triggerType: ['icon','text'],
                                    }}
                                >
                                    {grid.name}
                                </Paragraph> */}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminScheduleGridItems;