import React, { useState } from 'react';
import { Container, Dropdown } from 'react-bootstrap';

const ClassSubjectDropdown = ({ classes, subjects, property, changeProperty }) => {
    const [clas, setClass] = useState(classes.filter(j => j.id == property?.value?.split('|')[0])[0])
    const [subject, setSubject] = useState(subjects.filter(j => j.id == property?.value?.split('|')[1])[0])

    const changeClass = (clas) => {
        setClass(clas)
        let value = clas.id + '|' + subject?.id
        changeProperty(property.key, value, property.id)
    }

    const changeSubject = (subject) => {
        setSubject(subject)
        let value = clas?.id + '|' + subject.id
        changeProperty(property.key, value, property.id)
    }

    return (
        <Container>
            <Dropdown>
                <Dropdown.Toggle>{clas?.name || "Выберите класс"}</Dropdown.Toggle> 
                <Dropdown.Menu>
                    {classes.map(clas =>
                        <Dropdown.Item
                            onClick={() => changeClass(clas)}
                            key={clas.id}
                        >
                            {clas.name}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
                <Dropdown.Toggle>{subject?.name || "Выберите предмет"}</Dropdown.Toggle>
                <Dropdown.Menu>
                    {subjects.map(subject =>
                        <Dropdown.Item
                            onClick={() => changeSubject(subject)}
                            key={subject.id}
                        >
                            {subject.name}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </Container>
    );
};

export default ClassSubjectDropdown;