import {  useContext } from 'react';
import { observer } from "mobx-react-lite";
import { Form } from 'react-bootstrap';
import { Context } from '../../..';
import { fetchTeacherStudents } from '../../../http/teacherApi';
import { Card, Col, Row } from 'antd';

const AdminSelectClassStudent = observer(() => {
    const { admin } = useContext(Context)

    //console.log("TTSC",teacher.classes, teacher.selectedClass, teacher.teacherStudents, teacher.selectedStudent);

    const getClassID = () => {
        if (admin.selectedClass && Object.keys(admin.selectedClass).length != 0) {
            return admin.selectedClass.id
        } else {
            setClass(admin?.classes[0]?.id | 0)
            //admin.setSelectedClass(admin.classes[0])
            return admin?.classes[0]?.id | 0;
        }
    }

    const getStudentID = () => {
        if (admin.selectedStudent && Object.keys(admin?.selectedStudent).length != 0) {
            return admin.selectedStudent.id
        } else {
            loadStudents(admin.selectedClass.id)
            //teacher.setSelectedStudent(teacher.teacherStudents[0])
            return admin.teacherStudents[0]?.id
        }
    }

    const setClass = (classID) => {
        // console.log("ADS1",classID);
        admin.setSelectedClass(admin.classes.find(clas => clas.id == classID))
        loadStudents(classID)
    }

    const loadStudents = async (classID) => {
        const data = await fetchTeacherStudents(classID)
        admin.setTeacherStudents(data)
        admin.setSelectedStudent(data[0])
    }

    const setStudent = (studentID) => {
        // console.log("ADS2",subjectID);
        admin.setSelectedStudent(admin.teacherStudents.find(stu => stu.id == studentID))
        // teacher.setSelectedSubMenu({ id: 41 })
    }
    /* <Container style={{ marginBottom: '15px' }}> */
    return (

        <Card>
            <Row>
                <Col span={12}>
                    <Form /* className="mx-2" */>
                        <Form.Select onChange={(e) => setClass(e.target.value)} /* className="bg-transparent" bsPrefix="form-select" */ defaultValue={getClassID()}>
                            {admin.classes.map(clas =>
                                <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                            )}
                        </Form.Select>
                    </Form>
                </Col>
                {admin.teacherStudents.length > 0 &&
                    <Col span={12}>
                        <Form /* className="mx-2" */>
                            <Form.Select onChange={(e) => setStudent(e.target.value)} /* className="bg-transparent mt-1" bsPrefix="form-select" */ defaultValue={getStudentID()}>
                                {admin.teacherStudents.map(student =>
                                    <option key={'student' + student.id} value={student.id}>{student.name}</option>
                                )}
                            </Form.Select>
                        </Form>
                    </Col>
                }
            </Row>
        </Card>
    );
});

export default AdminSelectClassStudent;