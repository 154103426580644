import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { Container } from 'react-bootstrap';
import { fetchRoutes, oneTheme } from '../../../http/teacherApi';
import { fetchDescriptionsByThemeID } from "../../../http/studentApi";
import { Context } from "../../..";
import HTMLViewer from "../../utilites/HTMLViewer";
import FilesUpload from "../../utilites/FilesUpload";


const StudentSillabus = observer(({ student }) => {
    // const { student } = useContext(Context)
    const [routes, setRoutes] = useState([])
    const [theme, setTheme] = useState({})
    const [descriptions, setDescriptions] = useState([])

    useEffect(() => {
        loadData(student.selectedTheme.id)
        oneTheme(student.selectedTheme.id).then(data => setTheme(data))
    }, [student.selectedTheme])

    const loadData = async () => {
        const data = await fetchRoutes(student.selectedTheme.id)
        setRoutes(data)
        /*     let dataDesc = []
            for (let index = 0; index < data.length; index++) {
                const element = await fetchDescriptions(data[index].id) 
              //  console.log("el",element)
                dataDesc.push(...element)
            } */

        // console.log("fdsf",student.studentData);
        const dataDesc = await fetchDescriptionsByThemeID(student.selectedTheme.id, student.studentData.id)
        setDescriptions(dataDesc)
    }

    function click(desc) {
        student.setSelectedDescription(desc)
        student.setSelectedSubMenu({ id: 62 })
        /*  setDescToModal(desc)
         setResponsesVisible(true) */
    }

    return (
        <Container>
            Тема №{theme.index} ({theme.name})
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Маршрут</th>
                        <th>Описание</th>
                        {/* <th>СР</th>
                        <th>Самооценка</th> */}
                        <th>Оценка</th>
                    </tr>
                </thead>
                <tbody>
                    {descriptions.map(desc =>
                        <tr key={desc.id}>
                            <td key={"r" + desc.silabusRoute.id + desc.id} style={{ backgroundColor: desc.silabusRoute.color }}>
                                {desc.index === 1 && desc.silabusRoute.name}
                            </td>
                            <td key={"t" + desc.id} style={{ backgroundColor: desc.silabusRoute.color }}>
                                <HTMLViewer
                                    value={desc.text}
                                ></HTMLViewer>
                                <FilesUpload
                                    fileList={desc.fileList}
                                    disabled={true}
                                //    action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'descriptions'}&id=${description.id}`}
                                >
                                </FilesUpload>
                            </td>
                            {/* <td key={"dz" + desc.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => click(desc)}
                            >
                                СР  */}{/* Замена на зеленый OK */}
                            {/* </td> */}
                            {/* <td key={"st" + desc.id}>{desc.studentScore}</td> */} {/* оценка ученика */}
                            {/* <td key={"ss" + desc.id}>{desc.teacherScore}</td> */} {/* оценка учителя */}
                            {!desc.disabled ?
                                <td key={"dz" + desc.id}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => click(desc)}
                                >
                                    {desc.score ?
                                        desc.score
                                        :
                                        <div class={'btn btn-primary'}>Ответить</div>
                                    }
                                    {/*  {desc.score}*/}
                                </td>
                                :
                                <td key={"dz" + desc.id}>
                                    {desc.score}
                                </td>
                            }
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container >
    );
});

export default StudentSillabus;