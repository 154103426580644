import React, { ReactNode, useState } from 'react';
import { Form } from "react-bootstrap";
import { Button, Card, Tree, GetProps, } from 'antd';
import { $authHost } from '../http';
import { fetchClass, fetchSilabusV2, fetchSilabusV2Data, fetchSubject, } from '../http/teacherApi';
import BasicTable from '../components/utilites/old/BasicTable';
import { Outlet, useLoaderData } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs'

import { SelectClassSubject, selectClassSubjectAction, selectClassSubjectLoader } from '../components/teacher/syllabus/SelectClassSubject';
import { SelectTact, selectTactLoader, selectTactAction } from '../components/teacher/syllabus/SelectTact';
dayjs.locale('ru');


interface Trecord {
  key?: string,
  id?: string,
  accessor?: string,
  Header?: string,
  columns?: Trecord[] | [],
  style?: React.CSSProperties
}

const styleModules = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleThemes = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleRoutes = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };
const styleSkills = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };


function generate_modules(modules: any) {
  const _header = { Header: 'Модуль', style: styleModules, }
  const output_array: any[] = [];
  if (Array.isArray(modules) && modules.length > 0) {
    var t_header = [];
    for (const module of modules) {
      const [header, themes] = generate_themes(module.themes);
      t_header = header
      output_array.push({
        //          Header: (module.name) ? `${module.name} (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')})` : "!", accessor: "module" + module.id, id: "module" + module.id, style: styleModules
        Header: (module.name) ? `${module.name}` : "!", columns: themes, accessor: "module" + module.id, /* key: "module" + module.id, */ style: styleModules
      });
    }
    return [[{ ..._header, columns: t_header }], output_array]
  } else {
    return [[_header], [{ Header: 'Добавте модуль', /* accessor: 'module',  */id: "module", style: styleModules }]];
  }
}

function generate_themes(themes: any) {
  //console.log("themes", themes);

  const _header = { Header: 'Темы', style: styleThemes, }
  const output_array: any[] = [];
  if (Array.isArray(themes) && themes.length > 0) {
    var s_header: any = [];
    var s_skills: any = [];
    for (const theme of themes) {
      const [header, skills] = generate_skills(theme.skills);
      const [header1, descriptions] = generate_descriptions(theme.descriptions)
      //console.log("header", [{...header[0], columns: header1}]);

      //s_header = header
      s_header = [{ ...header[0], columns: header1 }]
      s_skills = [{ ...skills[0], columns: descriptions }]
      output_array.push({ Header: (theme.name) ? `${theme.name} (до ${dayjs(theme.end).format('DD MMM')})` : "Нет темы", columns: s_skills, id: "theme-" + theme.id, style: styleThemes })
    }
    return [[{ ..._header, columns: s_header }], output_array]
  } else {
    return [[_header], [{ Header: 'Добавте тему', accessor: 'theme', id: "theme", style: styleModules }]];
  }
}


export const style1: React.CSSProperties = { background: '#d4ecff', padding: '3px'/* , minHeight: '300px', minWidth: '250px' */, justifyItems: 'flex-start', position: 'relative', margin: '0px 0px 2px 0px ', };

function generate_skills(skills: any) {
  const _header = [{ Header: 'Умения', style: styleRoutes, }]
  const output_array: any[] = [];
  var text: ReactNode = (<div></div>);
  if (Array.isArray(skills) && skills.length > 0) {
    text = skills.map((skill) => { return (<div style={style1}>{(skill.name) ? skill.name : "!"}</div>) })
    /*
        for (const skill of skills) {
          console.log(SkillViever((skill.name) ? skill.name : "!"));
          
          text.appe = SkillViever((skill.name) ? skill.name : "!");
          //output_array.push({ Header: (skill.name) ? skill.name : "!", id: "skill-" + skill.id, style: { ...styleRoutes, background: skill.color } })
        }
    */

    output_array.push({ Header: text, id: "skill-" + Math.random(), style: { ...styleRoutes, /* , background: skill.color */ } })
    return [_header, output_array]
  } else {
    return [_header, [{ Header: ' ', /* accessor: 'skill', */ id: "skill" + Math.random(), style: styleSkills }]];
  }
}

function generate_descriptions(descriptions: any) {
  const _header = [{ Header: 'Уровень', id: 'level', style: styleDescriptions, }]
  const output_array: any[] = [];
  if (Array.isArray(descriptions) && descriptions.length > 0) {
    for (const description of descriptions) {
      output_array.push({ Header: description?.description, /* id: "d-" + description.id, */ accessor: "d-" + description.id, editable: true, style: { ...styleDescriptions, /* , background: skill.color */ } })
    }
    return [_header, output_array]
  } else {
    return [_header, [{ Header: '', /* accessor: 'description', */ id: "description" + Math.random(), style: styleDescriptions }]];
  }
}




export const testLoader = async ({ params }: any) => {
  const { selectedClassSubject } = params;

  console.log("params", params);

  /* 
    const { data } = await $authHost.get('api/test');
    console.log(data); */


  const [classID, subjectID] = selectedClassSubject?.split("|") || [10,38]
  //const classID = 10, subjectID = 38;
  //  const classID = 8, subjectID = 5;
  const tree = [];
  const out_modules: Trecord[] = [];

  var data3 = await fetchSilabusV2Data(classID, subjectID, "2023-8-16", "2023-11-20");
  var data4 = data3.map((item: any) => { return { ...item, 'd-14': '1', } })

  /* const mods = await fetchModules(classID, subjectID);
  for (const module of mods) {
    tree.push({
      title: (module.name) ? `${module.name} (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')})` : "!",
      key: module.id
    })
  } */
  //tree.push(AddButton({ key: `${classID}-${subjectID}`, name: 'Добавить модуль', onClick: onClick(`${classID}-${subjectID}`) }))

  const classData = await fetchClass(classID);
  const subjectData = await fetchSubject(subjectID);
  //console.log("class", classData.name, subjectData.name);

  const modules = await fetchSilabusV2(classID, subjectID, "2023-8-16", "2023-11-20");
  //const modules: any[] = [];
  //  var m_columns: any[] = [{ Header: 'Модуль', style: styleModules, }];

  //  const _modules = generate_modules(modules);
  //  console.log("generate", _modules);

  /* 
    if (Array(modules).length > 0) {
      for (const module of modules) {
        const themes = [];
        const t_columns: any[] = [{ Header: 'Темы', style: styleThemes, }];
        //      console.log("module", module);
  
        if (Array(module.themes).length > 0) {
  
          for (const theme of module.themes) {
            //console.log("theme", theme);
            const routes = [];
  
            if (theme.skills.length) {
              for (const route of theme.skills) {
                const descriptions = [];
  
                if (route.descriptions) {
                  for (const description of route.descriptions) {
  
                    const key = "description_" + description.id;
                    data3 = data3.map((item: any) => {
                      const score = description.responses.find((r_item: ResponseItem) => r_item.studentID === item.id)?.score
                      if (score)
                        return { ...item, [key]: score }
                      else
                        return { ...item }
                    })
  
                    descriptions.push({
                      accessor: "description_" + description.id,
                      //Header: (description.text) ? () => { return (<HTMLViewer value={description.text} />) } : "Нет описания", id: "description_" + description.id,
                      Header: "Описание краткое",
                      //style: { background: '#d4ecff', padding: '4px', minHeight: '300px', minWidth: '250px', justifyItems: 'flex-start', position: 'relative', margin: '0px', },
                      style: styleDescriptions
                    });
                  }
  
                  const _route = { Header: (route.name) ? route.name : "!", id: "route-" + route.id, style: { ...styleRoutes, background: route.color } };
                  if (descriptions.length > 0) {
                    routes.push({ ..._route, columns: descriptions });
                  } else {
                    routes.push(_route);
                  }
                }
              }
  
              const _theme = { Header: (theme.name) ? `${theme.name} (до ${dayjs(theme.end).format('DD MMM')})` : "Нет темы", id: "theme-" + theme.id, style: styleThemes }
  
              if (routes.length > 0) {
                themes.push({ ..._theme, columns: routes });
              } else {
                themes.push(_theme);
              }
            } else {
              //themes.push({ Header: 'Добавте тему', accessor: 'theme'+module.id, id: "theme"+module.id, style: styleThemes })        
            }
          }
        }
        //themes.push({ Header: 'Добавте тему', id: "theme"+module.id, style: styleModules })
  
        console.log("themes.length", themes.length, themes);
  
        if (themes.length > 0) {
          out_modules.push({
            //          Header: (module.name) ? `${module.name}  (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')}) ` : "!", columns: themes, accessor: "module" + module.id, id: "module" + module.id, style: styleModules
            Header: (module.name) ? `${module.name}` : "!", columns: themes, accessor: "module" + module.id, key: "module" + module.id, style: styleModules
          });
        } else {
          out_modules.push({
            //          Header: (module.name) ? `${module.name} (${dayjs(module.begin).format('DD MMM')} - ${dayjs(module.end).format('DD MMM')})` : "!", accessor: "module" + module.id, id: "module" + module.id, style: styleModules
            Header: (module.name) ? `${module.name}` : "!", accessor: "module" + module.id, key: "module" + module.id, style: styleModules
          });
        }
      }
    } else {
      out_modules.push({ Header: 'Добавте модуль', accessor: 'module', id: "module", style: styleModules })
    }
   */
  const [m_columns, m_modules] = generate_modules(modules);

  const silabus: Trecord[] = [{
    Header: '   ',
    columns: [{
      Header: 'N п/п',
      accessor: 'id'
    }, {
      Header: 'ФИ студента',
      accessor: 'name',
      style: styleModules,
    }]
  }, {
    Header: 'Предмет:',
    style: { background: '#bfbfbf', padding: '4px', border: 'solid 1px gray' },
    columns: m_columns /*[{
      Header: 'Модуль',
      style: styleModules,
        columns: [{
          Header: 'Темы',
          style: styleThemes,
          columns: [{
            Header: 'Умения',
            style: styleRoutes,
            columns: [{
              Header: 'Уровень',
              style: styleRoutes,
              columns: [{
                Header: 'Оценивание'
              }]
            }]
          }]
        }]
    }]*/
  }, {
    Header: `${subjectData.name} / ${classData.name}`,
    columns: m_modules,
    style: { background: '#ffffff', padding: '4px', border: 'solid 1px gray' }
  }]

  return { /* rows: data, */ silabus, data: data4, modules,/*  tree  COLUMNS*/ selectedClassSubject };
}

export const style: React.CSSProperties = { background: '#d4ecff', padding: '4px', minHeight: '300px', minWidth: '250px', justifyItems: 'flex-start', position: 'relative', margin: '0px', };
//const styleModules: React.CSSProperties = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
//const styleThemes: React.CSSProperties = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
//const styleRoutes: React.CSSProperties = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };
const styleDescriptions: React.CSSProperties = { background: '#efefef', padding: '3px', border: 'solid 1px gray'/* , writingMode: 'vertical-rl' */, minHeight: '400px', minWidth: '50px', fontSize: '0.rem', verticalAlign: 'top' };

const { DirectoryTree } = Tree;

interface DataNode {
  title: string | ReactNode;
  key: string | number;
  isLeaf?: boolean;
  children?: DataNode[];
  selectable?: boolean;
}
interface ResponseItem {
  score: string,
  studentID: number
}

interface DescriptionItem {
  id: number;
  index: number;
  text: string;
  responses: ResponseItem[];
}

interface RouteItem {
  id: number;
  index: number;
  name: string;
  color: string;
  descriptions: DescriptionItem[];
}

interface ThemeItem {
  id: number;
  index: number;
  name: string;
  workTypeId: number;
  workTypeName: string;
  routes: RouteItem[];
}

interface ModuleItem {
  id: number;
  index: number;
  name: string;
  themes: ThemeItem[];
}

interface LoadData {
  silabus: ModuleItem[],
  modules: any,
  data: any,
  tree: DataNode[],
  COLUMNS: any,
  rows: [],
}

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;

const initTreeData: DataNode[] = [
  /*   {
      title: 'Модуль 1:', key: '10', children: [
        { title: 'leaf 0-0', key: '0-0-0', isLeaf: true },
        { title: 'leaf 0-1', key: '0-0-1', isLeaf: true },
      ],
    },
    { title: 'Expand to load', key: '11' },
    { title: 'Tree Node', key: '20', isLeaf: true }, */
];

const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

const EmptySelected = () => {
  return (
    <Card >
      Выберете модуль в меню слева
    </Card>
  )

}

const Test = () => {
  const { rows, silabus, modules, data, tree, COLUMNS } = useLoaderData() as LoadData;

  const copy = async (id: string) => {
    const [classID, subjectID] = id.split("|");
    console.log("click", classID, subjectID);

    await $authHost.post('api/test', { classID, subjectID });
  }



  //console.log("test modules:", modules, data);
  console.log("silabus", COLUMNS, silabus);


  const [url, setURL] = useState('')
  const [body, setBody] = useState('')
  const [type, setType] = useState('POST')
  const [answer, setAnswer] = useState('')
  const [treeData, setTreeData] = useState(/* initTreeData */tree);
  const [selected, setSelected] = useState<ReactNode>(EmptySelected());
  //const [modules, setModules] = useState<ModuleItem[]>([]);

  /* useEffect(() => {
    LoadData();
  }, []); */

  const send = async (event: any) => {
    try {
      event.preventDefault();
      let data
      switch (type) {
        case 'POST': data = await $authHost.post(url, { body })
          break
        case 'GET': data = await $authHost.get(url)
          break
        case 'PUT': data = await $authHost.put(url, { body })
          break
        //        case 'DELETE': data = await $authHost.delete(url, { body })
        //          break
        default: { }
      }
      console.log(data);
      setAnswer(JSON.stringify(data))
      //alert(data.data.toString());

    } catch (e) {
      alert(e)
    }
  }
  /* 
    const handleKeyPress = (event:any) => {
      if (event.key === 'Enter') {
        console.log('enter press here! ')
        send(event)
      }
    }
   */

  const key2number = (key: React.Key) => {
    var params: string | number | undefined | string[] = undefined;
    var _type = 'module'

    if (typeof (key) === 'number') {
      return { _type, id: key }
    }
    if (typeof (key) === 'string') {
      params = key.split('-');

      switch (params.length) {
        case 2: _type = 'theme'; break;
        case 3: _type = 'route'; break;
        case 4: _type = 'description'; break;
        default: console.log('default');
      }

      return { _type, id: params[params.length - 1] }
    }
    return undefined
  }

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    console.log('Trigger Select', keys, info);
    const { _type, id }: number | any = key2number(keys[0]);

    switch (_type) {
      //case 'module': setSelected(<ModuleCard key={id} id={id}></ModuleCard>); break;
      //case 'theme': ret = await fetchRoutes(id); isLeaf = false; break;
      //case 'route': ret = await fetchDescriptions(id); isLeaf = true; break;
      default: setSelected(EmptySelected());
    }
  };

  const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
    //console.log('Trigger Expand', keys, info);
  };

  /*  type ValuePiece = Date | null;
   type Value = ValuePiece | [ValuePiece, ValuePiece]; */
  const [valueDate, onChangeDate] = useState<string>('2014-08-01');
  /* const [startDate, setStartDate] = useState(new Date());
  */
  return (
    <>
      <Card>
        <Outlet />
        {/*         <div style={{ width: '250px', border: '1px black', display: 'flex', flexBasis: '100px' }}>
          <input aria-label="Date" type="date"  value={valueDate} onChange={(e) => {
            const newDate = dayjs(e.target.value).format('YYYY-MM-DD');
            console.log(newDate);
            onChangeDate(newDate)
          }
          } />
        </div> */}
        {/* <Calendar onChange={onChangeDate} value={valueDate} maxDetail='month'/> */}

        {/*         <hr />
        {rows.map((item:{name:string, value:string, cnt:number}) => (
          <div>{item.name} {item.cnt} <Button onClick={() => copy(item.value)}>Копи</Button></div>
        ))}
        <hr /> */}
        {/*         <table>
          <thead>
            <tr>
              <th>Модуль</th>
              {silabus.map((column) => (
                <th colSpan={column.themes.length}>
                  {column.name}
                </th>
              ))}
            </tr>
            <tr>
              <th>Темы</th>
                {silabus.map((module) => {
                  const ret = module.themes.map((theme) => {
                    return (<th colSpan={theme.routes.length}>{theme.name}</th>)
                  })
                  return ret
                })}
            </tr>
            <tr>
              <th>Умения</th>
                {silabus.map((module) => {
                  const ret = module.themes.map((theme) => {
                    const ret = theme.routes.map((route) => {
                      return (<th colSpan={route.descriptions.length}>{route.name}</th>)
                    })
                    return ret
                  })
                  return ret
                })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                2
              </td>
            </tr>
          </tbody>
        </table> */}

        <BasicTable silabus={silabus} /* modules */ data={data} />

        <hr />
        {/* <Accordion list={[{a:'1 вопрос', q:'1 ответ'},{q:'2 вопрос', a:<Accordion list={[{a:'3 вопрос', q:'3 ответ'},{a:'4 вопрос', q:'4 ответ'}]}/>}]}/> */}
      </Card>

      <Card> {/* directory tree */}
        {/*         <Row gutter={[8, 8]}>
          <Col span={12}>
            <DirectoryTree loadData={onLoadData1} treeData={treeData} onSelect={onSelect} onExpand={onExpand} showIcon={false} />
          </Col>
          <Col span={12}>
            {selected}
          </Col>
        </Row> */}
      </Card>
      <Card> {/* форма запросов */}

        <Form className="d-flex flex-column">
          <Form.Select onChange={(e) => setType(e.target.value)} className="mt-3 bg-transparent" bsPrefix="form-select" defaultValue={type}>
            <option key={'POST'} value={'POST'}>{'POST'}</option>
            <option key={'GET'} value={'GET'}>{'GET'}</option>
            <option key={'PUT'} value={'PUT'}>{'PUT'}</option>
            <option key={'DELETE'} value={'DELETE'}>{'DELETE'}</option>
          </Form.Select>

          <Form.Control
            className="mt-3"
            placeholder="URL:"
            value={url}
            onChange={e => setURL(e.target.value)}
          />

          <Form.Control
            as="textarea"
            className="mt-3"
            placeholder="BODY:"
            rows={5}
            value={body}
            onChange={e => setBody(e.target.value)}
          />

          <Button
            className='mt-3 align-self-end'
            /* variant={'outline-success'} */
            onClick={send}
          >
            Отправить
          </Button>

          <Form.Control as="textarea"
            className="mt-3"
            placeholder="SERVER ANSWER:"
            rows={5}
            value={answer}
            onChange={e => { }}
          />
        </Form>
      </Card>
    </>
  );
};

export default Test;

export const testRouter = [
  //{ index: true, element: < Navigate to='eventcalendar' /> },
  {
    path: 'silabus/*', element: <><SelectClassSubject /><Outlet /></>, loader: selectClassSubjectLoader, action: selectClassSubjectAction, children: [
      {
        path: ':selectedClassSubject/*', element: <><SelectTact/><Outlet/></>,  loader: selectTactLoader, action: selectTactAction, children: [
          {path: ':selectedTact/*', element: <></>,  /* loader: selectTactLoader, action: selectTactAction */}
        ]
      }
    ]
  },

];
