import { action, makeAutoObservable, runInAction } from "mobx";
import { createSubject, deleteSubject, fetchSubjects, modyfySubject } from "../http/adminApi";

type SubjectType = {
    id: number
    name: string
    opened: boolean
}

class Subjects {
    private _subjects: SubjectType[] = [] as SubjectType[]
    private _selectedSubject: SubjectType = {} as SubjectType
    private _isLoading: boolean = false
    private _needReload: boolean = false

    constructor() {
        this.reloadSubjects()
        //fetchSubjects().then(data => this.setSubjects(data))

        makeAutoObservable(this)
    }

    clearAll = () => {
        this.setSubjects([] as SubjectType[])
        this.setSelectedSubject({} as SubjectType)
    }
    reloadSubjects = async () => {
        // TODO проверка данных
        const data = await fetchSubjects()
        this.setSubjects(data)
        this.setNeedReload(false)
    }

    addSubject = async () => {
        const id = await createSubject()
        if (id > 0) {
            this.setSubjects([{ id: id/* , opened: true */ } as SubjectType, ...this.subjects])
        }
    }

    removeSubject = async (id: number) => {
        await deleteSubject(id)
        // TODO проверка данных
        this.setSubjects(this.subjects.filter(i => i.id !== id))
    }

    changeSubject = async (name: string, id: number) => {
        await modyfySubject(name, id)
        // TODO проверка данных
        this.setSubjects(this.subjects.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    setSubjects(subjects: SubjectType[]) {
        this._subjects = subjects
    }
    setSelectedSubject(subject: SubjectType) {
        this._selectedSubject = subject
    }
    setIsLoading(isLoading: boolean) {
        this._isLoading = isLoading
    }
    setNeedReload(needReload: boolean) {
        this._needReload = needReload
    }

    get subjects(): SubjectType[] {
        if (this.needReload) {
            this.reloadSubjects()
        }
        return this._subjects
    }
    get selectedSubject() {
        return this._selectedSubject
    }
    get isLoading(): boolean {
        return this._isLoading
    }
    get needReload(): boolean {
        return this._needReload
    }

}

const SubjectsStore = new Subjects()
export default SubjectsStore
