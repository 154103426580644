import React, { useContext } from 'react';
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx"
import { Context } from '../..';
import '../navbar.css'
import { Breadcrumb } from 'antd';
//import 'antd/dist/antd.css';

const ParentSubBar = observer(() => {
    const { parent } = useContext(Context)


    function click(subMenuItem) {
        runInAction(() => {
            parent.setSelectedSubMenu({ id: subMenuItem })
        })
    }

    const style = {
        cursor: 'pointer',
        backgroundColor: '#ffd110',
        paddingBottom: '5px',
        paddingLeft: '5px',
        paddingTop: '5px',
        paddingRight: '5px'
    };

    return (
        <Breadcrumb>
            {parent.selectedSubMenu?.id == '62' &&
                <Breadcrumb.Item onClick={() => click(61)} style={style}>
                    Тема №{parent.selectedTheme.index} ({parent.selectedTheme.name})
                </Breadcrumb.Item>
            }
            {parent.selectedSubMenu?.id == '42' &&
                <Breadcrumb.Item onClick={() => click(41)} style={style}>
                    {parent.studentSubjects?.find(i => i.id == parent.selectedSubject)?.name}
                </Breadcrumb.Item>
            }
        </Breadcrumb>
        /*         <Container>
                    {student.selectedSubMenu?.id == '62' &&
                        <Button
                            onClick={() => click(61)}
                        >
                            Тема №{student.selectedTheme.index} ({student.selectedTheme.name})
                        </Button>
                    }
                </Container> */
    );
});

export default ParentSubBar;