import { Card, Col, DatePicker, Row, Tree } from "antd";
import { GetProps, Typography } from 'antd';
import { Key, ReactNode, useEffect, useMemo, useState } from "react";
import { BiAddToQueue, BiCommentAdd, BiDotsHorizontal, BiPlus, BiSolidAddToQueue, BiTrash } from "react-icons/bi";
import { createRoute, createRouteV2, createTheme, deleteModule, deleteRoute, deleteRouteV2, fetchModules, fetchRoutes, fetchRoutesV2, modyfyDescriptionOV2, modyfyModuleO, modyfyRouteO, modyfyRouteOV2, modyfyThemeO, oneModule, oneTheme, oneThemeV2 } from "../../http/teacherApi";
import MyDatePicker from "../MyDatePicker";
import Select from 'react-select'
import { fetchWorkTypes } from "../../http/adminApi";
import { Form, Navigate, Outlet, redirect, useLoaderData, useNavigate, useSubmit } from "react-router-dom";
import DescriptionCard from "../DescriptionCardList";
import DescriptionCard2 from "../DescriptionCard2";
import DirectoryTreeDnD from "../teacher/syllabus/DirectoryTreeDnD";
import { key2number } from "../teacher/syllabus/Modules2";
import { Button } from "react-bootstrap";


import dayjs from 'dayjs'
dayjs.locale('ru')

const { DirectoryTree } = Tree;

const { Paragraph } = Typography;

export async function loaderSkillsListCard({ params }: any) {
    console.log("loaderRouteListCard", params);

    const rawWorkTypes = await fetchWorkTypes();
    const workTypes = rawWorkTypes.map((item: any) => { return { value: item.id, label: item.name, color: '#00B8D9' } });

    const { routeID, moduleID, themeID, descriptionID } = params;

    const raw_key: string[] = []
    raw_key.push(moduleID);
    if (themeID) raw_key.push(themeID);
    if (routeID) raw_key.push(routeID);
    if (descriptionID) raw_key.push(descriptionID);

    const key = raw_key.join('-');

    const target = { id: moduleID + '-' + themeID };

    if (themeID) {
        const theme = await oneThemeV2(themeID);

        const routes = await fetchRoutesV2(themeID);

        return { theme, workTypes, routes, target, key }
    }




    return { key }
}

export async function createSkillsListCard({ params }: any) {
    const { themeID, moduleID } = params;
        const ret = await createRouteV2(themeID);
        console.log("createRouteCard: new routeID:", ret);
        return redirect(`../${moduleID}/${themeID}`);// /${ret}
}



/* export async function createRouteListCard({ params }: any) {
    console.log("createRouteListCard", params);

    const { moduleID } = params;
    if (moduleID) {
        const ret = await createTheme(moduleID);
        console.log("new themeID:", ret);
        return redirect("../"+ret);
    }
    return redirect(".")
}
 */
export async function destroySkillsListCard({ params, request }: any) {
    console.log("destroyRouteListCard", params, request);
    const { themeID, moduleID, routeID } = params
    if (routeID) {
        await deleteRouteV2(routeID);

    }
    return redirect('.');
}


export async function actionSkillsListCard({ params, request }: any) {
    const formData = await request.formData();
    const { query } = Object.fromEntries(formData);

    if (query) {
        console.log("actionThemeCard 2", params, JSON.parse(query));
        const { id, name, type, index, key, workType, color, routeID, descriptionID, text, description } = JSON.parse(query);
        if (routeID) {
            if (name === '') {
                deleteRouteV2(routeID)
                return redirect("..");
            }
            modyfyRouteOV2({ routeID, name, color, index });
            return redirect(routeID);
        }
        if (descriptionID) {
            modyfyDescriptionOV2({ descriptionID, text, description, index });
            return redirect("..");
        }

    }

    return redirect('..');
}



interface Module {
    id: number,
    index?: string,
    name?: string,
    begin?: string | null,
    end?: string,
    workType?: number
}

interface DataNode {
    title: string | ReactNode;
    key: string | number;
    isLeaf?: boolean;
    children?: DataNode[];
    selectable?: boolean;
}

type DirectoryTreeProps = GetProps<typeof Tree.DirectoryTree>;

const SkillsListCard = (/* params: { id: number | any } */) => {
    const submit = useSubmit();

    const { workTypes, theme, routes, target, key, } = useLoaderData() as any;
    const navigate = useNavigate();
    const [treeData, setTreeData] = useState<DataNode[]>([]/* useMemo(() => tree, [modules]) */);

    const { id, _type, selected, expanded } = key2number(key);

    //console.log("ThemeCard2", key, "selected", selected, "routes", routes, id);


    const [selectedKeys, setSelectedKeys] = useState<Key[] | undefined>(selected);

    /*     useEffect(() => {
            //console.log('useeffect', selected, expanded);
        
            setSelectedKeys(selected);
    //            setExpandedKeys(expanded);
          }, [selected]) */

    const [workTypes1, setWorkTypes] = useState([])
    const [module, setModule] = useState<Module>({ id: 0 });

    const _editable = (props: any) => {
        return (
            <Paragraph
                editable={{
                    tooltip: 'Редактировать',
                    onChange: (name) => change({ key: props.id, name }),
                    triggerType: ['icon'/* , 'text' */],
                }}
            >
                {props?.name}
            </Paragraph>
        )
    }

    const _add = (routes: any) => {
        const treeData = routes.map((item: any) => { return { title: _editable(item), key: String(item?.id), selectable: false } })
        treeData.push({ title: _new('Добавить умение'), key: 'new', isLeaf: true, selectable: false })
        return treeData
    }

    const _new = (text:any, key:any='') => {
        return (
          <Form method="post" action={key+"newSkill"}>
            <Button size="sm" type="submit">{text}</Button>
          </Form>
        )
      }
  
  
    const data = useMemo(() => ({
        get tree() {
            if (routes) {
                return _add(routes)
/*                 const treeData = routes.map((item: any) => { return { title: _editable(item), key: item?.id } })
                treeData.push({ title: 'Добавить умение', key: 'new', isLeaf: true })
                return treeData
 */            }
        }
    }), [routes])

    useEffect(() => {
        //console.log('useeffect', selected, expanded);

        setTreeData(data.tree)
        //setSelectedKeys(selected);
        //setExpandedKeys(expanded);
    }, [/* params */routes])




    /*  useEffect(() => {
         load(id);
     }, []);
 
     const load = async (id: any) => {
         const ret = await oneTheme(id);
         setModule(ret);
         const rawWorkTypes = await fetchWorkTypes();
         setWorkTypes(rawWorkTypes.map((item: any) => { return { value: item.id, label: item.name, color: '#00B8D9' } }));
     }
  */

    useEffect(() => {
        //loadModule(id);
        setModule(theme);
        setWorkTypes(workTypes);
    }, [theme, workTypes]);

    const change = async (props: any) => {
        //console.log("ch idx route", props);
        if (props?.index) {
            setSelectedKeys([props?.key])
        }

        //setModule({ ...module, ...value });
        //modyfyRouteO({ ...value })

        let formData = new FormData();
        formData.append("query", JSON.stringify({ ...props, routeID: props?.key, type: "route" }));
        submit(formData, { method: "POST" });


    }

    const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
        //console.log('Theme Trigger Select', keys, info);
        navigate(`${String(keys[0]).replace(/-/gi, '/')}`);
    };

    return (
        <>
            {/* <Card title={'Тема: ' + module.name} key={'theme' + module.id}> */}
            <Row gutter={[8, 8]}>
                <Col span={24}><h5>{'Тема: ' + module.name}</h5></Col>
                <Col span={24}>
                    <Card title='Умения'>
                        <DirectoryTreeDnD treeData={_add(routes)} onChange={change} /* onSelect={onSelect} */ selectedKeys={selectedKeys} /*expandedKeys={expandedKeys}   onExpand={onExpand} */ showIcon={false} expandAction={false} />
                    </Card>
                </Col>
                <Col span={24}>
                    <Outlet />
                </Col>
            </Row>

        </>
    )
}


export default SkillsListCard;