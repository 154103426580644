import React, { useContext, useState, useEffect } from 'react';
//import Modal from "react-bootstrap/Modal";
import { Dropdown, Table, Row, Col } from "react-bootstrap";
import { Context } from '../../..';
import { observer } from "mobx-react-lite";
import { fetchMKIntervals } from '../../../http/parentApi';
import { createMKInterval } from '../../../http/adminApi';
import { Card, Select, TimePicker, message, Modal } from 'antd';
import { Button, Form } from 'antd';
//import moment from 'moment';
//import 'moment/locale/ru';

async function getIntervals(studentID) {
  console.log('studentID', studentID);
  fetchMKIntervals(studentID).then(data => {
    console.log('data', data);
    return data;
  })

}

export const newIK = async (props) => {
  // проверка что ID есть
  if (props['interval'] && props['student']) {

    fetchMKIntervals(props['student']).then(async intervals => {
      let interval = intervals.find(i => i.id === props['interval'])
      let b = interval.begin//.split(" ")[0] + " " + begin
      let e = interval.end//.split(" ")[0] + " " + end
      const data = await createMKInterval(props['interval'], props['student'], props['parent'], b, e)
      if (data > 0) {
        message.success("Запись на наблюдение прошла успешно!")
        return true;
      } else {
        message.error("Записатся не удалось")
        return false;
      }
    });
  } else {
    message.error("Выберете все параметры для записи")
    return false;
  }
}





const AdminIKModal = (params) => {
  //  console.log("->", params);
  const form = params['form'];
  const setIsDisabled = params['setIsDisabled'];

  const { admin } = useContext(Context)

  const [selectedClassID, setSelectedClassID] = useState(0)
  const [selectedStudentID, setSelectedStudentID] = useState(0)
  const [selectedParentID, setSelectedParentID] = useState(0)
  const [selectedIntervalID, setSelectedIntervalID] = useState(0)
  const [begin, setBegin] = useState('')
  const [end, setEnd] = useState('')
  const [ikIntervals, setIKIntervals] = useState([])
  const [intervalReserved, setIntervalReserved] = useState(false)
  const [intervals, setIntervals] = useState({});

  useEffect(() => {
    // есть студент запрашиваем список интервалов 
    if (selectedStudentID) {
      fetchMKIntervals(selectedStudentID).then(data => {
        setIKIntervals(data)
        setIntervals(data.map((item) => { return { label: item.name, value: item.id } }));
        setSelectedIntervalID(data[0]?.id || null);
        if (data[0]?.reserved) {
          setIntervalReserved(true)
        } else setIntervalReserved(false)
      })
    }
  }, [selectedStudentID])

  useEffect(() => {
    console.log('====================================');
    console.log(selectedIntervalID);
    console.log('====================================');
    // есть интервал заполняем begin end 
    if (selectedIntervalID) {
      let interval = ikIntervals.find(i => i.id === selectedIntervalID)
      //setBegin(moment(interval.begin, timeFormat))
      //setEnd(moment(interval.end, timeFormat))
      //console.log("DSD", interval, interval.begin, interval.begin.split(" ")[1]);
      /*       let b = interval.begin.split(" ")[1]
            if (b.length == 4) {
              b = "0" + b
            }
            let e = interval.end.split(" ")[1]
            if (e.length == 4) {
              e = "0" + e
            }
            setBegin(b)
            setEnd(e) */
    }
  }, [selectedIntervalID])

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };

  const formStyle = {
    maxWidth: 'none',
/*     background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
 */    padding: 24,
    borderRadius: 10,
    //background: 'red',
  };

  function setInputs() {
    setSelectedClassID(0)
    setSelectedIntervalID(0)
    setSelectedParentID(0)
    setSelectedStudentID(0)
    setIKIntervals([])
    setIntervalReserved(false)
  }

  const onChange = (event) => {
    const values = form.getFieldsValue();
    console.log('onChange', event, values);
    if (values['class'] && values['student'] && values['interval'] && values['parent']) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  /*   const timeFormat = 'HH:mm';
    const disabledTime = (value) => {
      return { disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24] }
    } */



  return (
    <
      /*       onShow={setInputs}
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered */
      >

      <Form onFinish={newIK}  {...formItemLayout} form={form} /* style={formStyle} */ onValuesChange={onChange}>

        <Form.Item
          label="Класс"
          name="class"
        /*           wrapperCol={{
                    offset: 1,
                    span: 18,
                  }} */

        >
          <Select
            options={admin.classes.map(clas => { return { label: clas.name, value: clas.id } })}
            onChange={(value) => { setSelectedClassID(value) }}
          />

          {/*           <Dropdown className="mt-2 mb-2">
            Выберете класс:&nbsp;
            <Dropdown.Toggle>{admin.classes.find(i => i.id == selectedClassID)?.name || "Выберите класс"}</Dropdown.Toggle>
            <Dropdown.Menu>
              {admin.classes.map(clas =>
                <Dropdown.Item
                  onClick={() => setSelectedClassID(clas.id)}
                  key={clas.id}
                >
                  {clas.name}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown> */}
        </Form.Item>

        {Boolean(selectedClassID) &&
          <Form.Item
            label="Студент"
            name="student"
          >
            <Select
              options={admin.students.filter(i => i.properties.find(j => j.key == 'Класс')?.value == selectedClassID).map(student => { return { label: student.name, value: student.id } })}
              onChange={(value) => { setSelectedStudentID(value) }}
            />

            {/*             <Dropdown className="mt-2 mb-2">
              Выберете студента:&nbsp;
              <Dropdown.Toggle>{admin.students.find(i => i.id == selectedStudentID)?.name || "Выберите студента"}</Dropdown.Toggle>
              <Dropdown.Menu>
                {admin.students.filter(i => i.properties.find(j => j.key == 'Класс')?.value == selectedClassID).map(student =>
                  <Dropdown.Item
                    onClick={() => setSelectedStudentID(student.id)}
                    key={student.id}
                  >
                    {student.name}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          } */}
          </Form.Item>
        }
        {intervalReserved && <p>Уже запись на {ikIntervals[0]?.name}</p>}
        {ikIntervals.length == 0 && <p>Нет свободных дней для записи</p>}
        {Boolean(selectedClassID && selectedStudentID && !intervalReserved && ikIntervals.length > 0) &&
          <>
            {/*Выберете интервал:&nbsp;
               <Form.Select  className="bg-transparent" bsPrefix="form-select" defaultValue={selectedIntervalID} aria-label="Default select example">
                {ikIntervals.map(interval =>
                  <option onClick={(e) => setSelectedIntervalID(e.target.value)} key={'interval' + interval.id} value={interval.id}>{interval.name}</option>
                )}
              </Form.Select> */}

            <Form.Item
              label="Интервал"
              name="interval"
            >
              <Select options={intervals} className='' />
            </Form.Item>

            {/* {Boolean(selectedIntervalID) &&
                <>
                  <Row className="align-items-center">
                    <Col xs="auto">
                      Уточните время при необходимости:&nbsp;
                    </Col>
                    <Col xs="auto">
                      <Form.Control className="mt-2 mb-2"
                        placeholder='Начало ИК'
                        //defaultValue={begin}
                        value={begin}
                        onChange={e => setBegin(e.target.value)}
                        type='time'
                      />
                    </Col>
                    <Col xs="auto">
                      <Form.Control className="mt-2 mb-2"
                        placeholder='Окончание ИК'
                        //defaultValue={end}
                        value={end}
                        onChange={e => setEnd(e.target.value)}
                        type='time'
                      />
                    </Col>
                  </Row>


                  <TimePicker
                    minuteStep={5}
                    format={timeFormat}
                    allowClear={false}
                    defaultValue={moment(begin, timeFormat)}
                    //value={moment(begin, timeFormat)}
                    onChange={(value) => setBegin(value.format(timeFormat))}
                    disabledTime={disabledTime}
                  />
                  <TimePicker
                    minuteStep={5}
                    format={timeFormat}
                    allowClear={false}
                    defaultValue={moment(end, timeFormat)}
                    //value={moment(end, timeFormat)}
                    onChange={(value) => setEnd(value.format(timeFormat))}
                    disabledTime={disabledTime}
                  />  

              } */}
          </>
        }
        {Boolean(selectedIntervalID) &&
          <Form.Item
            label="Родитель"
            name="parent"
          >
            <Select
              options={admin.parents.filter(i => admin.students.find(i => i.id == selectedStudentID)?.properties.filter(j => j.key == 'Мама' || j.key == 'Папа').map(k => k.value).includes(String(i.id))).map(parent => { return { label: parent.name, value: parent.id } })}
              className=''
              onChange={(value) => { setSelectedParentID(value) }}
            />
          </Form.Item>
          /* <Dropdown className="mt-2 mb-2">
            Родитель:&nbsp;
            <Dropdown.Toggle>{admin.parents.find(i => i.id == selectedParentID)?.name || "Выберите родителя"}</Dropdown.Toggle>
            <Dropdown.Menu>
              {admin.parents.filter(i => admin.students.find(i => i.id == selectedStudentID)?.properties.filter(j => j.key == 'Мама' || j.key == 'Папа').map(k => k.value).includes(String(i.id))).map(parent =>
                <Dropdown.Item
                  onClick={() => setSelectedParentID(parent.id)}
                  key={parent.id}
                >
                  {parent.name}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown> */
        }

      </Form>

      {/* </Modal.Body> */}
      {/* <Modal.Footer> */}
      {/*           <Form.Item
            wrapperCol={{
              offset: 20,
              span: 16,
            }}>
            <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
              <Button variant="outline-success" onClick={() => newIK()}> 
              Добавить
            </Button>
          </Form.Item> */}
      {/* </Modal.Footer> */}

    </ >
  );
};

export default AdminIKModal;