import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { createMKInterval, createVisitInterval, deleteMKInterval, deleteVisitInterval } from '../../http/parentApi';
import { Checkbox, message } from 'antd';

//Запись на MK
const ParentMeetingMK = ({ student, mkIntervals: mkIntervals, carers, reload, isStudents }) => {

    const [selectedIntervalID, setSelectedIntervalID] = useState({})
    const [noIntervals, setNoIntervals] = useState(false)
    const [alreadyMK, setAlreadyMK] = useState(false)

    const [selectedCarers, setSelectedCarers] = useState([])

    useEffect(() => {
        setAlreadyMK(false)
        setNoIntervals(false)
        if (student?.id) {
            if (mkIntervals.length == 0) {
                // нет достыпных интервалов
                setNoIntervals(true)
            } else
                if (mkIntervals.length == 1 && mkIntervals[0]?.reserved) {
                    // уже записаны
                    setAlreadyMK(true)
                    setSelectedIntervalID(mkIntervals[0]?.id)
                } else {
                    // не записаны и есть интервалы
                    setSelectedIntervalID(mkIntervals[0]?.id)
                }
        }
    }, [student, mkIntervals, carers])

    const addMK = async () => {
        let begin = mkIntervals.find(i => i.id == selectedIntervalID).begin
        let end = mkIntervals.find(i => i.id == selectedIntervalID).end
        const data = await createMKInterval(selectedIntervalID, student?.id, selectedCarers, begin, end)
        if (data > 0) {
            message.success("Запись на ИК прошла успешно!")
            reload()
        } else {
            message.error("Записатся не удалось, пожайлуста выберете другую дату-время ИК")
            reload()
        }
    }

    const removeMK = async () => {
        const data = await deleteMKInterval(selectedIntervalID)
        if (data > 0) {
            message.success("Отмена записи прошла успешно!")
            setAlreadyMK(false)
            reload()
        } else {
            message.error("Отменить запись не удалось, пожайлуста свяжитесь с администратором")
            reload()
        }
    }

    const onChangeCarers = (checkedValues) => {
        //console.log('checked = ', checkedValues);
        setSelectedCarers(checkedValues)
    };

    return (
        <Container style={{ backgroundColor: "white", textAlign: "center", padding: "15px", marginTop: "40px" }}>
            <p style={{ fontSize: "16px" }}>
                ИК проводят: директор школы {mkIntervals[0]?.director} и руководитель ступени {mkIntervals[0]?.stage} {mkIntervals[0]?.head}
            </p>
            {isStudents &&
                <p style={{ fontSize: "16px" }}>
                    Если желаете провести ИК для всех Ваших детей одновременно, свяжитесь пожайлуста с администратором.
                </p>
            }
            {alreadyMK ?
                <>
                    <p style={{ fontSize: "16px" }}>Вы записаны на ИK {mkIntervals[0]?.name}</p>

                    <Button disabled={mkIntervals[0]?.disabled} onClick={() => removeMK()} className='float-middle'>Отменить запись</Button>
                </>
                :
                <>
                    <p style={{ fontSize: "16px" }}>Запись на ИК студента {student?.name} на следующее дату и время:</p>
                    {noIntervals ? "Нет свободных интервелов для записи" :
                        <Form className="mx-2">
                            <Form.Select onChange={(e) => setSelectedIntervalID(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={selectedIntervalID} aria-label="Default select example">
                                {mkIntervals.map(interval =>
                                    <option key={'interval' + interval.id} value={interval.id}>{interval.name}</option>
                                )}
                            </Form.Select>
                        </Form>
                    }
                    {carers.length > 0 &&
                        <>
                            <p style={{ marginTop: "15px" }}>Уведомить о записи на ИК пользователей:</p>
                            <Checkbox.Group
                                style={{
                                    width: '100%',
                                    marginBottom: '15px',
                                }}
                                //value={roles}
                                onChange={onChangeCarers} >
                                {carers.map(carer =>
                                    <Checkbox value={carer.userID}>{carer.name}</Checkbox>
                                )}
                            </Checkbox.Group>
                        </>
                    }
                    <p style={{ marginTop: "15px" }}>&nbsp;
                        <Button disabled={noIntervals} onClick={() => addMK()} className='float-middle'>Записатся</Button>
                    </p>
                </>
            }
        </Container >
    );
};

export default ParentMeetingMK;