import React, { useContext, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Container } from 'react-bootstrap';
import '../navbar.css'
import MyCalendar from '../Calendar';
import { Card, message } from 'antd';
import { fetchEvents } from '../../http/adminApi';

const EventsCalendar = observer(() => {

    const { admin } = useContext(Context)

    useEffect(() => {
        async function fetchData() {
            const data = await fetchEvents()
            if (Array.isArray(data)) {
                admin.setCalendarEvents(data)
            } else message.error("Получены не корректные данные от сервера")
        }
        fetchData();
    }, [])

    return (
        <Card title='Календарь событий' style={{height: '58vw' }}>
{/*             <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Календарь событий</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </Table>
 */}            <MyCalendar />
        </Card>
    );
});

export default EventsCalendar;