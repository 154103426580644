import React, { useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import AdminClass from './modals/AdminClass';
import { Card } from 'antd';

const AdminClassesTable = observer(() => {
    const [classVisible, setClassVisible] = useState(false)
    const [classToModal, setClassToModal] = useState({})
    const { admin } = useContext(Context)

    function onClickClass(clas) {
        admin.setSelectedClass(clas);
        setClassToModal(clas);
        setClassVisible(true);
    }

    function onClickNewClass() {
        admin.setSelectedClass({});
        setClassToModal({ leader: '', teacher: '', name: '' });
        setClassVisible(true);
    }

    //  console.log("BRA:", admin.selectedBranch.id)

    return (
        <Card>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Название</th>
                        <th>Наставник</th>
                        <th>Педагог</th>
                        <th style={{ verticalAlign: 'bottom' }}>Действия<Button onClick={() => onClickNewClass()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {admin.classes.filter(i => i.branchID == admin.selectedBranch.id).map(clas =>
                        <tr key={clas.id}>
                            <td>{clas.id}</td>
                            <td>{clas.name}</td>
                            <td>{clas.leader.name}</td>
                            <td>{clas.teacher.name}</td>
                            <td><Button onClick={() => onClickClass(clas)}>Редактирование</Button></td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <AdminClass show={classVisible} onHide={() => setClassVisible(false)} clas={classToModal} />
        </Card>
    );
});

export default AdminClassesTable;