import React, { useContext } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Container, Form } from 'react-bootstrap';
import ModulesList from '../teacher/syllabus/ModulesList';
import SchoolBook from '../teacher/syllabus/SchoolBook';
import { Card } from 'antd';

const AdminModulesTableNM = observer(() => {
    const { admin } = useContext(Context)

    const getClassID = () => { 
        if (Object.keys(admin.selectedClass).length != 0) {
            return admin.selectedClass
        } else {
            return admin.classes[0]?.id
        }
    }

    const getSubjectID = () => {
        if (Object.keys(admin.selectedSubject).length != 0) {
            return admin.selectedSubject
        } else {
            return admin.subjects[0]?.id
        }
    }

    return (
        <Card>
            <Form className="mx-2">
                <Form.Select onChange={(e) => admin.setSelectedClass(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={getClassID()} aria-label="Default select example">
                    {admin.classes.map(clas =>
                        <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => admin.setSelectedSubject(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={getSubjectID()} aria-label="Default select example">
                    {admin.subjects.map(subject =>
                        <option key={'subject' + subject.id} value={subject.id}>{subject.name}</option>
                    )}
                </Form.Select>
            </Form>
            <SchoolBook classID={getClassID()} subjectID={getSubjectID()}/>
            <ModulesList classID={getClassID()} subjectID={getSubjectID()}/>
        </Card>
    )
});

export default AdminModulesTableNM;