import React, { useContext, useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { Form, Button, Dropdown, Table, Row, Col } from "react-bootstrap";
import { Context } from '../../..';
import { observer } from "mobx-react-lite";
import { modyfyClass, createClass, fetchResponsible, fetchClassSubjects } from '../../../http/adminApi';
import { Tabs } from 'antd';
import Admin from '../../../pages/Admin';
import AdminClassSubjects from '../AdminClassSubjects';

const AdminClass = observer(({ show, onHide, clas }) => {
  const { admin } = useContext(Context)
  const [leader, setLeader] = useState('')
  const [teacher, setTeacher] = useState('')
  const [name, setName] = useState('')
  const [scheduleGrid, setScheduleGrid] = useState({})
  const [stages, setStages] = useState([])
  const [selectedStage, setSelectedStage] = useState({})
  const [subjects, setSubjects] = useState([])

  //  const [students, setStudents] = useState([])
  const [isNew, setIsNew] = useState(false)

  let index = 1

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    let data = await fetchResponsible()
    setStages(data?.stage)
  }

  function setInputs() {
    setLeader(clas?.leader.name)
    setTeacher(clas?.teacher.name)
    setName(clas?.name)
    admin.setSelectedLeader(clas?.leader)
    admin.setSelectedTeacher(clas?.teacher)

    setScheduleGrid(clas?.scheduleGrid)
    setSelectedStage(clas?.stageID)
    if (clas?.id) {
      setIsNew(false)
      fetchClassSubjects(clas.id).then(subj => setSubjects(subj))
    } else {
      setIsNew(true)
    }
    //   console.log("SH", scheduleGrid);
    //   studentsByClassList(clas?.id).then(data => setStudents(data))
  }

  function newClass() {
    const id = createClass(name, admin.selectedLeader, admin.selectedTeacher, scheduleGrid?.id, selectedStage, subjects).then
    admin.setClasses([...admin.classes, { leader: admin.selectedLeader, teacher: admin.selectedTeacher, name: name, scheduleGrid: scheduleGrid, stageID: selectedStage, id: id }])
  }

  function changeClass() {
    /*       clas.leader = leader
          clas.teacher = teacher */
    const data = modyfyClass(name, admin.selectedLeader, admin.selectedTeacher, scheduleGrid?.id, clas.id, selectedStage, subjects).then

    clas.leader = admin.selectedLeader.name
    clas.teacher = admin.selectedTeacher.name
    clas.name = name
    admin.setSelectedClass(clas)
    admin.setClasses(admin.classes.map(i => i.id === clas.id ? { ...i, id: clas.id, leader: admin.selectedLeader, teacher: admin.selectedTeacher, scheduleGrid: scheduleGrid, stageID: selectedStage, name: clas.name } : i))

  }

  function clickClass() {
    if (!clas?.id) {
      newClass()
    } else {
      changeClass()
    }
    onHide()
  }

  const clickLeader = (leader) => {
    admin.setSelectedLeader(leader);
    setLeader(leader.name)
  }

  const clickTeacher = (teacher) => {
    admin.setSelectedTeacher(teacher);
    setTeacher(teacher.name)
  }

  return (
    <Modal
      onShow={setInputs}
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isNew ? "Добавить класс" : "Изменить данные класса"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {isNew ? "Введите данные нового класса." : "Измените данные класса."}
        </p>
        <Form>
          <Form.Control className="mt-2 mb-2"
            placeholder='Введите название класса'
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <Row className="mb-2" key="teatut">
            <Col md={6}>
              Наставник:
              <Dropdown className="mt-2 mb-2">
                <Dropdown.Toggle>{leader || admin.selectedLeader.name || "Выберите наставника"}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {admin.teachers.map(leader =>
                    <Dropdown.Item
                      onClick={() => clickLeader(leader)}
                      key={leader.id}
                    >
                      {leader.name}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={6}>
              Педагог:
              <Dropdown className="mt-2 mb-2">
                <Dropdown.Toggle>{teacher || admin.selectedTeacher.name || "Выберите педагога"}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {admin.teachers.map(teacher =>
                    <Dropdown.Item
                      onClick={() => clickTeacher(teacher)}
                      key={teacher.id}
                    >
                      {teacher.name}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row className="mb-2" key="shesta">
            <Col md={6}>
              Сетка расписания:
              <Dropdown className="mt-2 mb-2">
                <Dropdown.Toggle>{scheduleGrid?.name || "Выберите сетку расписания"}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {admin.scheduleGrids.map(scheduleGrid =>
                    <Dropdown.Item
                      onClick={() => setScheduleGrid(scheduleGrid)}
                      key={scheduleGrid.id}
                    >
                      {scheduleGrid.name}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={6}>
              Ступень:
              <Dropdown className="mt-2 mb-2">
                <Dropdown.Toggle>{stages.find(i => i.id === selectedStage)?.name || "Выберите ступень"}</Dropdown.Toggle>
                <Dropdown.Menu>
                  {stages.map(stage =>
                    <Dropdown.Item
                      onClick={() => setSelectedStage(stage.id)}
                      key={stage.id}
                    >
                      {stage.name}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

        </Form>

        <Tabs
          type="card"
          items={[
            {
              label: 'Студенты',
              children: <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Имя ученика</th>
                    <th>Логин ученика</th>
                  </tr>
                </thead>
                <tbody>
                  {admin.students.map(student => student?.properties.find(x => x.key == 'Класс')?.value == clas?.id &&
                    <tr key={student.id}>
                      <td>{index++}</td>
                      <td>{student.name}</td>
                      <td>{student.login}</td>
                    </tr>
                  )}
                </tbody>
              </Table>,
              key: '1',
            },
            {
              label: 'Предметы',
              children: <AdminClassSubjects classID={clas.id} subjects={subjects} setSubjects={setSubjects}/>,
              key: '2',
            },
          ]}
        />

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={onHide}>Закрыть</Button>
        <Button variant="outline-success" onClick={clickClass}>
          {isNew ? "Добавить" : "Изменить"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default AdminClass;