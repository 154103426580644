import { AxiosResponse } from "axios";
import { $authHost } from "../http";
import { UsersResponse } from "../models/response/UsersResponse";
import { UserType } from "../models/Users";

export default class UsersService {
    static async getUser(id: number): Promise<UserType> {
        const ret =  $authHost.get<UserType>('api/user/' + id);
        console.log((await ret).data);
        
        return (await ret).data;
    }
}