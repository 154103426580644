import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Dropdown } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone, FormatPainterFilled } from '@ant-design/icons';
import { fetchColors } from '../../../http/adminApi';

const ColorSelectDropdown = ({ route, changeColor }) => {
  const [colors, setColors] = useState([])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    let data = await fetchColors()
    setColors(data.map((i) => { return { ['label']: i.name, ['key']: i.color, ['icon']: <FormatPainterFilled style={{ color: i.color }} />} }))
  //  console.log("COL", data, data2);
  }

  const items = [
    {
      label: 'Зеленый',
      key: 'green',
      icon: <FormatPainterFilled style={{ color: 'green' }} />,
    },
    {
      label: 'Желтый',
      key: 'yellow',
      icon: <FormatPainterFilled style={{ color: 'yellow' }} />,
    },
    {
      label: 'Голубой',
      key: 'blue',
      icon: <FormatPainterFilled style={{ color: 'blue' }} />,
    },
    {
      label: 'Серый',
      key: 'grey',
      icon: <FormatPainterFilled style={{ color: 'grey' }} />,
    },
  ];

  const handleMenuClick = ({ key }) => {
    //  console.log('click', key);
    //  let status = key.split(' ')[0]
    //  let id = key.split(' ')[1]
    //  console.log('att1', status, id);
    changeColor(key, route.id)
    //  route.color = key
  };

  const menuProps = {
    items: colors,
    onClick: handleMenuClick,
  };

  return (
    <Container>
      <Dropdown.Button menu={menuProps}
        placement="bottom"
        key={'dr|' + route.id}
        icon={<FormatPainterFilled style={{ color: route.color }} />}>
      </Dropdown.Button>
    </Container>
  );
};

export default ColorSelectDropdown;