import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container } from 'react-bootstrap';
import { FaMinus, FaPlus, FaArrowDown } from 'react-icons/fa';
import AdminUser from './modals/AdminUserN';
import '../navbar.css'
import { delUser, userList } from '../../http/adminApi';
import { Popconfirm, message } from 'antd';

const AdminUsersTable = observer(({ userRole }) => {
    const [userVisible, setUserVisible] = useState(false)
    const [userToModal, setUserToModal] = useState({})

    const [users, setUsers] = useState([])

    const { admin } = useContext(Context)

    useEffect(() => {
        userList(userRole).then(data => setUsers(data))
        //console.log("!!!", userRole);
    }, [admin.users])

    function openUser(user) {
        admin.setSelectedUser(user);
        setUserToModal(user);
        setUserVisible(true);
    }

    function newUser() {
        admin.setSelectedUser({});
        setUserToModal({ login: '', password: '', name: '', role: userRole, properties: [] });
        setUserVisible(true);
    }

    async function deleteUser(user) {

        admin.setSelectedUser({});
        //console.log("USE", user);
        const data = await delUser(user.id)
        console.log("USE", user, data);
        if (data > 0) {
            message.success(`Удаление ${user.name} успешно!`)
            userList(userRole).then(data => setUsers(data))
        } else {
            message.error(`Удаление ${user.name} не удалось!`)
        }
    }

    const scrollToBottom = () => { 
        window.scrollTo({ 
          top: document.documentElement.scrollHeight, 
          behavior: 'auto'
          /* you can also use 'auto' behaviour 
             in place of 'smooth' */
        }); 
      }; 

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Имя пользователя</th>
                        <th>Логин пользователя</th>
                        <th>Действия</th>
                        <th className="tableaction">
                            <Button onClick={scrollToBottom} className='float-end'><FaArrowDown className="d-flex justify-content-between"/></Button>
                            <Button onClick={() => newUser()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user =>
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.login}</td>
                            <td /* colSpan="2" */><Button onClick={() => openUser(user)}>Редактирование</Button></td>
                            <td>
                                <Popconfirm className='float-end' title={"Вы уверены что хотите удалить пользователя " + user.name + " ?"} onConfirm={() => deleteUser(user)} okText="Да" cancelText="Нет">
                                    <Button className='float-end'>
                                        <FaMinus className="d-flex justify-content-between" />
                                    </Button>
                                </Popconfirm>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <AdminUser show={userVisible} onHide={() => setUserVisible(false)} editUser={userToModal} userRole={userRole} users={users} setUsers={setUsers} />
        </Container>
    );
});

export default AdminUsersTable;