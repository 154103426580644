import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Button, Row, Col } from 'react-bootstrap';
import { fetchTeacherScores, modyfyTeacherScores } from '../../../http/teacherApi';
import { Context } from '../../..';
import { createResponse } from '../../../http/studentApi';
import HTMLViewer from '../HTMLViewer';
import { FaPlus } from 'react-icons/fa';
import Editor from '../quill/Editor';
import { InputNumber/* , Button, message, Upload */ } from 'antd';
import FilesUpload from '../FilesUpload';
import ResponseScore from './ResponseScore';
import ResponseAnswer from './ResponseAnswer';
/* import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { test } from '../../../http/userApi'; */


const ResponseAnswersIterator = observer(({ answers, changeAnswer, responseID, changeScore, saveAnswer, placeholder }) => {

    return (
        <Container>
            {answers.map(answer =>
                <Row style={{'border': '1px solid grey', 'padding':'10px', 'marginTop':'3px'}}>
                    <Col sm={10}>
                        <ResponseAnswer
                            responseID={responseID}
                            changeAnswer={changeAnswer}
                            answer={answer}
                        ></ResponseAnswer>
                    </Col>
                    <Col sm={2}>
                        <ResponseScore
                            responseID={responseID}
                            changeScore={changeScore}
                            answer={answer}
                            saveAnswer={saveAnswer}
                            placeholder={placeholder}
                        ></ResponseScore>
                    </Col>
                </Row>
            )}
        </Container>
    );
});

export default ResponseAnswersIterator;