import React, { useState } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import RadarChart from './RadarChart';
import { Card, Col, Row, Slider } from 'antd';
import StarTips from './StarTips';

const StarEditor = ({ starS, starLayer, changeRayData }) => {

    return (<>
        {Object.keys(starS[starLayer]).map((rayKey, index) =>
            <div key={index}>
                <div>{rayKey}</div>
                <Slider
                    trackStyle={starLayer == 1 ? { backgroundColor: "#75B53E" } : { backgroundColor: "#ffd110" }}
                    // defaultValue={starS[starLayer][rayKey]} 
                    value={starS[starLayer][rayKey]}
                    // tooltip={{ open: true }}
                    max={4}
                    min={1}
                    onChange={(value) => {
                        changeRayData(rayKey, value)
                    }}
                />
            </div>
        )}
    </>
    );
}

export default StarEditor;