import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { createScheduleGrid, fetchScheduleGrids, modyfyScheduleGrid } from '../../../http/adminApi';
import { Typography } from 'antd';
import AdminScheduleGrid from './AdminScheduleGrid';
import AdminScheduleGridItems from './AdminScheduleGridItems';

const { Paragraph } = Typography;

const AdminScheduleGridRef = observer(() => {
    const { admin } = useContext(Context)

    return (
        <Container>
            <AdminScheduleGrid />
            {admin.selectedSubMenu?.id == 121 && <AdminScheduleGridItems />}
            
{/*             {admin.selectedSubMenu?.id == 121 ?
                <AdminScheduleGridItems />
                :
                <AdminScheduleGrid />
            } */}
        </Container>
    );
});

export default AdminScheduleGridRef;