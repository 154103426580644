import { FC, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "..";

const Redirect: FC = () => {
    const { store } = useContext(Context);
    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        console.log('==redirect==================================');
        console.log(store.isAuth, store.role);

        if (!store.role) {
            store.setRole(store.user.role);
        }

        if (!store.isAuth) {
            navigate('/login', { replace: true })
        }

        if (store.isAuth && location.pathname === '/') {
            if (!store.role) {
                navigate('/notfound', { replace: true })
            } else {
                navigate('/' + store.role, { replace: true })
            }
        }


    }, [])

    return <></>
}

export default Redirect;