import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Button } from 'react-bootstrap';
import { fetchTeacherScores, modyfyTeacherScores } from '../../../http/teacherApi';
import { Context } from '../../..';
import { createResponse } from '../../../http/studentApi';
import HTMLViewer from '../HTMLViewer';
import { FaPlus } from 'react-icons/fa';
import Editor from '../quill/Editor';
import { InputNumber/* , Button, message, Upload */ } from 'antd';
import FilesUpload from '../FilesUpload';
/* import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { test } from '../../../http/userApi'; */


const ResponseAnswer = observer(({ answer, changeAnswer, responseID }) => {

    return (
        <Container>
            {answer.editable ?
            <Container key={answer.id} style={{ 'border-width': '1px', 'border-style': 'outset' }}>
                <Editor
                    data={answer.comment}
                    edit={true}
                    funcToSave={(val) => changeAnswer(val, answer.id, responseID)}
                    placeholder='Введите ответ'
                ></Editor>
                <FilesUpload
                    fileList={answer.fileList}
                    action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'answer'}&id=${answer.id}`}
                ></FilesUpload>
            </Container>
            :
            <Container key={answer.id}>
                <HTMLViewer
                    value={answer.comment}
                ></HTMLViewer>
                {Boolean(answer.fileList.length != 0) &&
                    <FilesUpload
                        fileList={answer.fileList}
                        disabled={true}
                    ></FilesUpload>}
            </Container>}
        </Container>
    );
});

export default ResponseAnswer;