import React, { useEffect, useContext, useState } from 'react';
//import Table from 'react-bootstrap/Table';
import { Card, Table, Tooltip } from 'antd';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { Context } from '../../..';
//import moment from 'moment';
//import 'moment/locale/ru';
import { DatePicker } from 'antd';
import { fetchStudentAttendanceStat } from '../../../http/studentApi';
import AttendanceStudentHist from '../../teacher/statistic/AttendanceStudentHist';
import AttendanceStudentStatus from '../../teacher/statistic/AttendanceStudentStatus';
import dayjs from 'dayjs'

const { RangePicker } = DatePicker;

const StudentAttendanceStat = observer((params/* { studentID } */) => {
    const { student } = useContext(Context)
    const { store } = useContext(Context)
    const studentID = (params?.studentID) ? params?.studentID: store?.user?.id

    const dateFormat = 'DD.MM.YYYY';
    const dateFormatForDB = 'YYYY-MM-DD'

    const firstColumn = {
        title: 'Предметы',
        dataIndex: 'subjectName',
        fixed: 'left',
        width: 150,
    }
    const lastColumn = {
        title: 'Сводная гистограмма',
        dataIndex: 'hist',
        fixed: 'right',
        width: 300,
        align: 'center',
    }

/*     {"columns":[{"id":"ID1","date":"15.03.2023","begin":"10:15","end":"11:00"},{"id":"ID2","date":"16.03.2023","begin":"11:15","end":"12:00"},{"id":"ID3","date":"17.03.2023","begin":"11:15","end":"12:00"},{"id":"ID4","date":"18.03.2023","begin":"11:15","end":"12:00"}],"subjects":[{"id":"4324","subjectName":"Математика","ID1":{"status":"red","comment":"коммент 1"},"ID2":{"status":"yellow","comment":""},"hist":{"green":"4","red":"10","yellow":"10","ogange":"0","blue":"0"}},{"id":"4325","subjectName":"Литература","ID1":{"status":"red","comment":"коммент 2"},"ID2":{"status":"yellow","comment":""},"ID4":{"status":"blue","comment":"желтый коммент"},"hist":{"green":"4","red":"10","yellow":"10","ogange":"0","blue":"0"}}]} */

    const [subjectsList, setSubjectsList] = useState([])
    const [columns, setColumns] = useState([firstColumn, lastColumn])

//    const [begin, setBegin] = useState(moment(moment.now() - 604800000))
//    const [end, setEnd] = useState(moment(moment.now()))

    useEffect(() => {
        loadData(student.attBegin, student.attEnd)
    }, [student.attBegin, student.attEnd, studentID])

    const loadData = async (begin, end) => {
        const data = await fetchStudentAttendanceStat(begin.format(dateFormatForDB), end.format(dateFormatForDB), studentID)

        if (!data) return null; // нет данных

        let colData = [firstColumn]
        data.columns.map((col) => {
            colData.push({
                //title: <Tooltip title={col.begin + '-' + col.end} color="grey"><div style={{writingMode:'sideways-lr'}}>{col.date}</div></Tooltip>,
                title: <div style={{writingMode:'sideways-lr'}}>{col.date}</div>,
                dataIndex: col.id,
                width: 30,
            })
        })
        colData.push(lastColumn)
        setColumns(colData)

        setSubjectsList(data.subjects.map(sub => {
            for (var key in sub) {
                if (key != 'id' && key != 'subjectName' && key != 'hist' && key != 'key') {
                    sub[key] = <AttendanceStudentStatus cell={sub[key]} />
                }
            }
            return {
                ...sub,
                ['key']: sub.id,
                ['hist']: <AttendanceStudentHist hist={sub.hist} />
            }
        }))

    }

    const changePeriod = (period) => {
        student.setAttBegin(dayjs(period[0])/* .format(dateFormat) */)
        student.setAttEnd(dayjs(period[1])/* .format(dateFormat) */)
        //setBegin(period[0])
        //setEnd(period[1])
        loadData(period[0],period[1])
    }

    return (
        <Card>
            <RangePicker
                //value={[begin, end]}
                value={[student.attBegin, student.attEnd]}
                format={[dateFormat, dateFormat]}
                allowClear={false}
                onChange={(value) => changePeriod(value)}
            /*  disabledDate={disabledDate}
             onCalendarChange={(val) => setDates(val)}
             onChange={(val) => setValue(val)}
             onOpenChange={onOpenChange} */
            />

            <Table
                style={{ marginTop: '15px' }}
                columns={columns}
                dataSource={subjectsList}
                pagination={false}
                /* pagination={{
                    pageSize: 50,
                }} */
                scroll={{
                    x: 500,
                }}
            />
        </Card>
    );
});

export default StudentAttendanceStat;