import React from 'react';
import { Container } from 'react-bootstrap';
import { Dropdown } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

const AttendanceDropdown = ({attendance, changeStatus}) => {

    const items = [
        {
            label: 'Не явился',
            key: 'red ' + attendance.id,
            icon: <CloseCircleTwoTone twoToneColor="red" />,
        },
        {
            label: 'Опоздал',
            key: 'yellow ' + attendance.id,
            icon: <CheckCircleTwoTone twoToneColor="yellow" />,
        },
        {
            label: 'Ушел',
            key: 'orange ' + attendance.id,
            icon: <CheckCircleTwoTone twoToneColor="orange" />,
        },
        {
            label: 'Явился',
            key: 'green ' + attendance.id,
            icon: <CheckCircleTwoTone twoToneColor="green" />,
        },
        {
            label: 'Дистант',
            key: 'blue ' + attendance.id,
            icon: <CheckCircleTwoTone twoToneColor="blue" />,
        },
    ];

    const handleMenuClick = ({ key }) => {
      //  console.log('click', key);
        let status = key.split(' ')[0]
        let id = key.split(' ')[1]
      //  console.log('att1', status, id);
        changeStatus(status, id)
        attendance.status = status
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <Container>
            {!attendance.status && <Dropdown.Button menu={menuProps}
                placement="bottom"
                key={'dr|' + attendance.id}
                icon={<CheckCircleTwoTone />}>
                Выбрать
            </Dropdown.Button>}
            {attendance.status == 'red' && <Dropdown.Button menu={menuProps}
                placement="bottom"
                key={'dr|' + attendance.id}
                icon={<CloseCircleTwoTone twoToneColor="red" />}>
                Не явился
            </Dropdown.Button>}
            {attendance.status == 'yellow' && <Dropdown.Button menu={menuProps}
                placement="bottom"
                key={'dr|' + attendance.id}
                icon={<CheckCircleTwoTone twoToneColor="yellow" />}>
                Опоздал
            </Dropdown.Button>}
            {attendance.status == 'orange' && <Dropdown.Button menu={menuProps}
                placement="bottom"
                key={'dr|' + attendance.id}
                icon={<CheckCircleTwoTone twoToneColor="orange" />}>
                Ушел
            </Dropdown.Button>}
            {attendance.status == 'green' && <Dropdown.Button menu={menuProps}
                placement="bottom"
                key={'dr|' + attendance.id}
                icon={<CheckCircleTwoTone twoToneColor="green" />}>
                Явился
            </Dropdown.Button>}
            {attendance.status == 'blue' && <Dropdown.Button menu={menuProps}
                placement="bottom"
                key={'dr|' + attendance.id}
                icon={<CheckCircleTwoTone twoToneColor="blue" />}>
                Дистант
            </Dropdown.Button>}
        </Container>
    );
};

export default AttendanceDropdown;