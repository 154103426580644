import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Button, Col, Row } from 'react-bootstrap';
import { fetchTeacherScores, modyfyTeacherScores } from '../../../http/teacherApi';
import { Context } from '../../..';
import { createResponse } from '../../../http/studentApi';
import HTMLViewer from '../../utilites/HTMLViewer';
import { FaPlus } from 'react-icons/fa';
import Editor from '../../utilites/quill/EditorBlur';
import {
    InputNumber,/* , Button, message, Upload */
    message
} from 'antd';
import FilesUpload from '../../utilites/FilesUpload';
import TeacherCommentEditable from './TeacherCommentEditable';
import TeacherCommentNotEditable from './TeacherCommentNotEditable';
import ResponseAnswers from '../../utilites/classResponse/ResponseAnswer';
import ResponseScores from '../../utilites/classResponse/ResponseScore';
import ResponseAnswersIterator from '../../utilites/classResponse/ResponseAnswersIterator';
/* import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { test } from '../../../http/userApi'; */


const TeacherSetScoreNL = observer(() => {
    const { teacher } = useContext(Context)
    const { user } = useContext(Context)

    useEffect(() => {
        loadData()
    }, [teacher.setSelectedSubMenu, teacher.selectedSideMenu])



    const loadData = async () => {
        const data = await fetchTeacherScores(teacher.selectedDescription.id, teacher.selectedStudent.id)
        teacher.setTeacherScores(data)
    }

    const changeScore = (score, answerID, responseID) => {
        //const data = await modyfyTeacherScores(scoreID, teacherScore)
        //  console.log("Cha",score, answerID, responseID);
        //  let response = teacher.teacherScores.find(i => i.id === responseID)
        //   let answer = response.answers.find(j => j.id === answerID)
        // console.log("Cha1", answer);
        //   answer.score = score
        /*         teacher.setTeacherScores(
                    teacher.teacherScores.map(i => i.id === responseID ?
                        i.answers.map(j => j.id === answerID ?
                            { ...j, ['score']: score }
                            : j)
                        : i)
                ) */
        // console.log("Cha1",teacher.teacherScores);
        teacher.setTeacherScores(
            teacher.teacherScores.map(i => i.id === responseID ?
                {
                    ...i, ['answers']: i.answers.map(j => j.id === answerID ?
                        { ...j, ['score']: score }
                        : j)
                }
                : i)
        )
        //teacher.setTeacherScores(teacher.teacherScores.map(i => i.id === responseID ? i.answers.map(j => j.id === answerID ? { ...j, ['score']: score } : j) : i))
        // console.log("Cha2",teacher.teacherScores);
    }

    const changeComment = (comment, answerID, responseID) => {
        //const data = await modyfyTeacherScores(scoreID, null, answer)
        //  let response = teacher.teacherScores.find(i => i.id === responseID)
        //   let answer = response.answers.find(j => j.id === answerID)
        //  answer.comment = comment
        teacher.setTeacherScores(
            teacher.teacherScores.map(i => i.id === responseID ?
                {
                    ...i, ['answers']: i.answers.map(j => j.id === answerID ?
                        { ...j, ['comment']: comment }
                        : j)
                }
                : i)
        )
        // teacher.setTeacherScores(teacher.teacherScores.map(i => i.id === responseID ? i.answers.map(j => j.id === answerID ? { ...j, ['comment']: comment } : j) : i))
    }

    const saveAnswer = async (answerID, responseID) => {
        console.log("SAV", answerID, responseID);
        let response = teacher.teacherScores.find(i => i.id === responseID)
        let answer = response.answers.find(j => j.id === answerID)
        //console.log("SCORE", score);
        const data = await modyfyTeacherScores(answer.id, answer.score, answer.comment)
        if (data) {
            message.success(`Сохранение успешно!`);
        } else {
            message.error(`Сохранение не удалось...`);
        }
    }

    return (
        <Container>
            <div style={{'marginBottom':'15px'}}>Ученик {teacher.selectedStudent.name}</div>
            <div>
                <HTMLViewer 
                    style={{'border': '1px solid grey', 'padding':'10px'}}
                    value={teacher.selectedDescription.text}
                ></HTMLViewer>
            </div>
            <Container>
                <Row>
                    <Col my-5 sm={10}><b>Ответ</b></Col>
                    <Col my-5 sm={2}><b>Оценка</b></Col>
                </Row>
            </Container>
            <hr></hr>
            {teacher.teacherScores.map(score =>
                <ResponseAnswersIterator
                    responseID={score.id}
                    changeAnswer={changeComment}
                    answers={score.answers}
                    changeScore={changeScore}
                    saveAnswer={saveAnswer}
                    placeholder='Оценка'
                ></ResponseAnswersIterator>
            )}
        </Container>
    );
});

export default TeacherSetScoreNL;