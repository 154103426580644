import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Button } from 'react-bootstrap';
import { fetchTeacherScores, modyfyTeacherScores } from '../../../http/teacherApi';
import { Context } from '../../..';
import { createResponse } from '../../../http/studentApi';
import HTMLViewer from '../../utilites/HTMLViewer';
import { FaPlus } from 'react-icons/fa';
import Editor from '../../utilites/quill/EditorBlur';
import { InputNumber/* , Button, message, Upload */ } from 'antd';
import FilesUpload from '../../utilites/FilesUpload';
/* import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { test } from '../../../http/userApi'; */


const TeacherCommentEditable = observer((score) => {

    return (
        <Container>
            <HTMLViewer
                value={score.answer}
            ></HTMLViewer>
            {Boolean(score.fileListStudent.length != 0) &&
                <FilesUpload
                    fileList={score.fileListStudent}
                    disabled={true}
                //  action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'responses'}&id=${score.id}`}
                >
                </FilesUpload>
            }
            {Boolean(score.answer) && <hr />}
            <Editor
                data={score.comment}
                edit={true}
                /* idToSave={score.id} */
                funcToSave={(val) => score.changeAnswer(val, score.id)}
                placeholder='Введите ответ'
            ></Editor>
            <FilesUpload
                fileList={score.fileListTeacher}
                action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'responses'}&id=${score.id}`}
            >
            </FilesUpload>
        </Container>
    );
});

export default TeacherCommentEditable;