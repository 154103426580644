import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container, Button } from 'react-bootstrap';
import { fetchTeacherScores, modyfyTeacherScores } from '../../../http/teacherApi';
import { Context } from '../../..';
import { createResponse } from '../../../http/studentApi';
import HTMLViewer from '../HTMLViewer';
import { FaPlus } from 'react-icons/fa';
import Editor from '../quill/EditorBlur';
import { InputNumber/* , Button, message, Upload */ } from 'antd';
import FilesUpload from '../FilesUpload';
/* import 'antd/dist/antd.css';
import { UploadOutlined } from '@ant-design/icons';
import { test } from '../../../http/userApi'; */


const ResponseScore = observer(({ answer, changeScore, saveAnswer, responseID, placeholder }) => {

    return (
        <Container>
            {answer.editable ?
            <Container>
                <InputNumber
                    min={0}
                    max={100}
                    placeholder={placeholder}
                    value={answer.score}
                    onChange={val => changeScore(val, answer.id, responseID)}
                /* onChange={val => teacher.setTeacherScores(teacher.teacherScores.map(i => i.id === responseID ? i.answers.map(j => j.id === answer.id ? { ...j, ['score']: val } : j ) : i ))}
                onBlur={e => changeScore(e.target.value, answer.id, responseID)} */
                ></InputNumber>
                <Button
                    style={{ 'marginTop': '15px' }}
                    onClick={() => saveAnswer(answer.id, responseID)}
                >
                    Сохранить
                </Button>
            </Container>
            :
            Boolean(answer.score) && answer.score}
        </Container>
    );
});

export default ResponseScore;