import React, { useContext, useRef, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container } from 'react-bootstrap';
import { FaPlus, FaPrint } from 'react-icons/fa';
import '../navbar.css'
import MyCalendar from '../Calendar';
import { Card } from 'antd';
import { useReactToPrint } from 'react-to-print';

const AdminEventsCalendar = observer(() => {
    const [eventVisible, setEventVisible] = useState(false)
    const [eventToModal, setEventToModal] = useState({})
    //    const [, forceUpdate] = useState(0);
    const { admin } = useContext(Context)

    // TODO загрузка событий 
    /*     useEffect(() => {
            userList(userRole).then(data => admin.setUsers(data))
        }, []) */

    function onClickEvent(event) {
        /*         admin.setSelected(user);
                setUserToModal(user);
                setUserVisible(true); */
    }

    function onClickNewEvent() {
        //     admin.setSelectedUser({});
        setEventToModal({ login: '', password: '', name: '' });
        setEventVisible(true);
    }

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Card title='Календарь событий' extra={<button onClick={handlePrint}><FaPrint /></button>}>
            <Table striped bordered hover>
                {/*                 <thead>
                    <tr>
                        <th>Календарь событий</th>
 */}                        {/* <th className="tableaction"><Button onClick={() => onClickNewEvent()} className='float-end'><FaPlus className="d-flex justify-content-between"/></Button></th> */}
                {/*                     </tr>
                </thead>
 */}                <tbody>
                </tbody>
            </Table>
            <div ref={componentRef}>
                <MyCalendar />
            </div>
            {/* <AdminEvent show={eventVisible} onHide={() => setEventVisible(false)} event={eventToModal}/> */}
        </Card>
    );
});

export default AdminEventsCalendar;