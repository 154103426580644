import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createCalendarEventType, deleteCalendarEventType, fetchCalendarEventTypes, modyfyCalendarEventType } from '../../../http/adminApi';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Checkbox } from 'antd';

const AdminRefCalendarEventTypes = observer(() => {
    const [eventTypes, setEventTypes] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        let data = await fetchCalendarEventTypes()
        setEventTypes(data)

    }

    //const types = [{name: 'типа тип1', color: '#c6eebf', id: 1, isWeek: false, isParent: false},{name: 'род соб', color: '#c6eebf', id: 2, isWeek: false, isParent: true}]

    const addEventType = async () => {
        const id = await createCalendarEventType()
        setEventTypes([...eventTypes, { name: '', color: 'black', id: id, isWeek: false, isParent: false }])
    }

    const removeEventType = async (id) => {
        let data = await deleteCalendarEventType(id)
        setEventTypes(eventTypes.filter(i => i.id !== id))
    }

    const changeEventTypeName = async (name, id) => {
        let data = await modyfyCalendarEventType(name, null, id, null, null)
        setEventTypes(eventTypes.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }

    const changeEventTypeColor = async (color, id) => {
        // console.log("DSDF",color,id);
        let data = await modyfyCalendarEventType(null, color, id, null, null)
        setEventTypes(eventTypes.map(i => i.id === id ? { ...i, ['color']: color } : i))
    }

    const changeEventTypeIsWeek = async (isWeek, id) => {
        let data = await modyfyCalendarEventType(null, null, id, isWeek, null)
        setEventTypes(eventTypes.map(i => i.id === id ? { ...i, ['isWeek']: isWeek } : i))
    }

    const changeEventTypeIsParent = async (isParent, id) => {
        let data = await modyfyCalendarEventType(null, null, id, null, isParent)
        setEventTypes(eventTypes.map(i => i.id === id ? { ...i, ['isParent']: isParent } : i))
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Типы событий календаря</th>
                        <th></th>
                        <th>Фоновое?</th>
                        <th>Родительское?</th>
                        <th><Button onClick={() => addEventType()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {eventTypes.map(eventType =>
                        <tr key={eventType.id}>
                            <td>
                                {eventType.isSystem ? eventType.name :
                                    <Paragraph
                                        editable={{
                                            tooltip: 'Название типа события',
                                            onChange: (value) => changeEventTypeName(value, eventType.id),
                                            triggerType: ['icon', 'text'],
                                        }}
                                    >
                                        {eventType.name}
                                    </Paragraph>
                                }
                            </td>
                            <td>
                                <FormControl
                                    placeholder='Выберете цвет заливки в календаре'
                                    defaultValue={eventType.color}
                                    onChange={(e) => changeEventTypeColor(e.target.value, eventType.id)}
                                    type="color"
                                />
                            </td>
                            <td><Checkbox
                                onChange={(e) => changeEventTypeIsWeek(e.target.checked, eventType.id)}
                                defaultChecked={eventType.isWeek}
                                disabled={eventType.isSystem}
                            >
                                {eventType.isWeek ? 'Да' : 'Нет'}
                            </Checkbox></td>
                            <td><Checkbox
                                onChange={(e) => changeEventTypeIsParent(e.target.checked, eventType.id)}
                                defaultChecked={eventType.isParent}
                                disabled={eventType.isSystem}
                            >
                                {eventType.isParent ? 'Да' : 'Нет'}
                            </Checkbox></td>
                            <td>
                                {!eventType.isSystem &&
                                    <Button onClick={() => removeEventType(eventType.id)} className='float-end'>
                                        <FaMinus className="d-flex justify-content-between" />
                                    </Button>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminRefCalendarEventTypes;