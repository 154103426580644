import './App.css';
import { FC, useContext } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
// import AppRouter, { router } from './components/AppRouter';
import NavBar from './components/NavBar';
import { observer } from "mobx-react-lite";
//import { userList } from './http/adminApi';
//import { check } from './http/userApi';
import { Layout, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Favicon from "react-favicon";
import favicon from './img/favicon.ico';
import { Context } from '.';
import { CircleLoader } from 'react-spinners'


//import { IUser } from './models/IUser';
import { store } from './index';
/*
export type StoreData = {
  store: Store;
};
 */

//{/* <RequireAuth><Redirect /></RequireAuth> */}
const App: FC = () => {
  const { store } = useContext(Context)
  //const { isAuth } = useLoaderData() as any;
  //console.log("App FC", isAuth);

  //  const { store } = useLoaderData() as StoreData;
  //const [user, setUser] = useState<IUser>();

  /* const data = useLoaderData();
  if (data) {
    const { role }: any = data;
  } */

  if (store.isLoading) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: window.innerHeight - 54 }} >
      <CircleLoader color='#a4ca0c'/>
    </div>
  )

  return (
    <Layout style={{ minHeight: '100vh'/* '100dvh' */ }}>
      {/* <div className="App"> */}
      {/*       <header className="App-header">
      </header>
 */}
      <Favicon url={favicon} />
      <NavBar />

      <Layout>{/* 
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} width={260} style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          // background: colorBgContainer
        }}>
                       <MainMenu /> 
                      <div
              id="detail"
              className={
                navigation.state === 'loading'
                  ? 'loading'
                  : ''
              }
            >---</div> 


        </Sider> */}
        <Layout className="site-layout" /* style={{ marginLeft: 240, padding: '0 14px 14px' }} */>
          {/*           <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb> */}
          <Content
            style={{
              padding: 14,
              margin: 0,
              minHeight: 300,
              /* background: colorBgContainer, */
            }}
          >
            <div id="detail">
              {/* <MyMenu options={options}/><MenuItem/><MyMenu/> */}
              <Outlet />
            </div>

          </Content>
        </Layout>
      </Layout>

      {/* </div> */}
    </Layout >
  );
};


export const rootLoader = async ({ request, params }: any) => {
  //console.log('root loader');

  //const isAuth = await store.checkAuth()
  //console.log("->", isAuth);

  //return { isAuth };
  return null
}

export default observer(App)
