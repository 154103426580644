import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { Button, FormControl, Dropdown, Container } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { BiDotsHorizontal } from 'react-icons/bi';
import { createTheme, deleteTheme, fetchThemes, modyfyTheme, oneModule } from '../../../http/teacherApi';
import { fetchWorkTypes } from "../../../http/adminApi";
import { message, Popconfirm, Typography } from 'antd';
import { Context } from '../../..';

const { Paragraph } = Typography;

const ThemesTable = observer(() => {
  const { teacher } = useContext(Context)
  const { admin } = useContext(Context)
  const [workTypes, setWorkTypes] = useState([])

  useEffect(() => {
    fetchWorkTypes().then(data => setWorkTypes(data))
  }, [])

  useEffect(() => {
    loadData()
    //  oneModule(moduleID).then(data => setModule(data))
  }, [teacher.selectedModule])

  const loadData = async () => {
    const data = await fetchThemes(teacher.selectedModule.id)
    teacher.setTeacherThemes(data)
  }

  const addTheme = async () => {
    const id = await createTheme(teacher.selectedModule.id)
    teacher.setTeacherThemes([...teacher.teacherThemes, { name: '', id: id }])
  }

  const changeIndex = (index, id) => {
    changeTheme(index, null, null, id, null)
    teacher.setTeacherThemes(teacher.teacherThemes.map(i => i.id === id ? { ...i, ['index']: index } : i))
  }

  const changeName = (name, id) => {
    changeTheme(null, name, null, id, null)
    teacher.setTeacherThemes(teacher.teacherThemes.map(i => i.id === id ? { ...i, ['name']: name } : i))
  }

  const changeWorkType = (workTypeId, id) => {
    changeTheme(null, null, workTypeId, id, null)
    teacher.setTeacherThemes(teacher.teacherThemes.map(i => i.id === id ? { ...i, ['workTypeId']: workTypeId } : i))
  }

  const changeDate = (end, id) => {
    changeTheme(null, null, null, id, end)
    teacher.setTeacherThemes(teacher.teacherThemes.map(i => i.id === id ? { ...i, ['end']: end } : i))
  }

  const changeTheme = async (index, name, workType, id, end) => {
    const data = await modyfyTheme(id, index, name, workType, end)
  }

  const delTheme = async (theme) => {
    //console.log("MOD", theme);
    const data = await deleteTheme(theme.id)
    if (data > 0) {
        message.success("Удаление успешно!")
        loadData()
    } else {
        message.error("Удаление не удалось!")
    }
}

  const openRoutes = (theme) => {
    teacher.setSelectedTheme(theme)
    teacher.setSelectedSubMenu({ id: 62 })
    admin.setSelectedSubMenu({ id: 142 })
  }

  return (
    <Container>
      <div>
        Разделы для модуля #{teacher.selectedModule.index} ({teacher.selectedModule.name})
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Разделы</th>
            <th>Тип контроля</th>
            <th>Дата завершения</th>
            <th></th>
            <th><Button onClick={() => addTheme()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
          </tr>
        </thead>
        <tbody>
          {teacher.teacherThemes.map(theme =>
            <tr key={theme.id}>
              <td>
                {<Paragraph
                  editable={{
                    tooltip: 'Номер',
                    onChange: (value) => changeIndex(value, theme.id),
                    triggerType: ['icon', 'text'],
                  }}
                >
                  {theme.index}
                </Paragraph>}
                {/*          <FormControl
                    placeholder='Номер'
                    value={theme.index}
                    onChange={(e) => changeIndex(e.target.value, theme.id)}
                  /> */}
              </td>
              <td>
                <Paragraph
                  editable={{
                    tooltip: 'Введите название',
                    onChange: (value) => changeName(value, theme.id),
                    triggerType: ['icon', 'text'],
                  }}
                >
                  {theme.name}
                </Paragraph>
                {/*         <FormControl
                    placeholder='Введите название'
                    value={theme.name}
                    onChange={(e) => changeName(e.target.value, theme.id)}
                  /> */}
              </td>
              <td>
                <Dropdown className="mt-2 mb-2">
                  <Dropdown.Toggle>{workTypes.filter(workType => workType.id == theme.workTypeId)[0]?.name || "Выберите тип контроля"}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {workTypes.map(workType =>
                      <Dropdown.Item
                        onClick={() => changeWorkType(workType.id, theme.id)}
                        key={"dr" + workType.id + theme.id}
                      >
                        {workType.name}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
              <td>
                <FormControl
                  placeholder='Введите дату'
                  value={theme?.end?.split('T')[0]}
                  onChange={(e) => changeDate(e.target.value, theme.id)}
                  type='date'
                />
              </td>
              <td><Button onClick={() => openRoutes(theme)}><BiDotsHorizontal className="d-flex justify-content-between" /></Button></td>
              <td>
                <Popconfirm
                  title={'Вы уверены что хотите удалить ' + theme.name + '?'}
                  onConfirm={() => delTheme(theme)}
                  onCancel={() => message.warning('Удаление отменено')}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button>
                    <FaMinus className="d-flex justify-content-between" />
                  </Button>
                </Popconfirm>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
});

export default ThemesTable;