import React, { useContext, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container, Form, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createLessonType, modyfyLessonType, deleteLessonType, fetchClasses, fetchSchedule, modyfySchedule } from '../../http/adminApi';
import { fetchTeacherSchedule, fetchTeacherScheduleNew } from '../../http/teacherApi';
import HTMLViewer from '../utilites/HTMLViewer';
import { Table, Tag, Space } from 'antd';

//Расписание уроков
const TeacherShedule = observer(() => {
    const [schedule, setSchedule] = useState([])
    //    const [columns, setColumns] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const data = await fetchTeacherScheduleNew()
        setSchedule(data)
    }

    const columns = [
        {
            title: 'Время',
            dataIndex: 'interval',
            key: 'interval',
        },
        {
            title: 'Понедельник',
            dataIndex: 'day1',
            key: 'day1',
            onCell: (text, record) => {
                if (text['day1']?.color) {
                    return {
                        ['style']: { backgroundColor: 'hsl(47, 100%, 95%)' },
                    };
                }
            },
            render: (text) => <><b>{text?.subject[0]}</b><b>{text?.subject[1]}</b><div>{text?.class[0]}</div><div>{text?.class[1]}</div><div>{text?.class[2]}</div></>,
        },
        {
            title: 'Вторник',
            dataIndex: 'day2',
            key: 'day2',
            onCell: (text, record) => {
                if (text['day2']?.color) {
                    return {
                        ['style']: { backgroundColor: 'hsl(47, 100%, 95%)' },
                    };
                }
            },
            render: (text) => <><b>{text?.subject[0]}</b><b>{text?.subject[1]}</b><div>{text?.class[0]}</div><div>{text?.class[1]}</div><div>{text?.class[2]}</div></>,
        },
        {
            title: 'Среда',
            dataIndex: 'day3',
            key: 'day3',
            onCell: (text, record) => {
                if (text['day3']?.color) {
                    return {
                        ['style']: { backgroundColor: 'hsl(47, 100%, 95%)' },
                    };
                }
            },
            render: (text) => <><b>{text?.subject[0]}</b><b>{text?.subject[1]}</b><div>{text?.class[0]}</div><div>{text?.class[1]}</div><div>{text?.class[2]}</div></>,
        },
        {
            title: 'Четверг',
            dataIndex: 'day4',
            key: 'day4',
            onCell: (text, record) => {
                if (text['day4']?.color) {
                    return {
                        ['style']: { backgroundColor: 'hsl(47, 100%, 95%)' },
                    };
                }
            },
            render: (text) => <><b>{text?.subject[0]}</b><b>{text?.subject[1]}</b><div>{text?.class[0]}</div><div>{text?.class[1]}</div><div>{text?.class[2]}</div></>,
        },
        {
            title: 'Пятница',
            dataIndex: 'day5',
            key: 'day5',
            onCell: (text, record) => {
                if (text['day5']?.color) {
                    return {
                        ['style']: { backgroundColor: 'hsl(47, 100%, 95%)' },
                    };
                }
            },
            render: (text) => <><b>{text?.subject[0]}</b><b>{text?.subject[1]}</b><div>{text?.class[0]}</div><div>{text?.class[1]}</div><div>{text?.class[2]}</div></>,
        },

    ];
    const data = [
        {
            key: '1',
            interval: '9:00 - 10:00',
            day1: { subject: 'Математика', class: ['2 класс', '3 класс'], color: true },
            day2: { subject: 'Математика', class: ['2 класс', '3 класс'], color: true },
            day3: { subject: 'Математика', class: ['2 класс', '3 класс'], color: true },
            day4: { subject: 'Математика', class: ['2 класс', '3 класс'], color: true },
            day5: { subject: 'Математика', class: ['2 класс', '3 класс'], color: true },
        },
        {
            key: '2',
            interval: '11:00 - 12:00',
            day1: { subject: 'Математика', class: ['2 класс', '3 класс'] },
            day2: { subject: 'Математика', class: ['2 класс', '3 класс'], color: true },
            day3: { subject: 'Математика', class: ['2 класс', '3 класс'], color: false },
            day4: { subject: 'Математика', class: ['2 класс', '3 класс'], color: false },
            day5: { subject: 'Математика', class: ['2 класс', '3 класс'], color: false },
        },
        {
            key: '3',
            interval: '13:00 - 14:00',
            day1: { subject: 'Математика', class: ['2 класс', '3 класс'] },
            day2: { subject: 'Математика', class: ['2 класс', '3 класс'], color: true },
            day3: { subject: 'Математика', class: ['2 класс', '3 класс'], color: false },
            day4: { subject: 'Математика', class: ['2 класс', '3 класс'], color: false },
            day5: { subject: 'Математика', class: ['2 класс', '3 класс'], color: false },
        },
    ];


    return (
        <Container>
            <Table
                columns={columns}
                dataSource={schedule}
                pagination={false}
            />
        </Container>
    );
});

export default TeacherShedule;