import { $authHost, $host }  from "../http";
import { AxiosResponse } from "axios";
import { AuthResponse } from "../models/response/AuthResponse";

export default class AuthService {
    static async login(login:string, password:string): Promise<AxiosResponse<AuthResponse>> {
        return  $authHost.post<AuthResponse>('api/user/login', {login, password})
    }

    static async logout():Promise<void>{
        return  $authHost.get('api/user/logout')
    }

    static async refresh(): Promise<AxiosResponse<AuthResponse>> {
        return $host.get<AuthResponse>('api/user/refresh');
//        return $authHost.get<AuthResponse>('api/user/refresh');
    }
}