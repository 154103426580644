import React, { useEffect, useMemo, useState } from 'react'
import { useTable } from 'react-table'
//import './BasicTable.css'


//import MOCK_DATA from './MOCK_DATA.json'
//import {COLUMNS} from './Columns'

const styleModules = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleThemes = { background: '#92d050', padding: '3px', border: 'solid 1px gray' };
const styleRoutes = { background: '#bfbfbf', padding: '3px', border: 'solid 1px gray' };


const MOCK_DATA = [
  {
    id: 'id',
    name: 'name',
    skill: 'skill',
    description_1988: 'test'
  }
]

const COLUMNS = [{
  Header: ' ',
  columns: [{
    Header: 'N п/п',
    accessor: 'id'
  }, {
    Header: 'ФИ студента',
    accessor: 'name',
    style: styleModules,
  }]
}, {
  Header: 'Предмет:',
  style: { background: '#bfbfbf', padding: '4px', border: 'solid 1px gray' },
  columns: [{
    Header: 'Модуль',
    style: styleModules,
    columns: [{
      Header: 'Темы',
      style: styleThemes,
      columns: [{
        Header: 'Умения',
        style: styleRoutes,
        columns: [{
          Header: 'Уровень',
          style: styleRoutes,
          /* columns: [{
            Header: 'Оценивание'
          }] */
        }]
      }]
    }]
  }]
},
]





//export type DensityState = 'sm' | 'md' | 'lg'

const BasicTable = ({ silabus, data }) => {

  const [validationErrors, setValidationErrors] = React.useState({});

  const validateRowData = (rowData) => {
    const errors = {};
   /*  if (!rowData.firstName) {
      errors.firstName = 'First name is required';
    } */
    // Add more validation checks as needed
    return errors;
  };

  const handleCancelClick = (rowId, updatedRowData) => {
    const errors = validateRowData(updatedRowData);
    if (Object.keys(errors).length === 0) {
      setEditRowId(null); // Exit editing mode
      // Proceed to save data (e.g., API call)
    } else {
      setValidationErrors({ ...validationErrors, [rowId]: errors });
    }
  };

  const handleSaveClick = async (rowId, updatedRowData) => {
    console.log("save row data",rowId, updatedRowData );
    
    const errors = validateRowData(updatedRowData);
    if (Object.keys(errors).length === 0) {
      try {
        // Make an API call to save the updated data
        await saveUpdatedRowData(rowId, updatedRowData);
        // Update local state with the updated row data
        setData((prevData) =>
          prevData.map((row) => (row.id === rowId ? updatedRowData : row))
        );
        setEditRowId(null); // Exit editing mode
        setValidationErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[rowId];
          return newErrors;
        });
      } catch (error) {
        // Handle any errors from the API call
      }
    } else {
      setValidationErrors({ ...validationErrors, [rowId]: errors });
    }
  };

  const CustomInputComponent = ({ value, onChange }) => {
    const [inputValue, setInputValue] = React.useState(value);

    const handleInputChange = (e) => {
      console.log("change", e.target.value);
      
      setInputValue(e.target.value);
      onChange(e.target.value);
    };

    return (
      <input /* style={{width:'30px', border:'1px red'}} */ style={{width:'100%', textAlign: 'center', border: '0px solid #ff8c00', background:'#ffd110'}}
        value={inputValue}
        onChange={handleInputChange}
      // Additional props and styling
      />
    );
  };


  //  const columns = useMemo(() => [...COLUMNS, { Header: 'История / 7 класс', columns: silabus, style: { background: '#ffffff', padding: '4px', border: 'solid 1px gray' }, }  ], [])
  const columns = useMemo(() => silabus, [])


  //const tableInstance = useTable({ columns: [{ Header: "empty" }], data: [{}] });

  //columns.push({ Header: 'История', columns: silabus });
  //console.log(columns);
  const tableInstance = useTable({
    columns,
    data,
    /* state: {
      density, //passing the density state to the table, TS is still happy :)
    }, */
  })

  const [editRowId, setEditRowId] = React.useState(null);
  const [editColumnId, setEditColumnId] = React.useState(null);

  const handleEditClick = (rowId) => {
    console.log("edit click", rowId);
    
    setEditRowId(rowId.row);
    setEditColumnId(rowId.column);
  };

  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = tableInstance

  //console.log("rows->",rows);

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr key={Math.random()} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps([{
                  style: column.style
                }])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.id === 'level') {

                } else 
                return <td {...cell.getCellProps()} style={{ border: '1px solid gray' }} /* onClick={() => { console.log(cell.column.id, cell.row.original) }} */ { ...(cell.column.id === 'name') ? ({colSpan:2}) : ('')}>
                  {
                  //console.log("-->", cell)
                  }
                  {(cell.column.editable/* editRowId === row.id *//* cell.column.id === editColumnId && cell.row.original.id === editRowId */) ? (
                    /* Render custom input field for editing */
                    <>
                      <CustomInputComponent
                        value={cell.value}
                        onChange={(newValue) => {
                          // Logic to update local state with the new value
                        }}
                      />
                      {/* <button onClick={() => handleSaveClick(row.id, row.values)}>Save</button>
                      <button onClick={() => handleCancelClick(row.id)}>Cancel</button> */}
                    </>
                  ) : (
                    /* Render static text */
                    <div onClick={() => handleEditClick(/* row.id */{column:cell.column.id, row:cell.row.original.id})}>
                      {cell.render('Cell')}
                      {/* <button onClick={() => handleEditClick(row.id)}>Edit</button> */}
                    </div>
                  )
                  }
                </td>
              })}
            </tr>
          )
        })
        }
      </tbody>
    </table>
  )
}

export default BasicTable
