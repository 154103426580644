import { useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { fetchClassSubjects, modyfyClassSubjects } from '../../../http/adminApi';
import { Card, Col, Row, message } from 'antd';
import { FaBan, FaEdit, FaPlus, FaSave } from 'react-icons/fa';


import { Button, Form } from 'react-bootstrap';
import { Context } from '../../..';
import MyCheckbox from '../../MyCheckbox';


const AdminRefSubjectStar = observer(() => {
    const [subjects, setSubjects] = useState<any>([]);
    const [classID, setClassID] = useState<string>('0');
    const { admin } = useContext(Context);

    const subjectsLoader = async () => {

        const cs = await fetchClassSubjects(classID);
        setSubjects(cs);
        console.log("subject loader refs", cs);
        /*         for (const item in cs) {
                    console.log(item);
        
                }  */
    }

    useEffect(() => {
        //console.log("selected class changed");
        subjectsLoader();

    }, [classID]);

    const save = async () => {
        const data = { classID, subjects }
        try {
            const ret = await modyfyClassSubjects(data);
            if (ret) {
                message.success("успешно сохранено");
            } else {
                message.error("ошибка сохранения");

            }
        } catch {
            message.error("ошибка сохранения");

        }
        //        console.log(subjects);

    }


    const onChange = (e: any) => {
        const id = e.target.id;
        const star = e.target.checked;

        //console.log(e.target.checked, e.target.id);
        //message.success("сохранено");

        const newData = [...subjects];
        const index = newData.findIndex((item: any) => id == item.id);
        //console.log("index", index);
        if (index > -1) {
            const item = newData[index];
            //console.log("item", item);

            newData.splice(index, 1, {
                ...item,
                ...{ star }
            });
        } else {
            //            newData.push(editingItem);
            //            setData(newData);
        }
        setSubjects(newData);
        //        console.log(newData);





    }
    return (
        <>
            <Card>

                <Row>
                    <Col span={24}>
                        <Form.Select onChange={(e) => setClassID(e.target.value)}>
                            {admin.classes.map(clas =>
                                <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                            )}
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <ul style={{ listStyleType: 'none' }}>
                            {subjects.map((item: any) => (
                                <li key={item?.id + '-' + classID}>
                                    <MyCheckbox id={item?.id} labelTxt={item?.name} onChange={onChange}  checked={(item?.star) ? true : false} />
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={save}>Сохранить</Button>
                    </Col>
                </Row>
            </Card>
        </>
    );
});

export default AdminRefSubjectStar;