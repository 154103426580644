import React, { useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container } from 'react-bootstrap';
import { fetchTeacherStudents } from '../../../http/teacherApi';
import { Context } from '../../..';

const AdminStudents = observer(() => {
    const {admin} = useContext(Context)  
    const {teacher} = useContext(Context)   

    useEffect(() => {
        loadData()
    }, [admin.setSelectedSubMenu, admin.selectedSideMenu])

    const loadData = async () => {
        const data = await fetchTeacherStudents(admin.selectedClass.id)
        teacher.setTeacherStudents(data)
    }

    const openStudent = (student) => {
        teacher.setSelectedStudent(student)
        admin.setSelectedSubMenu({id: 42})
    }

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Ученики {admin.selectedClass.name + ' / ' + admin.selectedSubject.name }</th>
                    </tr>
                </thead>
                <tbody>
                    {teacher.teacherStudents.map(student =>
                        <tr key={student.id}>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => openStudent(student)}
                            >
                                {student.name + ' (' + student.scoresCount  + ')'}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminStudents;