import React, { useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import {observer} from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import '../navbar.css'
import MyCalendar from '../CalendarStudent';
import { Card } from 'antd';

const StudentPlansCalendar = observer(() => {  
    const [eventVisible, setEventVisible] = useState(false)
    const [eventToModal, setEventToModal] = useState({})
//    const [, forceUpdate] = useState(0);
    const {admin} = useContext(Context)  

    // TODO загрузка событий 
/*     useEffect(() => {
        userList(userRole).then(data => admin.setUsers(data))
    }, []) */
    
    function onClickEvent(event) 
    {
/*         admin.setSelected(user);
        setUserToModal(user);
        setUserVisible(true); */
    }

    function onClickNewEvent() 
    {
   //     admin.setSelectedUser({});
        setEventToModal({login: '', password: '', name: ''});
        setEventVisible(true);
    }

    return (
      <Card title='Календарь планирования'>
{/*       <Table striped bordered hover>
        <thead>
            <tr>
                <th>Календарь планирования</th>
                 <th className="tableaction"><Button onClick={() => onClickNewEvent()} className='float-end'><FaPlus className="d-flex justify-content-between"/></Button></th>
            </tr>
        </thead>
        <tbody>
        </tbody>    
      </Table>
 */}      <MyCalendar/>
      {/* <AdminEvent show={eventVisible} onHide={() => setEventVisible(false)} event={eventToModal}/> */}
      </Card>
    );
});

export default StudentPlansCalendar;