import React, { useContext, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Container } from 'react-bootstrap';
import RadarChart from '../../utilites/star/RadarChart';
import { fetchStar1Data, fetchStar2Data, fetchStarSData, modyfyStarEduData, modyfyStarEduDataL2, modyfyStarSubData } from '../../../http/studentApi';
import EditStar from '../../utilites/star/EditStar';
import { message } from 'antd';
import { fetchTipsData } from '../../../http/adminApi';
import SubjectsStore from '../../../store/SubjectsStore';
import { fetchStar2DataBSID } from '../../../http/parentApi';
/* import star1 from './Star1.png'
import star2 from './Star2.png' */
//Звезды - прогресс студента уровень 2
const StudentStarL2 = observer(({ mode = 'student' }) => {
    const { student } = useContext(Context)
    const { parent } = useContext(Context)

    const [star2, setStar2] = useState([])

    useEffect(() => {
        loadData()
    }, [, parent.studentData])

    const loadData = async () => {
        let id
        if (mode == 'student') {
            id = student.selectedSubject?.id
        } else {
            id = parent.selectedSubject?.id
        }
        let data2
        if (mode == 'student') {
            data2 = await fetchStar2Data(id)
        } else {
            data2 = await fetchStar2DataBSID(id, parent.studentData.id)
        }
        if (data2) {
            setStar2(data2)
        }


        //student.setEducationRoute(data1)
/*         const tips = await fetchTipsData('student')
        setTipsTextDataSub(tips) */

        //для проверки нового стора (SubjectsStore)
        //subjects = [...student.studentSubjects]
    }

    const chartSize = 450;
    const numberOfScale = 5;

    const saveEducationRouteDataL2 = async () => {
        try {
            const formData = new FormData()
            formData.append('starData', JSON.stringify(star2))
            formData.append('subjectID', student.selectedSubject?.id)
            modyfyStarEduDataL2(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }

    const navigate = (name) => {
        if (mode == 'student') {
            let module = student.studentModules?.find((i) => i.name == name)
            if (module) {
                student.setSelectedModule(module)
                student.setSelectedSubMenu({ id: 42 })
            } else {
                message.error("Переход звезду 3 не удается")
            }
        }
        if (mode == 'parent') {
            let module = parent.studentModules?.find((i) => i.name == name)
            if (module) {
                parent.setSelectedModule(module)
                parent.setSelectedSubMenu({ id: 42 })
            } else {
                message.error("Переход звезду 3 не удается")
            }
        }
    } 

    const getHeader = () => {
        let ret = ''
        if (mode == 'student') {
            ret = student.studentSubjects?.find(i => i.id == student.selectedSubject)?.name
        }
        if (mode == 'parent') {
            ret = parent.studentSubjects?.find(i => i.id == parent.selectedSubject)?.name
        }
        return ret
    }

    return (
        <Container>
            <EditStar
                header={getHeader()}
                starS={star2}
                setStarS={setStar2}
                starLayer={1}
                navigate={navigate}
                saveData={saveEducationRouteDataL2}
                greenDesc='- планируемый уровень'
                yellowDesc='- текуший уровень'
                editable={mode == 'parent' ? false : true}
            />
        </Container>
    );
});

export default StudentStarL2;