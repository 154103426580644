import { observer } from "mobx-react-lite";
import React, { useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { BiDotsHorizontal } from 'react-icons/bi';
import { createRoute, deleteRoute, fetchRoutes, modyfyRoute, oneTheme } from '../../../http/teacherApi';
import { message, Popconfirm, Typography } from 'antd';
import { Context } from '../../..';
import { FormatPainterFilled } from "@ant-design/icons";
import ColorSelectDropdown from "./ColorSelectDropdown";

const { Paragraph } = Typography;

const RoutesTable = observer(() => {
  const { teacher } = useContext(Context)
  const { admin } = useContext(Context)

  useEffect(() => {
    loadData()
    //  oneTheme(themeID).then(data => setTheme(data))
  }, [teacher.selectedTheme])

  const loadData = async () => {
    const data = await fetchRoutes(teacher.selectedTheme.id)
    teacher.setTeacherRoutes(data)
  }

  const addRoute = async () => {
    const id = await createRoute(teacher.selectedTheme.id)
    teacher.setTeacherRoutes([...teacher.teacherRoutes, { name: '', id: id }])
  }

  const changeIndex = (index, id) => {
    changeRoute(index, null, null, id)
    teacher.setTeacherRoutes(teacher.teacherRoutes.map(i => i.id === id ? { ...i, ['index']: index } : i))
  }

  const changeName = (name, id) => {
    changeRoute(null, name, null, id)
    teacher.setTeacherRoutes(teacher.teacherRoutes.map(i => i.id === id ? { ...i, ['name']: name } : i))
  }

  const changeColor = (color, id) => {
    changeRoute(null, null, color, id)
    teacher.setTeacherRoutes(teacher.teacherRoutes.map(i => i.id === id ? { ...i, ['color']: color } : i))
  }

  const changeRoute = async (index, name, color, id) => {
    const data = await modyfyRoute(id, index, name, color)
  }

  const delRoute = async (route) => {
    //console.log("MOD", module);
    const data = await deleteRoute(route.id)
    if (data > 0) {
        message.success("Удаление успешно!")
        loadData()
    } else {
        message.error("Удаление не удалось!")
    }
}

  const openDescriptions = (route) => {
    teacher.setSelectedRoute(route)
    teacher.setSelectedSubMenu({ id: 63 })
    admin.setSelectedSubMenu({ id: 143 })
  }

  return (
    <Container>
      <div>
        Темы уроков для раздела #{teacher.selectedTheme.index} ({teacher.selectedTheme.name})
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Тема урока</th>
            <th>Цвет</th>
            <th></th>
            <th><Button onClick={() => addRoute()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
          </tr>
        </thead>
        <tbody>
          {teacher.teacherRoutes.map(route =>
            <tr key={route.id}>
              <td>
                {<Paragraph
                  editable={{
                    tooltip: 'Номер',
                    onChange: (value) => changeIndex(value, route.id),
                    triggerType: ['icon', 'text'],
                  }}
                >
                  {route.index}
                </Paragraph>}
                {/*        <FormControl
                    placeholder='Номер'
                    value={route.index}
                    onChange={(e) => changeIndex(e.target.value, route.id)}
                  /> */}
              </td>
              <td>
                <Paragraph
                  editable={{
                    tooltip: 'Введите название',
                    onChange: (value) => changeName(value, route.id),
                    triggerType: ['icon', 'text'],
                  }}
                >
                  {route.name}
                </Paragraph>
                {/*          <FormControl
                    placeholder='Введите название'
                    value={route.name}
                    onChange={(e) => changeName(e.target.value, route.id)}
                  /> */}
              </td>
              <td>
                {/*                <FormControl
                  placeholder='Введите цвет'
                  value={route.color}
                  onChange={(e) => changeColor(e.target.value, route.id)}
                  type="color"
                /> */}
                <ColorSelectDropdown
                  route={route}
                  changeColor={changeColor}
                />
              </td>
              <td><Button onClick={() => openDescriptions(route)}><BiDotsHorizontal className="d-flex justify-content-between" /></Button></td>
              <td>
                <Popconfirm
                  title={'Вы уверены что хотите удалить ' + route.name + '?'}
                  onConfirm={() => delRoute(route)}
                  onCancel={() => message.warning('Удаление отменено')}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button>
                    <FaMinus className="d-flex justify-content-between" />
                  </Button>
                </Popconfirm>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
});

export default RoutesTable;