import React from 'react';
import { observer } from "mobx-react-lite";
import { Container, Form } from 'react-bootstrap';

const AttendanceEditShedulled = observer(({ classes, subjects, classID, setClassID, subjectID, setSubjectID, lessonComment }) => {

    return (
        <Container>
            <Form className="mx-2">
                {/* {console.log("AES", subjects, subjectID, lessonComment)} */}
                <Form.Select onChange={(e) => setClassID(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={classID}>
                    {
                        classes?.map(clas =>
                            <option key={'clas' + clas.id} value={clas.id}>{clas.name}</option>
                        )
                    }
                </Form.Select>
                <Form.Select onChange={(e) => setSubjectID(e.target.value)} className="bg-transparent" bsPrefix="form-select" value={subjectID}>
                    {
                        subjects?.map(subject =>
                            <option key={'subj' + subject.attendanceID} value={subject.attendanceID}>{subject.name}</option>
                        )
                    }
                </Form.Select>
                {(lessonComment != '' && lessonComment != undefined && lessonComment != null && lessonComment != 'undefined') &&
                    <Form.Control className="mt-2 mb-2"
                        value={lessonComment}
                        disabled={true}
                    />
                }
            </Form>
        </Container>
    )
});

export default AttendanceEditShedulled;