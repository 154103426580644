import React, { useContext } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import {observer} from "mobx-react-lite";
import { Context } from '../..';
import '../navbar.css'

const TeacherBar = observer(() => {  
    const {teacher} = useContext(Context)   
    
    function click(menuItem) {
        teacher.setSelectedSubMenu({})
        teacher.setSelectedSideMenu(menuItem)
    }
    
    return (
      <ListGroup>
        {teacher.sideMenu.map(menuItem =>
            <ListGroup.Item bsPrefix="list-group-item listgroupitemadm"
                style={{cursor: 'pointer'}} 
                active={menuItem.id === teacher.selectedSideMenu.id}
                onClick={() => click(menuItem)}
                key={menuItem.id}   
            >    
                {menuItem.name}
            </ListGroup.Item>          
        )}
      </ListGroup>
    );
});

export default TeacherBar;