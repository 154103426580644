import React, { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createWorkType, deleteWorkType, modyfyWorkType } from '../../../http/adminApi';
import Paragraph from 'antd/lib/typography/Paragraph';


// Замена на тип контроля темы силабус-маршрута
const AdminRefWorkTypes = observer(() => {
    const { admin } = useContext(Context)

    const addWorkType = () => {
        const data = createWorkType().then(id =>
            admin.setWorkTypes([...admin.workTypes, { name: '', id: id }]))
    }

    const removeWorkType = (id) => {
        const data = deleteWorkType(id)
        admin.setWorkTypes(admin.workTypes.filter(i => i.id !== id))
    }

    const changeWorkType = (name, id) => {
        const data = modyfyWorkType(name, id)
        admin.setWorkTypes(admin.workTypes.map(i => i.id === id ? { ...i, ['name']: name } : i))
    }
    //admin.workTypes.map(workType => console.log("ID:", workType.id))

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Тип контроля в силабусе-маршруте</th>
                        <th><Button onClick={() => addWorkType()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {admin.workTypes.map(workType =>
                        <tr key={workType.id}>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Тип контроля',
                                        onChange: (value) => changeWorkType(value, workType.id),
                                        triggerType: ['icon', 'text'],
                                    }}
                                >
                                    {workType.name}
                                </Paragraph>
                                {/*    <FormControl
                                    placeholder='Введите название типа контроля'
                                    value={workType.name}
                                    onChange={(e) => changeWorkType(e.target.value, workType.id)}
                                /> */}
                            </td>
                            <td>
                                <Button onClick={() => removeWorkType(workType.id)}
                                    className='float-end'>
                                    <FaMinus className="d-flex justify-content-between" />
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminRefWorkTypes;