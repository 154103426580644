import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Card, Tabs } from 'antd';
import AdminUsersTable from './AdminUsersTableN';
import { userList } from '../../http/adminApi';

const AdminUsersTabs = () => {

    /*     const [students, setStudents] = useState([])
        const [parents, setParents] = useState([])
        const [teachers, setTeachers] = useState([])
        const [tutors, setTutors] = useState([])
        const [admins, setAdmins] = useState([]) */

    /*     useEffect(() => {
            userList('student').then(data => setStudents(data))
            userList('parent').then(data => setParents(data))
            userList('teacher').then(data => setTeachers(data))
            userList('tutor').then(data => setTutors(data))
            userList('ADMIN').then(data => setAdmins(data))
        }, []) */

    const items = [
        {
            label: 'Студенты',
            children: <AdminUsersTable userRole={'student'} />,
            key: '1',
        },
        {
            label: 'Родители',
            children: <AdminUsersTable userRole={'parent'} />,
            key: '2',
        },
        {
            label: 'Учителя',
            children: <AdminUsersTable userRole={'teacher'} />,
            key: '3',
        },
        {
            label: 'Тьюторы',
            children: <AdminUsersTable userRole={'tutor'} />,
            key: '4',
        },
        {
            label: 'Администраторы',
            children: <AdminUsersTable userRole={'ADMIN'} />,
            key: '5',
        },
    ];

    return (
        <Card>
            <Tabs
                type="card"
                items={items}
            />
        </Card>
    );
};

export default AdminUsersTabs;