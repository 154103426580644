import React, { useEffect, useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { fetchStarSData } from '../../../http/studentApi';
import RadarChart from '../../utilites/star/RadarChart';
import { Context } from '../../..';
import { Card, Col, message, Row, Slider } from 'antd';
import { fetchStarSubData, modyfyStarSubData } from '../../../http/teacherApi';
import TeacherStudentSubjectStarTips from '../../utilites/star/StarTips';
import EditStar from '../../utilites/star/EditStar';
import { fetchTipsData } from '../../../http/adminApi';
import AdminSelectClassStudent from './AdminSelectClassStudent';

const AdminStudentSubjectStar = observer(() => {
    const { admin } = useContext(Context)

    useEffect(() => {
        loadData()
    }, [admin.selectedStudent])

    const chartSize = 450;
    const numberOfScale = 5;

    const [starS, setStarS] = useState([])

    //  [{ 'Самооценка': 4, 'Самоконтроль': 3, 'Самоорганизация': 3, 'Соц-эмоциональный интеллект': 2, 'Стратегическое мышление': 3, 'Креативное мышление': 3 },
    //  { 'Самооценка': 4, 'Самоконтроль': 3, 'Самоорганизация': 2, 'Соц-эмоциональный интеллект': 3, 'Стратегическое мышление': 2, 'Креативное мышление': 2 }])

    const [tipsDataName, setTipsDataName] = useState('')
    const [tipsDataChoice, setTipsDataChoice] = useState(0)
    const [stateSaveButton, setStateSaveButton] = useState(true)
    const [tipsTextData, setTipsTextData] = useState({})

    const loadData = async () => {
        //const dataS = await fetchStarSData()
        if (admin.selectedStudent?.id) {
            const dataS = await fetchStarSubData(admin.selectedStudent?.id)
            setStarS(dataS)
            const tips = await fetchTipsData('tutor')
            setTipsTextData(tips)
        } else {
            setStarS([])
            setTipsTextData({})
        }
    }

    const change1 = (name, value) => {
        let star = starS
        star[1][name] = value
        setStarS(JSON.parse(JSON.stringify(star)))
        setTipsDataName(name)
        setTipsDataChoice(value)
        setStateSaveButton(false)
        //    console.log("starS",starS);
    }

    const saveData = async () => {
        try {
            const formData = new FormData()
            formData.append('studentID', admin.selectedStudent?.id)
            formData.append('starData', JSON.stringify(starS))
            modyfyStarSubData(formData).then(data => {
                //console.log("DATA",data,data == 'ok');
                if (data == 'ok') {
                    message.success("Cохранено успешно!")
                    loadData()
                } else {
                    message.error("Не сохранено, ответ сервера:", data)
                    //message.info('This is a normal message');
                }
            })
        } catch (error) {
            message.error("Не сохранено, ошибка:", error.message)
        }
    }

    return (<>
        <AdminSelectClassStudent />
        <Card title="Индивидуальный план развития">
            <EditStar
                //header={}
                starS={starS}
                setStarS={setStarS}
                tipsTextData={tipsTextData}
                starLayer={0}
                saveData={saveData}
                greenDesc='- самооценка студента'
                yellowDesc='- оценка тьютора'
            />
        </Card>
    </>        /*   <Row justify="space-around" align="middle">
            <Col span={14}>
                <Row>
                    <Col span={24}>
                        <RadarChart
                            route={starS}
                            size={chartSize}
                            numberOfScale={numberOfScale}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={1}>
                        <div style={{ backgroundColor: "#ffd110", height: '16px', width: '16px' }}></div>
                    </Col>
                    <Col span={23}>- самооценка студента</Col>
                </Row>
                <Row>
                    <Col span={1}>
                        <div style={{ backgroundColor: "#75B53E", height: '16px', width: '16px' }}></div>
                    </Col>
                    <Col span={23}>- оценка тьютора</Col>
                </Row>
            </Col>
            <Col span={10}>
                <h6>Самооценка</h6>
                <Slider
                    trackStyle={{ backgroundColor: "#75B53E" }}
                    defaultValue={starS[1]['Самооценка']}
                    // tooltip={{ open: true }}
                    max={4}
                    min={1}
                    onChange={(value) => change1('Самооценка', value)}
                />
                <h6>Самоконтроль</h6>
                <Slider
                    trackStyle={{ backgroundColor: "#75B53E" }}
                    defaultValue={starS[1]['Самоконтроль']}
                    // tooltip={{ open: true }}
                    max={4}
                    min={1}
                    onChange={(value) => change1('Самоконтроль', value)}
                />
                <h6>Самоорганизация</h6>
                <Slider
                    trackStyle={{ backgroundColor: "#75B53E" }}
                    defaultValue={starS[1]['Самоорганизация']}
                    // tooltip={{ open: true }}
                    max={4}
                    min={1}
                    onChange={(value) => change1('Самоорганизация', value)}
                />
                <h6>Соц-эмоциональный интеллект</h6>
                <Slider
                    trackStyle={{ backgroundColor: "#75B53E" }}
                    defaultValue={starS[1]['Соц-эмоциональный интеллект']}
                    // tooltip={{ open: true }}
                    max={4}
                    min={1}
                    onChange={(value) => change1('Соц-эмоциональный интеллект', value)}
                />
                <h6>Стратегическое мышление</h6>
                <Slider
                    trackStyle={{ backgroundColor: "#75B53E" }}
                    defaultValue={starS[1]['Стратегическое мышление']}
                    //  tooltip={{ open: true }}
                    max={4}
                    min={1}
                    onChange={(value) => change1('Стратегическое мышление', value)}
                />
                <h6>Креативное мышление</h6>
                <Slider
                    trackStyle={{ backgroundColor: "#75B53E" }}
                    defaultValue={starS[1]['Креативное мышление']}
                    //  tooltip={{ open: true }}
                    max={4}
                    min={1}
                    onChange={(value) => change1('Креативное мышление', value)}
                />
                <Container style={{ textAlign: 'right' }}>
                    <Button style={{ marginTop: '20px' }} variant="outline-success" onClick={saveData} disabled={stateSaveButton}>Сохранить</Button>
                </Container>
            </Col>
            <TeacherStudentSubjectStarTips tipsData={tipsTextData} name={tipsDataName} choice={tipsDataChoice} />
        </Row> */

    );
});

export default AdminStudentSubjectStar;