import React, { useEffect, useContext } from 'react';
import { observer } from "mobx-react-lite";
import { Container, Form } from 'react-bootstrap';
import { Context } from '../../..';

const AdminClasses = observer(() => {
    const { admin } = useContext(Context)

    const getClassID = () => { 
        if (Object.keys(admin.selectedClass).length != 0) {
            return admin.selectedClass.id
        } else {
            admin.setSelectedClass(admin.classes[0])
            return admin.classes[0]?.id
        }
    }

    const getSubjectID = () => {
        if (Object.keys(admin.selectedSubject).length != 0) {
            return admin.selectedSubject.id
        } else {
            admin.setSelectedSubject(admin.subjects[0])
            return admin.subjects[0]?.id
        }
    }

    const setClass = (classID) => {
        console.log("ADS1",classID);
        admin.setSelectedClass(admin.classes.find(clas => clas.id == classID))
    }

    const openStudents = (subjectID) => {
        console.log("ADS2",subjectID);
        admin.setSelectedSubject(admin.subjects.find(subj => subj.id == subjectID))
        admin.setSelectedSubMenu({ id: 41 })
    }

    return (
        <Container>
            <Form className="mx-2">
                <Form.Select onChange={(e) => setClass(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={getClassID()} aria-label="Default select example">
                    {admin.classes.map(clas =>
                        <option key={'class' + clas.id} value={clas.id}>{clas.name}</option>
                    )}
                </Form.Select>
            </Form>
            <Form className="mx-2">
                <Form.Select onChange={(e) => openStudents(e.target.value)} className="bg-transparent" bsPrefix="form-select" defaultValue={getSubjectID()} aria-label="Default select example">
                    {admin.subjects.map(subject =>
                        <option key={'subject' + subject.id} value={subject.id}>{subject.name}</option>
                    )}
                </Form.Select>
            </Form>
        </Container>
    );
});

export default AdminClasses;