import React, { useContext, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Container, Form, Button } from 'react-bootstrap';
import { createVisitInterval, deleteVisitInterval, fetchMKIntervals, fetchStudentCarers, fetchVisitIntervals } from '../../http/parentApi';
import { Checkbox, message } from 'antd';
import ParentMeetingVisit from './ParentMeetingVisit';
import ParentMeetingMK from './ParentMeetingMK';

//Запись на наблюдение
const ParentMeeting = observer(() => {
    const { parent } = useContext(Context)

    const [visitIntervals, setVisitIntervals] = useState([])
    const [mkIntervals, setMKIntervals] = useState([])
    const [carers, setCarers] = useState([])

    useEffect(() => {
        loadData()
    }, [parent.studentData])

    const loadData = async () => {
        if (parent?.studentData?.id) {
            // есть студент запрашиваем список интервалов и других опекунов
            const data = await fetchVisitIntervals(parent.studentData.id)
            setVisitIntervals(data)
            const carersData = await fetchStudentCarers(parent.studentData.id)
            setCarers(carersData)
            const datamk = await fetchMKIntervals(parent.studentData.id)
            setMKIntervals(datamk)
        }
    }

    return (
        <Container>
            {parent?.studentData?.id &&
                <ParentMeetingVisit
                    student={parent?.studentData}
                    visitIntervals={visitIntervals}
                    carers={carers}
                    reload={loadData}
                />
            }
            {parent?.studentData?.id && visitIntervals[0]?.reserved &&
                <ParentMeetingMK
                    student={parent?.studentData}
                    mkIntervals={mkIntervals}
                    carers={carers}
                    reload={loadData}
                    isStudents={parent.students.length > 1 ? true : false}
                />
            }
        </Container>
    );
});

export default ParentMeeting;