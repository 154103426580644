import React, { useContext, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';
import { Button, Container, FormControl } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { createSubject, modyfySubject, deleteSubject, fetchSubjects } from '../../../http/adminApi';
import SubjectsStore from '../../../store/SubjectsStore';
import Paragraph from 'antd/lib/typography/Paragraph';

const AdminRefSubjects = observer(() => {
    //const {admin} = useContext(Context)
 
/*      useEffect(() => {
        //fetchSubjects().then(data => SubjectsStore.setSubjects(data))
    }, [])  */

/*     const addSubject = () => {
        createSubject().then(id =>
            SubjectsStore.setSubjects([...SubjectsStore.subjects, { name: '', id: id }]))
    }

    const removeSubject = (id) => {
        deleteSubject(id).then(() =>
            SubjectsStore.setSubjects(SubjectsStore.subjects.filter(i => i.id !== id)))
    }

    const changeSubject = (name, id) => {
        modyfySubject(name, id).then(() =>
            SubjectsStore.setSubjects(SubjectsStore.subjects.map(i => i.id === id ? { ...i, ['name']: name } : i)))
    } */

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Предмет</th>
                        <th><Button onClick={() => SubjectsStore.addSubject()} className='float-end'><FaPlus className="d-flex justify-content-between" /></Button></th>
                    </tr>
                </thead>
                <tbody>
                    {SubjectsStore.subjects.map(subject =>
                        <tr key={subject.id}>
                            <td>
                                <Paragraph
                                    editable={{
                                        tooltip: 'Редактировать название предмета',
                                        onChange: (value) => SubjectsStore.changeSubject(value, subject.id),
                                        triggerType: ['icon', 'text'],
                                        editing: subject.opened,
                                    }}
                                >
                                    {subject.name}
                                </Paragraph>
                                {/* <FormControl
                                    placeholder='Введите название предмета'
                                    value={subject.name}
                                    onChange={(e) => changeSubject(e.target.value, subject.id)}
                                /> */}
                            </td>
                            <td><Button onClick={() => SubjectsStore.removeSubject(subject.id)} className='float-end'><FaMinus className="d-flex justify-content-between" /></Button></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminRefSubjects;