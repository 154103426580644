import { makeAutoObservable } from "mobx";
import { check } from "../http/userApi";
import AuthService from "../services/AuthService";
import localForage from "localforage";

export class User {
    constructor() {
        this._isAuth = false
        this._user = {}
        this._role = ''
        this._roles = []
        this._isLoading = false
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }
    setUser(user) {
        this._user = user
    }
    setRole(role) {
        this._role = role
    }
    setRoles(roles) {
        this._roles = roles
    }
    setIsLoading(bool) {
        this._isLoading = bool
    }

    get isAuth() {
        return this._isAuth
    }
    get user() {
        return this._user
    }
    get role() {
        return this._role
    }
    get roles() {
        return this._roles
    }
    get isLoading() {
        return this._isLoading
    }

    check() {
        this.setIsLoading(true);
        check().then(data => {

            if (data) {
                this.setUser(data);
                this.setIsAuth(true);
                this.setRole(data.role);
                data.roles ? this.setRoles(JSON.parse(data.roles)) : this.setRoles([]);
            }
        }).finally((data) => {
            this.setIsLoading(false);
            return data;
        })
    }

    async login(login, password){
        try{
            const response = await AuthService.login(login, password);
            localForage.setItem('token', response.data.accessToken);
            this.setIsAuth(true);
            this.setUser(response.data.user);
            this.setRole(response.data.role)
            response.data.roles ? this.setRoles(JSON.parse(response.data.roles)) : this.setRoles([]);
        } catch (e) {
            //console.log(e.response?.data?.message);
        }
    }


}

//const UserStore = new User()
//export default UserStore
