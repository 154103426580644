import { FC } from "react";
import { $authHost } from "../http";
import { useLoaderData } from "react-router-dom";
import { Button } from "react-bootstrap";

export async function loaderCopyModules() {
    const { data } = await $authHost.get('api/test');
    console.log(data);

    console.log('====================================');
    console.log("CopyModules loader");
    console.log('====================================');

    return { rows: data };
}

export const CopyModules: FC = () => {
    const { rows } = useLoaderData() as any;

    const copy = async (id:string) => {
        const [classID, subjectID]  = id.split("|");
        console.log("click", classID, subjectID);
        
        await $authHost.post('api/test', {classID, subjectID});
      }
    
    return (
        <>
            {rows.map((item: { name: string, value: string, cnt: number }) => (
                <div>{item.name} {item.cnt} <Button onClick={() => copy(item.value)}>Копи</Button></div>
            ))}

        </>
    )
}
