import React, { useEffect, useContext, useState } from 'react';
//import Table from 'react-bootstrap/Table';
import { Card, Table, Tooltip } from 'antd';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { Context } from '../../..';
import { Col, InputNumber, message, Row } from 'antd';
//import moment from 'moment';
//import 'moment/locale/ru';
import { DatePicker } from 'antd';
import { fetchAttendanceStat, fetchTeacherClassesSubjects } from '../../../http/teacherApi';
import AttendanceStudentHist from './AttendanceStudentHist';
import AttendanceStudentStatus from './AttendanceStudentStatus';
import TeacherScoreSheet from './TeacherScoreSheet';
import { useLoaderData } from 'react-router-dom';
import type { TableColumnsType,TableProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs'


export async function loaderAttendanceStat({ params }: any) {
    const { selectedClassSubject } = params;

    if (selectedClassSubject) {
        const rawClassSubjects = await fetchTeacherClassesSubjects();
        const target = rawClassSubjects.find((item: any) => item.id === selectedClassSubject)

        //const [classID, subjectID] = selectedClassSubject.split('|');
        console.log("loaderAttendanceStat", params, target);
        return { target };
    }
    return null;
}




const { RangePicker } = DatePicker;



const TeacherAttendanceStat = observer(() => {
    const { target } = useLoaderData() as any;
    const [statBegin, setStatBegin] = useState(dayjs().subtract(1, 'month'));
    const [statEnd, setStatEnd] = useState(dayjs().add(1, 'month'));

    //const { teacher } = useContext(Context) as any;

    const dateFormat = 'DD.MM.YYYY';
    const dateFormatForDB = 'YYYY-MM-DD';

    const firstColumn = {
        title: <>{target.className} / {target.subjectName}</>,
        dataIndex: 'name',
        //fixed: 'left',
        width: 200,
    }
    const lastColumn = {
        title: <>Сводная гистограмма</>,
        dataIndex: 'hist',
        //fixed: 'right',
        width: 300,
        //align: 'center',
    }

    interface DataType {
        title?: JSX.Element;
        dataIndex?: string;
        fixed?: string;
        width?: number;
        align?: string;
    }

    const [studentsList, setStudentsList] = useState([])
    const [columns, setColumns] = useState([firstColumn, lastColumn])
    const columns2: TableColumnsType<DataType> = [
        firstColumn,
        { title: 'Name', dataIndex: 'name' },
        { title: 'Age', dataIndex: 'age' },
        lastColumn,
      ];

    //    const [begin, setBegin] = useState(moment(moment.now() - 604800000))
    //    const [end, setEnd] = useState(moment(moment.now()))

    useEffect(() => {
        loadData((statBegin) ? statBegin:dayjs(), (statEnd)? statEnd:dayjs())
    }, [])

    const loadData = async (begin: any, end: any) => {
        const data = await fetchAttendanceStat(begin.format(dateFormatForDB), end.format(dateFormatForDB), target.classID, target.subjectID)

        let colData = [firstColumn]
        data.columns.forEach((col: any) => {
            colData.push({
                title: <Tooltip title={col?.begin + '-' + col.end} color="grey"><div style={{ writingMode: 'sideways-lr' }}>{col.date}</div></Tooltip>,
                dataIndex: String(col.id),
                width: 30,
                //fixed: ''
            })
        })
        colData.push(lastColumn)
        setColumns(colData)

        setStudentsList(data.students.map((stu: any) => {
            for (var key in stu) {
                if (key != 'id' && key != 'name' && key != 'hist' && key != 'key') {
                    stu[key] = <AttendanceStudentStatus cell={stu[key]} />
                }
            }
            return {
                ...stu,
                ['key']: stu.id,
                ['hist']: <AttendanceStudentHist hist={stu.hist} />
            }
        }))

    }

    const changePeriod = (period: any) => {
        setStatBegin(period[0])
        setStatEnd(period[1])
        //setBegin(period[0])
        //setEnd(period[1])
        loadData(period[0], period[1])
    }

    const props = {begin: statBegin, end: statEnd, target};
    return (
        <Card title={'Статистика посещаемости'} >
            <RangePicker
                //value={[begin, end]}
                value={[statBegin, statEnd]}
                format={[dateFormat, dateFormat]}
                allowClear={false}
                onChange={(value) => changePeriod(value)}
            /*  disabledDate={disabledDate}
             onCalendarChange={(val) => setDates(val)}
             onChange={(val) => setValue(val)}
             onOpenChange={onOpenChange} */
            />

            <Table
                style={{ marginTop: '15px' }}
                columns={columns}
                dataSource={studentsList}
                pagination={false}
                /*   pagination={{
                      pageSize: 50,
                  }} */
                scroll={{
                    x: 500,
                }}
            />
            {/* <TeacherScoreSheet {...props}></TeacherScoreSheet> */}
        </Card>
    );
});

export default TeacherAttendanceStat;