import React from 'react';
import { Col, Row } from 'antd';

const AttendanceStudentHist = ({ hist }) => {
  //  hist:{'green':'4','red':'10','yellow':'10','ogange':'0','blue':'0'}

  return (
    <Row>
      {hist.green > 0 && <Col style={{ backgroundColor: 'green', opacity: '0.6' }} span={hist.green}>&nbsp;</Col>}
      {hist.red > 0 && <Col style={{ backgroundColor: 'red', opacity: '0.6' }} span={hist.red}>&nbsp;</Col>}
      {hist.yellow > 0 && <Col style={{ backgroundColor: 'yellow', opacity: '0.6' }} span={hist.yellow}>&nbsp;</Col>}
      {hist.orange > 0 && <Col style={{ backgroundColor: 'orange', opacity: '0.6' }} span={hist.orange}>&nbsp;</Col>}
      {hist.blue > 0 && <Col style={{ backgroundColor: 'blue', opacity: '0.6' }} span={hist.blue}>&nbsp;</Col>}
    </Row>
  );
};

export default AttendanceStudentHist;