import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Context } from '../..';
import { Button, Container, Dropdown, Form } from 'react-bootstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';

const AdminClassSubjects = observer(({ classID, subjects, setSubjects }) => {
    const { admin } = useContext(Context)
    const [selectedSubject, setSelectedSubject] = useState({})

    /*     useEffect(() => {
            loadData()
        }, [])
    
        const loadData = async () => {
            let data = await fetchClassSubjects(classID)
            setSubjects(data)
        } */

    const addSubject = async (subjectID) => {
        if (subjectID) {
            setSubjects([...subjects, { id: subjectID, levelID: classID }])
            setSelectedSubject({})
        }
    }

    const removeSubject = async (subjectID) => {
        setSubjects(subjects.filter(i => i.id !== subjectID))
    }

    const changeSubject = async (levelID, subjectID) => {
        setSubjects(subjects.map(i => i.id === subjectID ? { ...i, ['levelID']: levelID } : i))
    }

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Наберите для фильтрации..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>
                            <Dropdown /* search */>
                                Добавить предмет:&nbsp;
                                <Dropdown.Toggle>{selectedSubject?.name || "Выберите предмет"}</Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu}>
                                    {admin.subjects.filter(i => !subjects.find(j => j.id == i.id)).map(subj =>
                                        <Dropdown.Item
                                            onClick={() => setSelectedSubject(subj)}
                                            key={subj.id}
                                        >
                                            {subj.name}
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                                &nbsp;<Button onClick={() => addSubject(selectedSubject.id)} className='float-begin'><FaPlus className="d-flex justify-content-between" /></Button>
                            </Dropdown>
                        </th>
                        <th>
                            Уровень сложности
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {subjects.map(subject =>
                        <tr key={subject.id}>
                            <td>
                                {admin.subjects.find(i => i.id === subject.id)?.name}
                            </td>
                            <td>
                                <Dropdown>
                                    <Dropdown.Toggle>{admin.classes.filter(j => j.id == subject?.levelID)[0]?.name || "Выберите уровень"}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {admin.classes.map(clas =>
                                            <Dropdown.Item
                                                onClick={() => changeSubject(clas.id, subject.id)}
                                                key={clas.id}
                                            >
                                                {clas.name}
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                            <td><Button onClick={() => removeSubject(subject.id)}><FaMinus className="d-flex justify-content-between" /></Button></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AdminClassSubjects;