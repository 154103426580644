import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Container } from 'react-bootstrap';
import { Typography, TimePicker, Space, Button } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import AttendanceDropdown from './AttendanceDropdown';
import { Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import AttendanceDropdownComment from './AttendanceDropdownComment';

const { Paragraph } = Typography;


const AttendanceEditTable = observer(({ attendance, setAttendance }) => {

    let index = 1

    const timeFormat = 'HH:mm';
    const disabledTime = (value) => {
        return { disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24] }
    }

    const itemsROB = [
        {
            label: 'Болеет',
            key: 'Болеет',
        },
        {
            label: 'Уважительная причина',
            key: 'Уважительная причина',
        },
        {
            label: 'Тьоториал',
            key: 'Тьоториал',
        },
        {
            label: 'Свой комментарий',
            key: ' ',
        },
    ];

    const itemsY = [
        {
            label: 'Включён в работу',
            key: 'Включён в работу',
        },
        {
            label: 'Не включён в работу',
            key: 'Не включён в работу',
        },
        {
            label: 'Выполняет вечерние задания',
            key: 'Выполняет вечерние задания',
        },
        {
            label: 'Закрывает силлабус в срок',
            key: 'Закрывает силлабус в срок',
        },
        {
            label: 'На уроке работает самостоятельно',
            key: 'На уроке работает самостоятельно',
        },
        {
            label: 'Болеет',
            key: 'Болеет',
        },
        {
            label: 'Уважительная причина',
            key: 'Уважительная причина',
        },
        {
            label: 'Тьоториал',
            key: 'Тьоториал',
        },
        {
            label: 'Свой комментарий',
            key: ' ',
        },
    ];

    const itemsG = [
        {
            label: 'Включён в работу',
            key: 'Включён в работу',
        },
        {
            label: 'Не включён в работу',
            key: 'Не включён в работу',
        },
        {
            label: 'Выполняет вечерние задания',
            key: 'Выполняет вечерние задания',
        },
        {
            label: 'Закрывает силлабус в срок',
            key: 'Закрывает силлабус в срок',
        },
        {
            label: 'На уроке работает самостоятельно',
            key: 'На уроке работает самостоятельно',
        },
        {
            label: 'Свой комментарий',
            key: ' ',
        },
    ];


    /*     useEffect(() => {
         //   console.log("LD",data);
            loadData(data.classID, data.subjectIDname, data.date.toString())
        }, [data])
    
        const loadData = async (classID, subjectIDname, date) => {
            const data = await fetchAttendance(classID, subjectIDname, date)
            setAttendance(data)
        } */

    const changeStatus = (status, id) => {
        // console.log("cc1",status, id, attendance);
        //  changeAttendance(id, status, null, null, null)
        setAttendance(attendance.map(i => i.id === id ? { ...i, ['status']: status } : i))
        //  console.log("cc2",status, id, attendance);
    }

    const changeBegin = (begin, id) => {
        //  changeAttendance(id, null, begin, null, null)
        setAttendance(attendance.map(i => i.id === id ? { ...i, ['begin']: begin } : i))
    }

    const changeEnd = (end, id) => {
        //  changeAttendance(id, null, null, end, null)
        setAttendance(attendance.map(i => i.id === id ? { ...i, ['end']: end } : i))
    }

    const changeComment = (comment, id) => {
          console.log("cc",comment, id);
        //  changeAttendance(id, null, null, null, comment)
        setAttendance(attendance.map(i => i.id === id ? { ...i, ['comment']: comment } : i))
    }

    /*     const changeAttendance = async (id, status, begin, end, comment) => {
            const dataRet = await modyfyAttendance(id, status, begin, end, comment, data.date.toString())
        } */

    const checkDropdown = (attendance) => {
        let ret = false
        if ((attendance.status == 'red' || attendance.status == 'yellow' || attendance.status == 'orange' || attendance.status == 'green') && !attendance.comment) {
            ret = true
        }
        return ret
    }

    const selectItems = (attendance) => {
        let ret = []
        if (attendance.status == 'green') {
            ret = itemsG
        } else {
            if (attendance.status == 'yellow') {
                ret = itemsY
            } else {
                ret = itemsROB
            }
        }
        return ret
    }


    return (
        <Container>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>ФИО ученика</th>
                        <th>Статус</th>
                        <th>Начало</th>
                        <th>Конец</th>
                        <th>Комментарий</th>
                    </tr>
                </thead>
                <tbody>
                    {attendance.map(attendance =>
                        <tr key={attendance.id}>
                            <td>
                                {index++}
                            </td>
                            <td>
                                {attendance.studentName}
                            </td>
                            <td>
                                <AttendanceDropdown attendance={attendance} changeStatus={changeStatus} />
                            </td>
                            <td>
                                <TimePicker
                                    minuteStep={1}
                                    format={timeFormat}
                                    allowClear={false}
                                    defaultValue={moment(attendance.begin, timeFormat)}
                                    //value={moment(attendance.begin, timeFormat)}
                                    onChange={(value) => changeBegin(value.format(timeFormat), attendance.id)}
                                    disabledTime={disabledTime}
                                />
                            </td>
                            <td>
                                <TimePicker
                                    minuteStep={1}
                                    format={timeFormat}
                                    allowClear={false}
                                    defaultValue={moment(attendance.end, timeFormat)}
                                    //value={moment(attendance.end, timeFormat)}
                                    onChange={(value) => changeEnd(value.format(timeFormat), attendance.id)}
                                    disabledTime={disabledTime}
                                />
                            </td>
                            <td>
                                {checkDropdown(attendance) ?
                                    <AttendanceDropdownComment
                                        items={selectItems(attendance)}
                                        attendance={attendance}
                                        changeData={changeComment}
                                    />
                                    :
                                    <Paragraph
                                        editable={{
                                            tooltip: 'Введите комментарий',
                                            onChange: (value) => changeComment(value, attendance.id),
                                            triggerType: ['icon', 'text'],
                                        }}
                                    >
                                        {attendance.comment}
                                    </Paragraph>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
});

export default AttendanceEditTable;