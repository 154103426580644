import Select from "react-select";
import { FC, useEffect, useState } from "react";
import { PropertiesType, UserType } from "../../../models/Users";
import UsersService from "../../../services/UsersService";

interface PropsType {
    studentID: number;
    //value?: number | string | {};
    onChange?: any;
}

const SelectParent: FC<PropsType> = (props) => {
    const [options, setOptions] = useState<{ value: number, label: string }[]>();
    const [defaultValue, setDefaultValue] = useState<{ value: number, label: string } | null>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (id: number) => {
        setIsLoading(true);
        let parents: { value: number, label: string }[] = new Array();
        console.debug('student', id);
        const student = await UsersService.getUser(id);
        const properties = student?.properties.filter((j: PropertiesType) => j.key == 'Мама' || j.key == 'Папа')
        properties.forEach(async (item: PropertiesType) => {
            const ret = await UsersService.getUser(Number(item.value));
            if (ret) {
                parents.push({ label: ret.name, value: ret.id });
            } else {
                console.error("Ошибка целостности базы данных? Студент: ", id, "имеет не существующего родителя");
            }
        })
        setOptions(parents);
        setDefaultValue(null);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData(props.studentID);
    }, [props.studentID])

    const onChange = (value: any) => {
        setDefaultValue(value);
        console.log(value.value);

        props?.onChange(value);
    }

    return (
        <>
            <Select
                isLoading={isLoading}
                placeholder="Выберете родителя"
                name="parentID"
                options={options}
                onChange={onChange}
                value={defaultValue}
            />
        </>
    )
}

export default SelectParent;