import Styles from './index.module.css';
import { Input } from "antd";
import { useEffect, useRef, useState } from "react";
import type { MouseEventHandler } from "react";



type MyInputProps = {
    id: number;
    defaultValue?: string | null | undefined;
    placeholder?: string;
    //    mode?: "rows" | "cells";
    status?: "default" | "invalid";
    name: string;
    onChange?: (id: number, name: string, data: string | undefined) => void;
    onClose?: () => void;
};

const MyInput = (props: MyInputProps) => {

    const { id, name, defaultValue, onClose, placeholder } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [prevValue, setPrevValue] = useState(defaultValue);
    const rootRef = useRef<HTMLDivElement>(null);

    //    console.log(defaultValue);import type { InputRef } from 'antd';

    const [value, setValue] = useState(defaultValue ? defaultValue : undefined);


    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    const onPressEnter = () => {
        props.onChange?.(id, name, value);
    }

    useEffect(() => {

        const handleClick = (event: MouseEvent) => {

            const { target } = event;
            if (target instanceof Node && !rootRef.current?.contains(target)) {
                isOpen && onClose?.();
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };

    }, [isOpen, onClose]);

    useEffect(() => {
        if (!isOpen && value !== prevValue) {
            onPressEnter();
            setPrevValue(value);
        }
    }, [isOpen]);

    /*     const handleOptionClick = (value: Option['value']) => {
            setIsOpen(false);
            onChange?.(value);
        }; */

    const handlePlaceHolderClick: MouseEventHandler<HTMLDivElement> = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div
            className={Styles.myInputWrapper}
            ref={rootRef}
            data-is-active={isOpen}
        //data-mode={mode}
        >
            <div
                className={Styles.placeholder}
                //data-status={status}
                //data-selected={!!selected?.value}
                onClick={handlePlaceHolderClick}
                role='button'
                tabIndex={0}
            >
                {!isOpen && (value ? value : placeholder)}
                {isOpen && (
                    <Input
                        onChange={onChange}
                        defaultValue={value}
                        allowClear={false}
                        size='small'
                        autoFocus
                        onPressEnter={() => setIsOpen(false)}
                    />
                )
                }
            </div>
        </div>

    )
}

export default MyInput;