import React from 'react';
import { CheckCircleTwoTone, CheckSquareTwoTone, CloseCircleTwoTone, CloseSquareTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';

const titleGenEl = (el) => {
  let ret = ''
  if (el.status == 'yellow') {
    ret = 'Опоздание '
  }
  if (el.status == 'blue') {
    ret = 'Дистант '
  }
  if (el.comment) {
    ret = ret + el.begin + '-' + el.end + ': ' + el.comment
  } else {
    ret = ret + el.begin + '-' + el.end
  }
  return ret
}

const titleGenCell = (cell) => {
  let ret = ''
  if (cell.status == 'yellow') {
    ret = 'Опоздание '
  }
  if (cell.status == 'blue') {
    ret = 'Дистант '
  }
  ret = ret + cell.comment
  return ret
}

const AttendanceStudentStatus = ({ cell }) => {
  //  cell:{'status':'red','comment':'dgfdghdfgdfg'}

  if (cell.constructor.name == "Array") {
    return (cell.map((el) => {
      if (el.status == 'red') {
        if (el.comment) {
          return (<Tooltip title={el.begin + '-' + el.end + ': ' + el.comment} color="grey"><CloseSquareTwoTone twoToneColor="red" /></Tooltip>);
        } else {
          return (<Tooltip title={el.begin + '-' + el.end} color="grey"><CloseCircleTwoTone twoToneColor="red" /></Tooltip>);
        }
      } else {
        if (el.comment) {
          return (<Tooltip title={titleGenEl(el)} color="grey"><CheckSquareTwoTone twoToneColor={el.status} /></Tooltip>);
        } else {
          return (<Tooltip title={titleGenEl(el)} color="grey"><CheckCircleTwoTone twoToneColor={el.status} /></Tooltip>);
        }
      }
    }))
  } else {
    if (cell.status == 'red') {
      if (cell.comment) {
        return (<Tooltip title={cell.comment} color="grey"><CloseSquareTwoTone twoToneColor="red" /></Tooltip>);
      } else {
        return (<Tooltip title={cell.comment} color="grey"><CloseCircleTwoTone twoToneColor="red" /></Tooltip>);
      }
    } else {
      if (cell.comment) {
        return (<Tooltip title={titleGenCell(cell)} color="grey"><CheckSquareTwoTone twoToneColor={cell.status} /></Tooltip>);
      } else {
        return (<Tooltip title={titleGenCell(cell)} color="grey"><CheckCircleTwoTone twoToneColor={cell.status} /></Tooltip>);
      }
    }
  }
};

export default AttendanceStudentStatus;