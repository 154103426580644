import { FC } from 'react';
import { observer } from "mobx-react-lite";
import { Card } from 'antd';
import AdminParentVisit, { loader as visitLoader, action as visitAction, destroy } from './AdminParentVisit';
import AdminParentIK from './AdminParentIK';
import { NavLink, Navigate, Outlet } from 'react-router-dom';
import NewObservation, { action as observationAction, loader as observationLoader } from './NewObservation';

export const loader = async () => {
  console.log('=visit loader===================================');
  return null
}

const AdminParentMeetingTabs: FC = () => {

  return (
    <Card title={
      <div id='upbar'>
        <nav ><ul>
          <li key={1}><NavLink to={'observation'}
            className={({ isActive, isPending }) =>
              isActive
                ? "active"
                : isPending
                  ? "pending"
                  : ""
            }
          >Запись на наблюдение</NavLink></li>
          <li key={2}><NavLink to={'ik'}
            className={({ isActive, isPending }) =>
              isActive
                ? "active"
                : isPending
                  ? "pending"
                  : ""
            }
          >Запись на ИК</NavLink></li>
        </ul></nav>
      </div>
    }>
      <Outlet />
    </Card>
  );
};

export const router = [
  { index: true, element: <Navigate to="observation" replace /> },
  {
    path: 'observation/*', element: < AdminParentVisit />, loader: visitLoader, action: visitAction, children: [
      { path: ':id/destroy', element: <></>, action: destroy },
    ]
  },
  { path: 'observation/new', element: <NewObservation />, loader: observationLoader, action: observationAction },

  { path: 'ik/*', element: < AdminParentIK />, loader: loader },
];

export default observer(AdminParentMeetingTabs);