import React from 'react'

const points = points => {
    return points
        .map(point => point[0].toFixed(4) + ',' + point[1].toFixed(4))
        .join(' ');
}

export const axis = (polarToX, polarToY, size) => (col, i) => (
    <polyline
        key={`poly-axis-${i}`}
        points={points([
            [0, 0],
            [
                polarToX(col.angle, size / 2 - (size / 10)),
                polarToY(col.angle, size / 2 - (size / 10))
            ],
        ])}
        stroke="#555"
        strokeWidth=".2"
    />
)

export default points