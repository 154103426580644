import styled from 'styled-components'

const CheckboxContainer = styled.div`

    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    //justify-content: center;

label {
    font-size: 1.0em;
    cursor: pointer;
    display: flex;
    &:hover{
        &::before{
            background-color: gray;
        }
    }
}

input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

input:checked {
    &+label::before{
        content: '\\002714';
        background-color: #eeb004;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

input:focus {
    &+label::before{
        box-shadow: 0 0 10px blue;
    }
}

label::before {
    //font-size: 0.9em;
    content: '';
    border: 0.05em solid gray;
    height: 1em;
    width: 1em;
    border-radius: 0.15em;
    margin-right: 0.5em;
}
`

const MyCheckbox = ({ labelTxt, id, onChange, checked }: any) => (
    <CheckboxContainer>
        <input type={"checkbox"} id={id} onChange={onChange}  checked={checked} />
        <label htmlFor={id}>{labelTxt}</label>
    </CheckboxContainer>
)


export default MyCheckbox;