import React, { useEffect, useContext, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { observer } from "mobx-react-lite";
import { Button, Container, Form } from 'react-bootstrap';
import { fetchTeacherStudents, fetchStudentsScoresList, fetchModules, fetchThemes, getCurrentTheme, fetchStudentsScoresListByThemeID, modyfyStudentsScoresList } from '../../../http/teacherApi';
import { Context } from '../../..';
import TeacherSelectTheme from './TeacherSelectTheme';
import TeacherSelectWorkType from './TeacherSelectWorkType';
import { fetchWorkTypes } from '../../../http/adminApi';
import { InputNumber, message } from 'antd';
import FilesUpload from '../../utilites/FilesUpload';
import HTMLViewer from '../../utilites/HTMLViewer';

const TeacherStudentsMass = observer(() => {
    const { teacher } = useContext(Context)
    const [selectedWorkTypeID, setSelectedWorkTypeID] = useState(0)
    const [selectedThemeID, setSelectedThemeID] = useState(0)
    const [studentsScoresList, setStudentsScoresList] = useState([])
    const [l3Text, setL3Text] = useState("")
    const [l4Text, setL4Text] = useState("")

    useEffect(() => {
        loadData()
    }, [teacher.selectedSideMenu])

    useEffect(() => {
        loadData()
        // loadStudentsScoresList()
    }, [selectedThemeID])

    const loadData = async () => {
        if (selectedThemeID != 0) {
            loadStudentsScoresList()
        }

        /*         const dataMT = await getCurrentTheme(teacher.selectedClasSubject.subjectID, 0, teacher.selectedClasSubject.classID)
                teacher.setSelectedModule(dataMT.module)
                const dataT = await fetchThemes(dataMT.module.id)
                teacher.setTeacherThemes(dataT)
                teacher.setSelectedTheme(dataMT.theme)
                setSelectedMduleID(dataMT.module.id)
                setSelectedThemeID(dataMT.theme.id)
        
                const data = await fetchTeacherStudents(teacher.selectedClasSubject.classID)
                teacher.setTeacherStudents(data)
                const dataS = await fetchStudentsScoresList(teacher.selectedClasSubject.classID, teacher.selectedClasSubject.subjectID, dataMT.module.id, dataMT.theme.id)
                setStudentsScoresList(dataS) */

    }

    const loadStudentsScoresList = async () => {
        const dataS = await fetchStudentsScoresListByThemeID(selectedThemeID)

        if (dataS?.l3Text) {
            setL3Text(dataS.l3Text)
        } else {
            setL3Text("")
        }
        if (dataS?.l4Text) {
            setL4Text(dataS.l4Text)
        } else {
            setL4Text("")
        }
        if (dataS?.students?.length > 0) {
            setStudentsScoresList(dataS.students)
        } else {
            setStudentsScoresList([])
            alert("Список студентов не найден, или их нет, или нет заданий урока")
        }

    }

    const selectWorkType = (workTypeID) => {
        setSelectedWorkTypeID(workTypeID)
    }

    const selectTheme = (themeID) => {
        teacher.setSelectedTheme(teacher.teacherThemes.find(theme => theme.id == themeID))
        setSelectedThemeID(themeID)
        // await fetchDescriptionsByThemeID(themeID, teacher.selectedStudent.id).then(data => teacher.setTeacherDescriptions(data))
    }

    const changeScore = (score, studentID) => {
        setStudentsScoresList(studentsScoresList.map(i => i.id === studentID ? { ...i, ['score']: score } : i))
    }

    const saveData = async () => {
        let formData = new FormData()
        formData.append('students', JSON.stringify(studentsScoresList))
        const data = await modyfyStudentsScoresList(formData)
        if (data) {
            message.success(`Сохранение успешно!`);
        } else {
            message.error(`Сохранение не удалось...`);
        }
    }

    return (
        <Container>
            <TeacherSelectWorkType
                selectWorkType={selectWorkType}
                selectedWorkTypeID={selectedWorkTypeID}
                classubID={teacher.selectedClasSubject.id}
            />
            <TeacherSelectTheme classID={teacher.selectedClasSubject.classID}
                subjectID={teacher.selectedClasSubject.subjectID}
                selectTheme={selectTheme}
                selectedThemeID={selectedThemeID}
                selectedWorkTypeID={selectedWorkTypeID} />

            {l3Text &&
                <Container style={{ marginTop: '15px' }}>{l3Text}</Container>
            }

            {l4Text != "" &&
                <HTMLViewer
                    value={l4Text}
                ></HTMLViewer>}

            <Table striped bordered hover style={{ marginTop: '15px' }}>
                <thead>
                    <tr>
                        <th>Ученики {teacher.selectedClasSubject.className + ' / ' + teacher.selectedClasSubject.subjectName}</th>
                        <th>Оценка</th>
                        <th>Файлы</th>
                    </tr>
                </thead>
                <tbody>
                    {studentsScoresList.map(student =>
                        <tr key={student.id}>
                            <td>
                                {student.name}
                            </td>
                            <td>
                                {student.editable ?
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        placeholder={'Оценка'}
                                        value={student.score}
                                        onChange={val => changeScore(val, student.id)}
                                    ></InputNumber>
                                    :
                                    student.score
                                }
                            </td>
                            <td>
                                <FilesUpload
                                    fileList={student.fileList}
                                    disabled={!student.editable}
                                    action={`${process.env.REACT_APP_API_URL}/api/file/upload/?area=${'answer'}&id=${student.answerID}`}
                                ></FilesUpload>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Button
                style={{ 'marginTop': '15px' }}
                onClick={() => saveData()}
            >
                Сохранить
            </Button>
        </Container>
    );
});

export default TeacherStudentsMass;